<template>
 
  <div :class="`theme--${data.themeType}`">

    <ProductDetails
      v-if="step == 1"
      @updateCartQuantity="updateCartQuantity"
      @addCartItem="addCartItem"
      @updateCartSummary="updateCartSummary"
      @setStep="setStep"
      @changeVariant="onChangeVariant"
      :data="{ ...data,changeVariant,changeVariantItem }"
      @addToCart="addToCart"
      @showMsg="showMsg"
    />
    <div v-else>
      <no-ssr>
        <Checkout      
        @updateCartQuantity="updateCartQuantity"
        :submited="submited"
        @onSubmitted="onSubmitted"
        @updateCartTotal="updateCartTotal"
        @updateCartSummary="updateCartSummary"
        @calcCart="calcCart"
        @setStep="setStep"
        :data="{ ...data }"
        :shippingCourierItems="shippingCourierItems"
    />
      </no-ssr>
    </div>
   
  </div>

</template>
<script>
import ProductDetails from "./ProductDetails/index.vue";
import Checkout from "./Checkout/index.vue";
import config from "./../../../config.js";
import Cookies from "js-cookie";

export default {
  props: ["data", "submited", "shippingCourierItems"],
  data() {
    return {
      step: 1,     
      baseUrl: config.api.replace("/api", ""),
      changeVariant:false,
      changeVariantItem:{}
    };
  },
  components: {
    ProductDetails,
    Checkout,
  },
  watch:{
    "data.variablePrices": {
      handler() {      
        
        this.calcCart();
      },
      deep: true,
    },
    payload: {
      handler() {      
        this.$emit("updateCheckout");    
        this.calcCart();   
      },
      deep: true,
    },
    step: function() {
      window.scrollTo({ top: 0, behavior: 'instant' })
    },
  },
  mounted() {  
    this.calcCart();
    // if (Cookies.get("tuktukSummaryCheckout") && !this.data.embed) {
    //   this.storeData = JSON.parse(Cookies.get("tuktukSummaryCheckout"));           
    //   if(this.storeData.url==this.data.url && this.$route.query.step && this.$route.query.step==2){
    //     this.setStep(2);
    //   }else{
    //     if(this.$route.query.step){
    //       this.$router.replace({ path: this.data.url, params: {url:this.data.url} });      
    //     }   this.$router.push({
        
    //     });
    //   }
    // }else{
    //   if(this.$route.query.step && !this.data.embed){
    //       this.$router.replace({ path: this.data.url, params: {url:this.data.url} });      
    //     } 
    // }
    

   
   
    
  },
  computed:{
    cartTotalPrice: {
      get() {
        return this.data.cartTotal;
      },
      set(val) {
        this.$emit("updateCartTotal", val);
      },
    },
  },
  methods: {
    calcCart() {
      // this.totalBumpOrderPrice = this.totalBumpOrderPrice ? this.totalBumpOrderPrice : 0
      let price =
        0 +
        parseFloat(this.data.payload.deliveryPrice ? this.data.payload.deliveryPrice : 0);
      this.data.cartSummary.map(async (e) => {
        let subPrice = (e.price ? e.price : e.priceOriginal) * e.qty;
        if (e.whosaleData) {
          e.whosaleData.map((one) => {
            if (one.count && parseInt(one.count) <= parseInt(e.qty)) {
              subPrice = one.price * parseInt(e.qty);
            }
            return subPrice;
          });
        }
        price = price + subPrice;
      });
     
      if (this.data.payload.payment == "COD") {
        price = price + parseFloat(this.data.codPrice);
      }
    
      if (this.data.payload.uniquePrice && this.data.payload.payment == "bankTransfer") {
        price =
          price +
          (this.data.codeType == "Decrease"
            ? -this.data.payload.uniquePrice
            : this.data.payload.uniquePrice);
      }
     
      if (this.data.additionalCost && this.data.addShipping) {
        price = price + parseInt(this.data.additionalCost);
      }
      if (this.data.flatShippingPrice && this.data.shippingType == "flat") {
        price = price + parseInt(this.data.flatShippingPrice);
      }
      if (this.data.shippingDiscountValue && this.data.addShipping && this.data.shippingType == "automated" && this.data.shippingDiscountValue > 0) {
        price = price - parseFloat(parseFloat(this.data.shippingDiscountValue));
      }
      
      // if (this.payload.bump) {
      //   price = price + parseFloat(this.data.bumpPrice)
      // }

      if (
        this.data.isBump &&
        this.data.bumpArray &&
        this.data.bumpArray.length >= 0 &&
        this.data.selectedbumpArray
      ) {
        // price = price + parseFloat(this.data.bumpPrice)
        this.data.selectedbumpArray.map((bumpData) => {
          //  const bumpData = this.data.bumpArray.find((data) => data.bump_id == bump_id)
          if (bumpData) {
            price = price + (bumpData.bumpPrice ? parseFloat(bumpData.bumpPrice) : 0);
            //  this.totalBumpOrderPrice = this.totalBumpOrderPrice + parseFloat(bumpData.bumpPrice)
          }
        });
      }
      if (typeof price == "string") {
        price = parseFloat(price);
      }
   
      // return parseInt(price.toFixed())
      this.cartTotalPrice = parseInt(price.toFixed());
    },
    onChangeVariant(val){       
      if(typeof val=="object" && "status" in val){
        this.changeVariant=val.status;
        this.changeVariantItem=val.elem;
      }else{
        this.changeVariant=val;
      }
      if(!val){
        this.changeVariantItem={};
      }
     
    },
    setStep(count) {
      if(process.client){
        console.log("this.data.url",this.data.url);
      this.step = count;         
      if(count==2){    
        const storeData={
          selectedbumpArray:this.data.selectedbumpArray,
          cartQuantity:this.data.cartQuantity,
          payloadVariablePrices:this.data.payload.variablePrices,
          variablePrices:this.data.variablePrices.map(a=>{ return {qty:a.qty}}),
          cartSummary:this.data.cartSummary,
          url:this.data.url
        }    
        if(!this.data.embed){   
          Cookies.set("tuktukSummaryCheckout", JSON.stringify(storeData), {
            expires: new Date(new Date().getTime() + 30 * 60 * 1000),
          });
          if(!this.$route.query.step){
            this.$router.replace({ path:this.data.url, params: {url:this.data.url}, query: {step: count} });      
          }   
        }     
      }else{
        if(!this.data.embed){
          this.$router.replace({ path: this.data.url, params: {url:this.data.url}})
        }
        
      }
    }
    },
    onSubmitted() {
      this.$emit("onSubmitted");
    },
    addToCart() {
      this.$emit("addToCart");
    },
    showMsg() {
      this.$emit("showMsg");
    },
    updateCartQuantity(cartQuantity) {
      this.$emit("updateCartQuantity", cartQuantity);
      this.calcCart();
    },
    addCartItem() {
      this.$emit("addCartItem");     
    },
    updateCartSummary(index) {     
      this.$emit("updateCartSummary", index);
      this.calcCart();
    },
    updateCartTotal(val) {
      this.$emit("updateCartTotal", val);
    }    
  },
};
</script>

<style lang="scss">
 @import "@/assets/styles/pages/success.scss";
 @import '@/assets/styles/pages/checkout/components/form/Tuktuk/index.scss';
 @import '@/assets/styles/pages/checkout/components/form/Tuktuk/Checkout/index.scss';
 @import '@/assets/styles/pages/checkout/components/form/Tuktuk/ProductDetails/index.scss';
</style>
