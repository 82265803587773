<template>
  <div id="success-content" :class="!form ? '' : 'form-card'">
    <div class="preview-card-scroll">
      <template v-if="data.successShowTitle && data.successTitle">
        <h1 class="success-content__title">
          {{ data.successTitle }}
        </h1>
      </template>

      <div
        :style="
          !data.successShowTitle
            ? ''
            : ''
        "
        class="card-body p-0"
        :class="isMobile ? 'mobileView_inDesktop' : ''"
      >
        <div class="preview-main no_border">
          <!-- ILUSTRATIONS -->
          <div class="success-content__ilust">
            <img
              width="102"
              src="@/assets/icons/success-order.svg"
              alt="success icon"
            />
          </div>

          <!-- THANK YOU CONTENT -->
          <h2 class="thank-you">{{ successHeadline }}</h2>

           <!-- SHOW VIDEO -->
          <div v-if="data.successVideo" class="preview-success-video">
            <iframe
              class="embed-responsive-item"
              :src="'https://www.youtube.com/embed/' + videoUrl"
              allowfullscreen
            ></iframe>
          </div>

           <!-- DESC PAYMENT -->
          <div class="desc-block" v-html="textData"></div>

          <!-- SHOW SUCCESS PAYMENT -->
          <div
            v-if="
              data.successTextEpayment &&
              data.successShowChatButton &&
              paymentInfo != 'bankTransfer'
            "
          >
            `
            <hr class="line-pengiriman" />
            <h2 class="text-center w-100 bank-text-1">
              {{ $t('payment.deliveryProblems') }}
            </h2>
            <p class="text-center mt-20 mb-20">
              {{ $t('payment.contactSeller') }}
            </p>
            <br />
            <div @click="sellerContact" class="customerwhatsapp">
              <img src="@/assets/icons/whatsapp-link.svg" />
              <div class="whatsapp-button ml-1">
                {{ $t('payment.contactViaWhatsapp') }}
              </div>
            </div>
          </div>
          
          <!-- WHATSAPP REDIRECT SCREEN -->
          <div
            v-if="
              data.whenComplete == 'csNumber' || data.whenComplete == 'random'
            "
          >
            `
            <hr class="line-pengiriman" />
            <h2 class="text-center w-100 bank-text-1">
              Anda akan diarahkan ke salah satu CS kami dalam beberapa saat...
            </h2>
            <p class="text-center mt-20 mb-20">
              Klik tombol dibawah jika anda tidak diarahkan
            </p>
            <br />
            <div @click="continueWhatsapp" class="customerwhatsapp-continue">
              <img src="@/assets/icons/whatsapp-link.svg" />
              <div class="whatsapp-button ml-1">
                continue
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- CONTENT COPYRIGHT -->
      <div
        class="copyright-content payment-confirmation__copyright-content"
      >
        <div class="copyright-text__poweredby" v-if="data.isPoweredBy">
          <p class="copyright-text__poweredby__text">Powered by</p>
          <img src="@/assets/icons/formulir-logo.png" />
        </div>
        <p class="copyright-content__text">
          Copyright © {{ new Date() | year_format }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import bankNames from "../../data/bankNames";
// import moment from "moment";
export default {
  name:"SuccessPage",
  props: [
    "data",
    "form",
    "refresh",
    "paymentType",
    "paymentInfo",
    "order",
    "paymentData",
    "isMobile",
    "userData"
  ],
  data() {
    return {
      payload: {
        name: "",
        phone: "",
        email: "",
        quantity: "",
        address: "",
        note: "",
        subdistrict: "",
        variablePrices: {},
        payment: "bankTransfer",
      },
      videoUrl: "",
    };
  },
  methods: {
    getTitle() {
      setTimeout(()=>{
        document.title = (this.data ? this.data.name : "embed-form")+" - Formulir";
      },500)
    },
    onConfirm() {
      this.$router.push({
        path: "/payment-confirmation?order=" + this.order.ORDER_ID
      });
    },
    async onCopyAccountNumber(textTarget) {
      try {
        await navigator.clipboard.writeText(textTarget);
      } catch ($e) {
        console.log("Copy error", $e);
      }
    },
    async onCopyPrice(textTarget) {
      try {
        await navigator.clipboard.writeText(textTarget);
      } catch ($e) {
        console.log("Copy error", $e);
      }
    },
    async sellerContact() {
      if (this.order && this.userData) {
        let fphone = this.userData.formulirNumber;
        if(this.order && this.order.handledUser && this.order.handledUser.email != this.userData.email){
          fphone = this.order.handledUser.phone
        }
        let number = await this.changeNumber(fphone)
        let url = `https://wa.me/${number}`;
        window.open(url, "_blank");
      }
    },
    async changeNumber(customerPhone){
      let RECEIVER_PHONE = customerPhone.replace("'", "");
      if (RECEIVER_PHONE && RECEIVER_PHONE.charAt(0) == "0") {
          RECEIVER_PHONE =
          "62" + RECEIVER_PHONE.substring(1, RECEIVER_PHONE.length);
      }
      if (RECEIVER_PHONE && RECEIVER_PHONE.charAt(0) != "+") {
          if (
              !(RECEIVER_PHONE.charAt(0) == "6" &&
              RECEIVER_PHONE.charAt(1) == "2")
          ) {
              RECEIVER_PHONE = "62" + RECEIVER_PHONE;
              RECEIVER_PHONE = "62" + RECEIVER_PHONE;
          }
      }
      return RECEIVER_PHONE;
    },
    continueWhatsapp(){
      this.$emit('continueAction')
    }
  },
  mounted() {
    this.getTitle();
    let btn = document.getElementById("payment-confirm");
    if (btn) {
      btn.addEventListener("click", () => {
        this.onConfirm();
      });
    }

    let btnCopyAccountNumber = document.getElementById(
      "btn-copy-account-number"
    );
    if (btnCopyAccountNumber) {
      btnCopyAccountNumber.addEventListener("click", (e) => {
        e.target
          .closest(".copy-button")
          .querySelector(".copy-popup")
          .classList.remove("hide");
        setTimeout(() => {
          e.target
            .closest(".copy-button")
            .querySelector(".copy-popup")
            .classList.add("hide");
        }, 3000);
        this.onCopyAccountNumber(
          e.target.closest(".copy-button").getAttribute("data-account")
        );
      });
    }

    let btnCopyPrice = document.getElementById("btn-copy-price");
    if (btnCopyPrice) {
      btnCopyPrice.addEventListener("click", (e) => {
        e.target
          .closest(".copy-button")
          .querySelector(".copy-popup")
          .classList.remove("hide");
        setTimeout(() => {
          e.target
            .closest(".copy-button")
            .querySelector(".copy-popup")
            .classList.add("hide");
        }, 3000);
        this.onCopyPrice(new Intl.NumberFormat().format(this.price));
      });
    }

    let variablePrices = {};
    this.data.attributes.map((attr, i) => {
      variablePrices[attr.name + i.toString()] = attr.value[0];
    });
    this.payload.variablePrices = variablePrices;

    this.data.requestedFields.map((attr) => {
      if (attr.field == "subdistrict") {
        attr.value = false;
      } else {
        attr.value = "";
      }
    });
  },
  watch: {
    $route: {
    immediate: true,
      handler() {
        this.getTitle();
      }
    },
    "data.successVideo": {
      handler(val) {
        if (val !== "") {
          if (val.includes("https://youtu.be/")) {
            this.videoUrl =
              val.split("/youtu.be/").length > 1
                ? val.split("/youtu.be/")[1]
                : val;
          } else if (val.includes("?")) {
            this.videoUrl =
              val.split("v=").length > 1 ? val.split("v=")[1] : val;
          } else {
            this.videoUrl = val;
          }
        } else {
          this.videoUrl = val;
        }
      },
      immediate: true,
      deep:true
    },
  },
  computed: {
    estimatedDays() {
      if(this.order && this.order.shippingPrice) {
        let estDays = (this.order.shippingPrice/10000)*2 + 1 
        return Math.round(estDays) > 21 ? 21 : Math.round(estDays);
      } else {
        return '2-4';
      }
    },
    successHeadline() {
      return this.data.successHeadline.replace(
        "{{product_name}}",
        this.data.name
      );
    },
    price() {
      let price =
        this.order && this.order.grossRevenue ? this.order.grossRevenue : 0;
      return price;
    },
    textData() {
      let text =
        this.paymentInfo == "COD"
          ? this.data.successTextCod
          : this.paymentInfo == "bankTransfer"
          ? this.data.successTextBank
          : this.paymentInfo == "E-Payment"
          ? this.data.successTextEpayment
          : "";

      text = text.replace("{{start_block}}", '<div class="price-block">');
      text = text.replace("{{end_block}}", " </div>");
      text = text.replace(
        "{{total_price}}",
        `<div class="rp-white">Rp ${new Intl.NumberFormat().format(
          this.price
        )}</div>`
      );

      text =
        this.data.bankTransferSettings && this.data.bankTransferSettings.length
          ? text.replace(
              "{{bank_accounts}}",
              `<div class="background-transfer">${this.data.bankTransferSettings.map(
                (data) => {
                  if (data.active) {
                    return `<div class="bank-transfer d-flex"><div class="bank-left"><div class="image"><img src="${require("@/assets/icons/bank-transfer/"+(data.id && data.id.indexOf('custom_bank')  == -1 ? data.id : "other_bank")+".svg") ? require("@/assets/icons/bank-transfer/"+(data.id && data.id.indexOf('custom_bank')  == -1? data.id  : "other_bank")+".svg") : require("@/assets/icons/bank-transfer/other_bank.svg")}" alt=""></div></div><div class="bank-right"><div class="text">${
                      data.accountName
                    }</div><div class="flex"><div class="rekening"><span>${
                      data.accountNumber
                    }</span>${
                      data.accountNumber ? '<span class="dots">•</span>' : data.label
                    }<span>${
                      bankNames.find((bank) => bank.id == data.id).text ? bankNames.find((bank) => bank.id == data.id).text : data.label
                    }</span></div></div></div><div class="rek-copy"><div id="btn-copy-account-number" class="copy-button" data-account="${
                      data.accountNumber
                    }"><div class="copy-popup hide">Copied</div><img src="${require("@/assets/icons/copy-blue.svg")}" alt="Mengantar"/></div></div></div>`;
                  }
                }
              ).join('')}
                <div class="bank-bg-grey">
                  <div class="transfer-total flex items-center justify-between">
                  
                    <div class="total-transferred">Total transferred</div>
                    <div class="rp-white">
                      <span>Rp ${new Intl.NumberFormat().format(this.price)}</span>
                      <div id="btn-copy-price" class="copy-button">
                        <div class="copy-popup hide">Copied</div>
                        <img src="${require("@/assets/icons/copy-blue.svg")}" alt="Mengantar"/>
                      </div>
                    </div>
                  </div>
                  <div class="confirm-pay">Konfirmasikan pembayaran anda di:</div>
                  <div @click="onConfirm" class="btn-confirm-payment pointer" id="payment-confirm">Confirm Payment</div>
                </div>
              </div>`
            )
          : "No Bank Selected";
      text = this.data.isPoweredBy ? text.replace('{{footer}}', ``) : text.replace('{{footer}}', ``)
            
      text = text.replace(
        "{{cod-text}}",
          `<hr class="line" />
          <div class="bank-text-1">Pembayaran Ke Kurir</div>
        <div class="bank-text-2">Mohon membayar sejumlah </div>`
      );

      text = text.replace(
        "{{success-text-bank}}",
          `<div class="success-text-bank">Untuk menyelesaikan proses order, silahkan transfer sejumlah </div>`
      );
      text = text.replace(
        "{{cod-header-text}}",
          `<div class="bank-text-header">Orderan anda kini kami proses, silahkan tunggu kedatangan kurir dalam ${this.estimatedDays} hari kedepan dan siapkan pembayaran senilai</div>`
      );
      text = text.replace(
        "{{payment_link}}",
        '<div @click="onConfirm" class="d-none btn-confirm-payment" id="payment-confirm">Confirm Payment</div>'
      );
      // text = text.replace(
      //   "{{confirm-payment}}",
      //   '<div class="confirm-pay">Konfirmasikan pembayaran anda di:</div>'
      // );
      text = text.replace(
        "{{confirm-payment}}",
        ''
      );
      text = text.replace(
        "{{cod-text}}",
        '<div class="cod-text mt-20">untuk dibayarkan ke kurir langsung.</div>'
      );
      return text;
    },
  },
  filters: {
    // year_format (val) {
    //   return moment(val).format('YYYY')
    // }
  }
};
</script>

<style>
.cod-text {
  margin-bottom: 1rem !important;
}


.whatsapp-button {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #ffffff;
}
.bank-text-header {
  text-align: center !important;
  width: auto;
  max-width: 499px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.customerwhatsapp {
  cursor: pointer;
  margin-top: -1rem !important;
  max-width: 590px;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto 20px;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  background: #04a784;
  border-radius: 8px !important;
}
.bank-text-1 {
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 20px !important;
  text-align: center !important;
  letter-spacing: 0.02em;
  color: #203551 !important;
  margin-bottom: 6px !important;
}
.bank-text-2 {
  margin-top: 20px;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #62718f;
}
.line {
  height: 0px;
  width: 60px;
  left: 320px;
  top: 347px;
  border-radius: 0px;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.line-pengiriman {
  height: 0px;
  width: 60px;
  left: 320px;
  top: 347px;
  border-radius: 0px;
  margin-top: 0.5rem;
  margin-bottom: 2.5rem;
}

body[data-sidebar-color="dark"] .rp-white {
  color: #ffffff !important;
}


.rek-copy {
  /* position: absolute; */
  /* top: 50%; */
  /* right: 25px; */
  /* transform: translateY(-50%); */
}

.copy-popup {
  position: absolute;
  top: -37px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  letter-spacing: 0.02em;
  color: #dedede;
  white-space: nowrap;
  background: #203551;
  box-shadow: 0px 10px 20px rgba(0, 19, 106, 0.05);
  border-radius: 5px;
  visibility: visible;
  opacity: 1;
  transition: all ease 0.3s;
}

.copy-popup::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -4px;
  width: 9px;
  height: 9px;
  background: #203551;
  border-radius: 0 0 0 3px;
  transform: translateX(-50%) rotateZ(-45deg);
}
.copy-popup.hide {
  top: -26px;
  visibility: hidden;
  opacity: 0;
  transition: all ease 0.3s;
}

.customerwhatsapp-continue {
  cursor: pointer;
  margin-top: -1rem !important;
  max-width: 590px;
  display: flex;
  justify-content: center;
  width: 50%;
  margin: 0 auto 20px;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  background: #2E47BA;
  border-radius: 4px;
}

.bank-transfer + .bank-transfer {
  border-top: 1px solid #e3eaf1;
}


.bank-transfer .middle-border {
  width: 1px;
  height: 40px;
  background: #fff;
  opacity: 0.3;
  margin-top: 10px;
}


.transfer-to {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px dashed #c9d4e2;
  color: #203551;
  letter-spacing: 0.02em;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}


@media (max-width: 576px) {
  .embed-checkout-header-text {
    margin-top: 0px;
  }
  .customerwhatsapp {
    margin-top: -1rem !important;
    max-width: 220px;
  }
  .preview-main {
    padding: 10px 15px 0px;
  }

  .bank-transfer {
    align-items: flex-start;
    padding: 15px 15px 20px;
  }
  .bank-transfer .middle-border {
    width: 100%;
    height: 1px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
 
  .bank-transfer .rekening {
    flex-direction: column;
    align-items: flex-start;
    font-size: 13px;
    line-height: 20px;
    text-align: left;
  }
  .bank-transfer .rekening span.dots {
    display: none;
  }
  .transfer-total {
    flex-direction: column;
  }
  .desc-block {
    padding: 0 !important;
    font-size: 13px;
  }
  .desc-block .rp-white {
    font-size: 18px;
  }
  .desc-block .bank-bg-grey {
    padding: 15px 15px 30px;
  }
  .background-transfer #payment-confirm {
    margin: 15px auto 0 !important;
  }
}

@media (max-width: 575px) {
  .success-text-width {
    max-width: 269px;
  }
  .container-fluid {
    padding: 0px 21px !important;
  }
  .success-preview-right {
    padding-bottom: 30px;
  }
  .success-preview-right .bg-orange-inner {
    z-index: 1;
  }

 
  .success-preview-right .card-body .desc-block {
    font-size: 13px;
    line-height: 18px;
  }
  .success-preview-right .card-body .desc-block .rp-white {
    font-size: 18px;
    line-height: 22px;
    margin: 15px 0 0;
  }
  .success-preview-right .card-body .bank-transfer .bank-left .image {
    margin-right: 10px;
  }

  .success-preview-right .desc-block .background-transfer {
    margin-top: 20px;
  }
  .success-preview-right .card-body .desc-block .bank-bg-grey {
    padding: 15px 15px 30px;
  }

  .success-preview-right .card-body .desc-block .rekening {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 13px;
    line-height: 20px;
  }

  .success-preview-right .card-body .desc-block .rekening .dots {
    display: none;
  }

  .success-preview-right .card-body .desc-block .rek-copy {
    right: 15px;
    top: 40px;
  }

  .success-preview-right .card-body .desc-block .transfer-total {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .success-preview-right .card-body .desc-block .transfer-total .rp-white {
    width: 100%;
    position: relative;
    margin-top: 5px;
  }

  .success-preview-right .card-body .desc-block .transfer-total .rp-white span {
    font-size: 20px;
    line-height: 24px;
  }

  .success-preview-right
    .card-body
    .desc-block
    .transfer-total
    .rp-white
    .copy-button {
    margin-left: 0;
    position: absolute;
    top: calc(50% - 8.5px);
    right: 0;
  }

  .success-preview-right
    .card-body
    .desc-block
    .background-transfer
    #payment-confirm {
    margin-top: 17px !important;
  }

  .success-preview-right .card-body.mobileView_inDesktop {
    max-width: 350px;
  }
}
</style>
