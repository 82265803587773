<script>
import OrderDetails from "@/components/OrderDetails";
import config from '@/config.js'
import { getSizedImageUrl } from "@/helpers/imagekitHelper";

export default {
  head() {
    return {
      title: `Order Details | Mengantar App`,
    };
  },
  components: {   
    OrderDetails
  },
  data() {
    return {
      baseUrl: config.api.replace('/api', ''),
      order: null,
      form: null,
      paymentData: null,
      showPayment: false,
      loading: false,
      customerAddress: [],
      shownAddress:'',
      writeReviewModal: false,
      orderReview: {
        userRating: 0,
        title:'',
        description:'',
        image:''
      },
      history: [],
      deliverdTime:'',
      error:{},
      submitted:false,
      getSizedImageUrl
    };
  },

  layout: 'auth'
};
</script>
<template>
 <OrderDetails :data="data" />
</template>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;900&display=swap');

// @import "../../../../assets/styles/pages/order-detail.scss";
</style>
