<script>
import axios from "axios";
import moment from "moment/src/moment";
import FileUploader from "@/components/Payment-FileUploader";
import config from "../config.js";
import _ from "lodash";
import {getContentId} from '../helpers/contentIdHelper'

export default {
    name: "Payment-Confirmation",
    components: {
        'FileUploader': FileUploader,
        
    },
    layout: 'empty',
    data() {
      return {
        orderCountPaymentConfirm: 0,
        form: null,
        order: null,
        host: '',
        paymentData: [],
        settings: [],
        error: {},
        payload: {
            order_id: this.$route.query.order,
            account: '',
            paymentId: '',
            paymentData:'',
            transferDate: new Date(),
            transferAmount: '',
            transferFile: '',
        },
        scripts:[],
        bodyColor:'',
        loading:false,
        getContentId
      }
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                this.getTitle();
            }
        },
        'payload.order_id': {
          handler: _.debounce(function (val) {
            if(val){
                this.getDetails()
            }
          }, 500),
          deep: true,
        },
        // 'payload.order_id': {
        //     handler(val) {
        //         if(val){
        //             this.getDetails()
        //         }
        //     },  
        //     deep: true,
        // },
    },
    async mounted() {     
        const storedCounter = localStorage.getItem('orderCountPaymentConfirm');
        if (storedCounter) {
        this.orderCountPaymentConfirm = parseInt(storedCounter);
        }
        this.orderCountPaymentConfirm++;
        this.host = window.location.host.split('.')[0];
        let data = await axios.get(config.api +'user/getPaymentData', { params: { user_id: this.host}})
        if (data.data.success) {
            // this.payload.paymentId = data.data.data.length ? data.data.data[0]._id : ''
            this.paymentData = data.data.data.filter(data=> data.accountNumber)
            this.settings = data.data.settings ? data.data.settings[0] : [];
            if(this.payload.order_id){
              this.getDetails();
            }
        } 
        this.bodyColor = document.getElementsByTagName("BODY");
        if(this.bodyColor.length &&  self.form) {
            setTimeout(()=>{
                if(self.form) {
                    document.querySelectorAll('meta[name="theme-color"]').forEach((function(x){ x.setAttribute("content", self.form.backgroundColor);}))
                    document.querySelector('meta[name="apple-mobile-web-app-status-bar-style"]').setAttribute("content", self.form.backgroundColor);
                    self.bodyColor[0].style.backgroundColor = self.form.backgroundColor;
                }
            },1000)
        }
        this.getTitle();
    },
    methods: {
       loadingChange(val){
         this.loading=val;
        },
        saveOrderLocal(){
            if (this.orderCountPaymentConfirm === 2) {
            const { customerName, ORDER_ID, product_id, customerPhone } = this.order;
            const dataToSave = {
                customerName,
                ORDER_ID,
                product_id,
                customerPhone
            };
            localStorage.setItem('orderDataPaymentConfirm', JSON.stringify(dataToSave));
            }
            localStorage.setItem('orderCountPaymentConfirm', this.orderCountPaymentConfirm.toString());
        },
         async checkTrackingConversionOnce(order) {
            const compareTime = sessionStorage.getItem("compareTime");
            let orderItem = {}
            if (compareTime && order) {
            orderItem = await axios.post(config.api + 'order/trackConversionOnce', {  productId: order.product_id, time: compareTime, product_id : order.product_id, customerName: order.customerName, customerPhone: order.customerPhone, order_id : order._id});
            this.trackConversionApiCalled = true;
            this.trackConversion = orderItem;
            }
            return this.trackConversionApiCalled ? this.trackConversion : orderItem; // Add 'return' statement to return the value of ordersCount
        },
        checkRequired(param){
            if(this.settings && this.settings.requestedFields){
                let temp = this.settings.requestedFields.filter(e=> e.name == param);
                return temp && temp.length > 0 ? temp[0].required : true;
            }
            return true
        },
        async save() {
            this.error = {};
            if (this.checkRequired('order_id') && !this.payload.order_id) {
                this.error.order_id = 'Harus diisi'
            }
            if (this.checkRequired('paymentId') && !this.payload.paymentId) {
                this.error.paymentData = 'Harus diisi'
            }
            if (this.checkRequired('account') && !this.payload.account) {
                this.error.account = 'Harus diisi'
            }
            if (this.checkRequired('transferDate') && !this.payload.transferDate) {
                this.error.transferDate = 'Harus diisi'
            }
            if (this.checkRequired('transferAmount') && !this.payload.transferAmount) {
                this.error.transferAmount = 'Harus diisi, dan hanya diisi dengan angka'
            }
            if (this.checkRequired('transferFile') && !this.payload.transferFile) {
                this.error.transferFile = 'Harus diisi'
            }

            if (!Object.keys(this.error).length) {
              
              const opt = this.paymentData.find(x=>x.accountNumber==this.payload.paymentId);
              if(opt){
                this.payload.paymentData=  (opt.bankName
                                  ? opt.bankName.toUpperCase() + ' - '
                                  : '') +
                                (opt.accountName
                                  ? opt.accountName + ' - '
                                  : '') +
                                (opt.accountNumber ? opt.accountNumber : '');
              }
              

                try {
                    let data = await axios.post(config.api +'order/payment-confirm', this.payload).catch((error) => {                    
                        if( error.response ){                         
                            if(error.response.data.message){
                              throw new Error(error.response.data.message);
                            }
                        }
                    }).catch(err => {                     
                      throw new Error(err.message);                       
                    })                  
                    if (data && data.data && data.data.success) {
                        this.$router.push({
                            path: "/payment-success?order=" + this.payload.order_id,
                        });
                    }
                } catch (err) {                 
                  this.$vs.notification({
                      color: "danger",
                      position: "top-right",
                      text: err.message,//"Please fix fields error before proceeding to next step",
                      duration: 2000,
                    });
                    if(this.$route.query.order){
                      this.payload = {
                          order_id: this.$route.query.order,
                          account: '',
                          paymentId: this.payload.paymentId,
                          paymentData: this.payload.paymentData,
                          transferDate: new Date(),
                          transferAmount: '',
                          transferFile: '',
                      }
                    }                  
                }
            }
        },
        createScripts(){
            if(this.order){
                this.total_price = this.order.grossRevenue
                this.product_name = this.order.productName
            }
            if(this.form){
                this.product_category_full = this.form.category + this.form.category2 + this.form.category3;
                if(this.$fb && this.form.pixelsFB && this.form.pixelsFB.length > 0 ){
                  //eslint-disable-next-line
                  let str = "<script>!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};"+"if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];"+"s.parentNode.insertBefore(t,s)}(window, document,'\script','https://connect.facebook.net/en_US/fbevents.js');"
                  this.form.pixelsFB.forEach(async pixel => {
                      // if user select global conversion settings 
                      if (pixel.trackingConversion == 'global') {
                      pixel.trackingConversion = this.form.globalConversionSetting
                      }
                      if (pixel.trackingConversion == 'once') {
                          const result = await this.checkTrackingConversionOnce(this.order);
                          if (result.data && result.data.length > 0) {
                              return
                          }
                          const storedData = localStorage.getItem("orderDataPaymentConfirm");
                          if (storedData) {
                            const storedOrder = JSON.parse(storedData);
                            if (
                                storedOrder.customerName === this.order.customerName &&
                                storedOrder.ORDER_ID === this.order.ORDER_ID &&
                                storedOrder.product_id === this.order.product_id &&
                                storedOrder.customerPhone === this.order.customerPhone
                            ) {
                                return;
                            }
                          }
                      }
                      str += `fbq('init', "${pixel.name.trim()}");`;
                  });

                  str += `fbq('track', 'PageView');`;
                  if(this.form.pixelsFB && this.form.pixelsFB.length > 0){
                      this.form.pixelsFB.forEach(events => {
                        var object = events.settings.reduce((obj, item) => Object.assign(obj, { [item.name]: item.name !== "currency" ? this[item.data.code] : item.data.code }), {});
                        str += `fbq('track',"${events.name}",${JSON.stringify(object)});`;
                      });
                  }

                  //eslint-disable-next-line
                  str += "<\/script>";       
                  this.scripts.push({script: str, name : "fb", type: "fb"});
                }
                if(this.form.GTM && this.form.GTM.length > 0){
                    this.form.GTM.forEach(async gtm => {
                        // if user select global conversion settings 
                        if (gtm.trackingConversion == 'global') {
                        gtm.trackingConversion = this.form.globalConversionSetting
                        }
                        if (gtm.trackingConversion == 'once') {
                            const result = await this.checkTrackingConversionOnce(this.order);
                            if (result.data && result.data.length > 0) {
                                return
                            }
                            const storedData = localStorage.getItem("orderDataPaymentConfirm");
                            if (storedData) {
                            const storedOrder = JSON.parse(storedData);
                            if (
                                storedOrder.customerName === this.order.customerName &&
                                storedOrder.ORDER_ID === this.order.ORDER_ID &&
                                storedOrder.product_id === this.order.product_id &&
                                storedOrder.customerPhone === this.order.customerPhone
                            ) {
                                return;
                            }
                            }
                        }
                        //eslint-disable-next-line
                        this.scripts.push({script: "<script>(function(w,d,s,l,i){w[l]=w[l]||[];"+"w[l].push({'formLocation': 'head','gtm.start':new Date().getTime(),event:'gtm.js'})"+";var f=d.getElementsByTagName(s)"+"[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?"+"id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','"+gtm.name+"');<\/script>", name : gtm.name, type: "gtm"});
                    });
                }
                if(this.form.pixelsTiktok && this.form.pixelsTiktok.length > 0){
                    this.form.pixelsTiktok.forEach(async pixelsTiktok => {
                         // if user select global conversion settings 
                        if (pixelsTiktok.trackingConversion == 'global') {
                        pixelsTiktok.trackingConversion = this.form.globalConversionSetting
                        }
                        if (pixelsTiktok.trackingConversion == 'once') {
                            const result = await this.checkTrackingConversionOnce(this.order);
                            if (result.data && result.data.length > 0) {
                                return
                            }
                            const storedData = localStorage.getItem("orderDataPaymentConfirm");
                            if (storedData) {
                            const storedOrder = JSON.parse(storedData);
                            if (
                                storedOrder.customerName === this.order.customerName &&
                                storedOrder.ORDER_ID === this.order.ORDER_ID &&
                                storedOrder.product_id === this.order.product_id &&
                                storedOrder.customerPhone === this.order.customerPhone
                            ) {
                                return;
                            }
                            }
                        }
                    let pixel = pixelsTiktok ? pixelsTiktok.name : ''
                    //eslint-disable-next-line
                    let recaptchaScript = document.createElement('script')
                    let str =  "!function (w, d, t) { w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=['page','track','identify','instances','debug','on','off','once','ready','alias','group','enableCookie','disableCookie'],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i='https://analytics.tiktok.com/i18n/pixel/events.js';ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement('script');o.type='text/javascript',o.async=!0,o.src=i+'?sdkid='+e+'&lib='+t;var a=document.getElementsByTagName('script')[0];a.parentNode.insertBefore(o,a)}; ttq.load('" + pixel + "'); ttq.page(); }(window, document, 'ttq');";
                    
                    if (this.data.pixelsEventsTiktok && this.data.pixelsEventsTiktok.length > 0 && this.data.enableTiktokPixel) {
                      this.data.pixelsEventsTiktok.forEach(events => {
                          if(events.settings){
                            var object = {}; 
                            events.settings.map((item) => {
                                if(item.name == "currency" || item.name == "content_type"){
                                    object[item.name] = item.name == "currency" ? "IDR" : "product"
                                }else{
                                    object[item.name] = item.name == "content_id" ? this.getContentId(this.form, this.getSKU(this.order.variations), item.data.code) : this[item.data.code]
                                }
                            });
                            str += `ttq.track("${events.name}",${JSON.stringify(object)});`;
                          }else{
                            str += `ttq.track("${events.name}");`;
                          }
                      });
                    }
                    recaptchaScript.innerHTML = str;
                    document.head.appendChild(recaptchaScript)
                  });
                }
            }
        },
       getSKU(param){
          for(let i =0; i < param.length;i++){
            if(param[i].SKU && param[i].SKU != ""){
              return param[i];
            }
          }
        },
        async getDetails(){
            let data = await axios.get(config.api +'order/one', {params: {ORDER_ID: this.payload.order_id, getPayment: false }})
            if (data.data.success) {
                this.form = data.data.form
                this.order = data.data.order
                this.paymentData = data.data.order.paymentInfo == "bankTransfer" ? data.data.form.bankTransferSettings.filter(e=> e.active) : this.paymentData;
                this.createScripts();
                this.saveOrderLocal()
            }
        },
        getTitle() {
            setTimeout(()=>{
                document.title = (this.form ? this.form.name : "embed-form")+" - Formulir";
            },500)
        }, 
    },
    filters: {
        year_format (val) {
            return moment(val).format('YYYY')
        },
        date_format (val) {
            return moment(val).format('DD MMM YYYY hh:mma')
        },
        datetime_format (val) {
            return moment(val).format('DD MMM YYYY, HH:mm')
        },
        history_format (val) {
            return moment(val).local().format('MMMM DD, YYYY ') + ' at ' + moment(val).local().format('hh:mma')
        },
    },
    computed: {
        
    },
};
</script>
<template>
  <div   
    :style="`background-color: ${form ? form.backgroundColor : ''};min-height: 100vh;`"
    class="d-flex justify-center"
  >  
    <span v-bind:key="index" v-for="(pixel, index) of scripts">
      <span v-bind:key="index">
        <VueScriptComponent :script="pixel.script" />
      </span>
    </span>
    <div id="wrapper" class="container-sm success-page">
      <div class="content-page-empty flex justify-center">
        <div class="content content-empty w-100" style="max-width: 500px">
          <div class="container-fluid">
            <div class="mb-0 preview-card" :class="'form-card'">
              <div class="card-body" style="padding: 0.75rem">
                <div class="row text-center mb-4">
                  <div class="w-100">
                    <label class="title-under-image payment-success-title">
                      {{ $t('payment.paymentConfirmation') }}
                    </label
                    >
                  </div>
                </div>
                <div class="preview-wrapper">
                  <div class="preview-main">
                    <div
                      class="row"
                      v-if="settings && settings.requestedFields"
                    >
                      <div
                        v-bind:key="i"
                        v-for="(elem, i) in settings.requestedFields"
                        class="col-md-12"
                      >
                        <div v-if="elem.enabled">
                          <label class="small-label"
                            >{{ elem.label
                            }}<span v-if="elem.required" class="text-danger"
                              >*</span
                            ></label
                          >                       
                          <vs-input
                            v-if="
                              elem.field == 'input' &&
                              (elem.type == 'text' || elem.type == 'number')
                            "
                            :type="elem.type"
                            :placeholder="elem.placeholder"
                            :class="{ 'is-invalid': error[elem.name] }"
                            v-model="payload[elem.name]"
                            class="vs-form-control payment-confirm-input"
                          />
                          <!-- <date-picker v-if="elem.type == 'date'" class="" v-model="payload.transferDate" type="date" ></date-picker> -->
                          <date-picker
                            v-if="elem.type == 'date'"
                            v-model="payload.transferDate"
                            type="date"
                            placeholder="DD/MM/YYYY"
                            class="pp-date-picker custom-shipment-date"
                            popup-class="pp-date-picker-popup"
                            :append-to-body="true"
                            format="DD/MMM/YYYY"
                            :class="{ 'is-invalid': error.transferDate }"
                          >
                          </date-picker>                        
                          <vs-select
                            v-if="
                              (elem.name == 'paymentId' ||
                                elem.name == 'transfer_to') &&
                              paymentData.length &&
                              order.paymentInfo == 'bankTransfer'
                            "
                            :placeholder="elem.placeholder"
                            v-model="payload.paymentId"
                            :class="{ 'is-invalid': error.paymentData }"
                            class="vs-form-control"
                          >
                            <vs-option
                              v-for="opt in paymentData"
                              :key="opt.accountNumber"
                              :label="
                                (opt.label
                                  ? opt.label.toUpperCase() + ' - '
                                  : '') +
                                (opt.accountName
                                  ? opt.accountName + ' - '
                                  : '') +
                                (opt.accountNumber ? opt.accountNumber : '')
                              "
                              :value="opt.accountNumber"
                              :text="
                                (opt.label
                                  ? opt.label.toUpperCase() + ' - '
                                  : '') +
                                (opt.accountName
                                  ? opt.accountName + ' - '
                                  : '') +
                                (opt.accountNumber ? opt.accountNumber : '')
                              "
                              >{{
                                (opt.label
                                  ? opt.label.toUpperCase() + " - "
                                  : "") +
                                (opt.accountName
                                  ? opt.accountName + " - "
                                  : "") +
                                (opt.accountNumber ? opt.accountNumber : "")
                              }}</vs-option
                            >
                          </vs-select>
                          <vs-select
                            v-else-if="
                              (elem.name == 'paymentId' ||
                                elem.name == 'transfer_to') &&
                              paymentData.length
                            "
                            :placeholder="elem.placeholder"
                            v-model="payload.paymentId"
                            :class="{ 'is-invalid': error.paymentData }"
                            class="vs-form-control"
                          >
                            <vs-option
                              v-for="opt in paymentData"
                              :key="opt.accountNumber"
                              :label="
                                (opt.bankName
                                  ? opt.bankName.toUpperCase() + ' - '
                                  : '') +
                                (opt.accountName
                                  ? opt.accountName + ' - '
                                  : '') +
                                (opt.accountNumber ? opt.accountNumber : '')
                              "
                              :value="opt.accountNumber"
                              :text="
                                (opt.bankName
                                  ? opt.bankName.toUpperCase() + ' - '
                                  : '') +
                                (opt.accountName
                                  ? opt.accountName + ' - '
                                  : '') +
                                (opt.accountNumber ? opt.accountNumber : '')
                              "
                              >{{
                                (opt.bankName
                                  ? opt.bankName.toUpperCase() + " - "
                                  : "") +
                                (opt.accountName
                                  ? opt.accountName + " - "
                                  : "") +
                                (opt.accountNumber ? opt.accountNumber : "")
                              }}</vs-option
                            >
                          </vs-select>
                          <FileUploader
                            v-if="elem.type == 'file'"
                            :customStyle="'height:40px; background: white; border: 1px solid #ced4da; padding: 7px 13px; border-radius: 8px;'"
                            type="file"
                            :className="{ 'vs-form-control': true, 'isError' : error[elem.name] }"
                            v-model="payload[elem.name]"
                             @loadingChange="loadingChange"
                          ></FileUploader>
                          <div v-if="error[elem.name]" class="invalid-feedback">
                            {{ error.order_id }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-else>
                      <div class="col-md-12">
                        <label class="small-label mt-2"
                          >{{ $t('payment.paymentProof') }} <span class="text-danger">*</span></label
                        >
                        <vs-input
                          placeholder="No. Pesanan"
                          :class="{ 'is-invalid': error.order_id }"
                          v-model="payload.order_id"
                          class="vs-form-control payment-confirm-input"
                        />
                      </div>
                      <div class="col-md-12">
                        <label class="small-label mt-2"
                          >{{ $t('payment.accountName') }}
                          <span class="text-danger">*</span></label
                        >
                        <vs-input
                          placeholder="Pemilik Rekening"
                          :class="{ 'is-invalid': error.account }"
                          v-model="payload.account"
                          class="vs-form-control payment-confirm-input"
                        />
                        <div v-if="error.account" class="invalid-feedback">
                          {{ error.account }}
                        </div>
                      </div>
                      <div class="col-md-12">
                        <label class="small-label mt-2"
                          >{{ $t('payment.transferTo') }} <span class="text-danger">*</span></label
                        >                      
                        <vs-select
                          v-if="paymentData.length"
                          v-model="payload.paymentId"
                          :class="{ 'is-invalid': error.paymentData }"
                          class="vs-form-control payment-confirm-input"
                        >
                          <vs-option
                            v-for="opt in paymentData"
                            :key="opt.accountNumber ? opt.accountNumber : ''"
                            :value="opt.accountNumber ? opt.accountNumber : ''"
                            :label="
                              (opt.label
                                ? opt.label.toUpperCase() + ' - '
                                : '') +
                              opt.accountName +
                              ' - ' +
                              opt.accountNumber
                            "
                            >{{
                              (opt.label
                                ? opt.label.toUpperCase() + " - "
                                : "") +
                              opt.accountName +
                              " - " +
                              opt.accountNumber
                            }}</vs-option
                          >
                        </vs-select>
                        <div v-else>{{ $t('payment.paymentDataNotFound') }}</div>
                        <div v-if="error.paymentData" class="invalid-feedback">
                          {{ error.paymentData }}
                        </div>
                      </div>
                      <div class="col-md-12">
                        <label class="small-label mt-2"
                          >{{ $t('payment.transferDate') }}
                          <span class="text-danger">*</span></label
                        >
                        <date-picker
                          class=""
                          v-model="payload.transferDate"
                          type="date"
                        ></date-picker>
                      </div>
                      <div class="col-md-12">
                        <label class="small-label mt-2"
                          >{{ $t('payment.transferAmount') }}
                          <span class="text-danger">*</span></label
                        >
                        <vs-input
                          placeholder="Contoh =>  10000"
                          min="10000"
                          :class="{ 'is-invalid': error.transferAmount }"
                          v-model="payload.transferAmount"
                          class="vs-form-control payment-confirm-input"
                        />
                        <div
                          v-if="error.transferAmount"
                          class="invalid-feedback"
                        >
                          {{ error.transferAmount }}
                        </div>
                      </div>
                      <div class="col-md-12">
                        <label class="small-label mt-2">{{ $t('payment.paymentProof') }}</label>
                        <FileUploader
                          :customStyle="'background: white; border: 1px solid #ced4da; padding: 7px 13px; border-radius: 12px;'"
                          type="file"
                          :className="{ 'vs-form-control': true, 'isError' : error.transferFile}"
                          v-model="payload.transferFile"
                          @loadingChange="loadingChange"
                        ></FileUploader>
                        <div
                          v-if="error.transferFile"
                          class="invalid-feedback"
                        >
                          {{ error.transferFile }}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <button
                          :disabled="!form || loading"
                          @click="save"
                          class="pp-button-blue--fill w-100"
                        >
                          {{ $t('payment.send') }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- CONTENT COPYRIGHT -->
            <div
              class="copyright-content payment-confirmation__copyright-content"
            >
              <div class="copyright-text__poweredby" v-if="form && form.isPoweredBy">
                <p class="copyright-text__poweredby__text">Powered by</p>
                <img src="@/assets/icons/formulir-logo.png" />
              </div>
              <p class="copyright-content__text">
                Copyright © {{ new Date() | year_format }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.is-invalid input.mx-input {
  border: 1px solid red !important;
}
.content-page-empty {
  overflow: hidden;
  padding: 20px 90px 65px 90px;
  min-height: 80vh;
}
.success-page .card {
  border: none !important;
  box-shadow: none !important;
}
.container-sm {
  max-width: 1140px !important;
}
.payment-success-title {
  font-weight: 600;
  font-size: 22px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #203551;
}
.mx-input {
  background-color: white !important;
  padding: 17px 14px;
  background-color: white !important;
  border: 1px solid #bfcee0 !important;
  border-radius: 8px !important;
  font-size: 13px !important;
  line-height: 25px !important;
  height: 38px !important;
}
.payment-confirm-input input {
  padding: 10px !important;
}
</style>

<style scoped>
.preview-wrapper {
  margin: 0.75rem 0.75rem 0 0.5rem;
}
.content-page-empty {
  padding: 30px 90px 65px 90px;
}
.preview-main {
  padding: 30px;
}
.preview-main .row .col-md-12:first-child .small-label {
  margin-top: 0 !important;
}
.preview-main .row .col-md-12 .small-label {
  margin: 15px 0 10px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #203551;
}
.preview-main .pp-button-blue--fill {
  margin-top: 20px;
}

@media (max-width: 575px) {
  .content-page-empty {
    padding: 30px 0 0 !important;
  }
  .preview-main {
    padding: 20px;
  }
  .preview-main .row .col-md-12 .small-label {
    font-size: 14px !important;
  }
}
</style>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;900&display=swap');
</style>