import Vue from "vue";
import VueRouter from "vue-router";
// import { defineAsyncComponent } from 'vue'
import Success from "@/pages/Success.vue";
import Checkout from "@/pages/Checkout.vue";
import PaymentConfirmation from "@/pages/payment-confirmation.vue";
import PaymentSuccess from "@/pages/payment-success.vue";
import OrderDetails from "@/pages/order-details.vue";

// const Checkout = defineAsyncComponent(() => import('@/pages/Checkout.vue'))
// const PaymentConfirmation = defineAsyncComponent(() => import('@/pages/payment-confirmation.vue'))
// const PaymentSuccess = defineAsyncComponent(() => import('@/pages/payment-success.vue'))
// const OrderDetails = defineAsyncComponent(() => import('@/pages/order-details.vue'))
// const Success = defineAsyncComponent(() => import('@/pages/Success.vue'))

Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  routes: [
    { name: "Order-details", component: OrderDetails, path: "/order-details/:order_id", children: [] },   
    { name: "PaymentConfirmation", component: PaymentConfirmation, path: "/payment-confirmation", children: [] }, 
    { name: "PaymentConfirmation", component: PaymentConfirmation, path: "/payment-confirmation(.*)*", children: [] },   
    { name: "PaymentSuccess", component: PaymentSuccess, path: "/payment-success", children: [] },
    { name: "Success", component: Success, path: "/success/:url", children: [] },
    { name: "App", component: Checkout, path: "/:url?", children: [] },
  ]
});

export default router;

