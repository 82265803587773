var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.form && _vm.order)?_c('div',{staticClass:"box-height d-flex justify-center",style:(("background-color: " + (_vm.form.backgroundColor) + ";"))},[_vm._l((_vm.scripts),function(pixel,index){return _c('span',{key:index},[_c('span',{key:index},[_c('VueScriptComponent',{attrs:{"script":pixel.script}})],1)])}),_c('div',{staticClass:"payment-success-page",attrs:{"id":"success-content"}},[_c('div',{staticClass:"preview-card-scroll",style:(_vm.order.paymentInfo != 'bankTransfer' && !_vm.form.successShowTitle
          ? ''
          : '')},[(_vm.form.successTitle)?[(_vm.form.successShowTitle && _vm.form.successTitle)?_c('h1',{staticClass:"success-content__title"},[_vm._v(" "+_vm._s(_vm.form.successTitle)+" ")]):_vm._e()]:_vm._e(),_c('div',{staticClass:"preview-main"},[_vm._m(0),_c('h2',{staticClass:"thank-you"},[_vm._v(" "+_vm._s(_vm.successHeadline)+" ")]),(
            _vm.order &&
            _vm.order.paymentInfo !== 'bankTransfer' &&
            _vm.order.paymentInfo !== 'COD'
          )?_c('div',[(_vm.form && _vm.order)?_c('div',[_c('div',{staticClass:"desc-block",domProps:{"innerHTML":_vm._s(_vm.textData)}})]):_vm._e()]):(_vm.order.shippingInfo && _vm.order.shippingInfo.deliveryCourier && _vm.order.shippingPrice > 0)?_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"success-content__text m-0"},[_vm._v(" "+_vm._s(_vm.$t('payment.processingOrder'))+" ")])]):_vm._e(),(
            _vm.form &&
            _vm.form.successShowChatButton &&
            _vm.order.paymentInfo !== 'COD'
          )?_c('div',{staticClass:"card mb-0 preview-card"},[_c('div',[_c('hr',{staticClass:"line"}),_c('h2',{staticClass:"text-center pt-3 epay-text-1"},[_vm._v(" "+_vm._s(_vm.$t('payment.deliveryProblems'))+" ")]),_c('p',{staticClass:"text-center mb-2 mt-2"},[_vm._v(" "+_vm._s(_vm.$t('payment.contactSeller'))+" ")])]),_c('br'),_c('div',[_c('div',{staticClass:"customerwhatsapp",on:{"click":_vm.sellerContact}},[_c('img',{attrs:{"src":require("@/assets/icons/whatsapp-link.svg")}}),_c('div',{staticClass:"whatsapp-button ml-1"},[_vm._v(" "+_vm._s(_vm.$t('payment.contactViaWhatsapp'))+" ")])])])]):_vm._e()])],2),_c('div',{staticClass:"copyright-content payment-confirmation__copyright-content"},[(_vm.form.isPoweredBy)?_c('div',{staticClass:"copyright-text__poweredby"},[_c('p',{staticClass:"copyright-text__poweredby__text"},[_vm._v("Powered by")]),_c('img',{attrs:{"src":require("@/assets/icons/formulir-logo.png")}})]):_vm._e(),_c('p',{staticClass:"copyright-content__text"},[_vm._v(" Copyright © "+_vm._s(_vm._f("year_format")(new Date()))+" ")])])])],2):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"success-content__ilust"},[_c('img',{attrs:{"width":"102","src":require("@/assets/icons/success-order.svg"),"alt":"success icon"}})])}]

export { render, staticRenderFns }