<template>
  <div v-if="data.payload" id="theme--tuktuk__view-order" class="theme--tuktuk__card">
    <div class="theme--tuktuk__container">
      <h2 class="theme--tuktuk__title">{{ $t("common.orderDetails") }}</h2>
      <!-- START ORDER SUMMARY LIST -->
      <div class="theme--tuktuk__view-order__list">
        <!-- PRODUCT -->
        <div v-if="data.type == 'simple'" class="theme--tuktuk__view-order__list__item">
          <div class="theme--tuktuk__view-order__list__item__variant">
            <p class="theme--tuktuk__view-order__list__item__qty">
              
              {{
                data.cartQuantity
              }}x
            </p>
            <p class="theme--tuktuk__view-order__list__item__name">
              {{ data.name }}
            </p>
          </div>
          
          <div v-if="data.type == 'simple'" class="theme--tuktuk__view-order__list__item__price">
            <template v-if="data.priceCompare">
              <div class="theme--tuktuk__view-order__list__item__price__sale_container">
                <p class="theme--tuktuk__view-order__list__item__price__sale">
                Rp{{ data.cartPrice | g_number_format }}
              </p>
              <p class="theme--tuktuk__view-order__list__item__price__regular">
                <template v-if="data.priceCompare && data.type == 'simple'">
                  Rp{{ (data.cartQuantity * data.price) | g_number_format }}
                </template>
                <template v-else-if="parseInt(data.priceWithoutWhosale) !== parseInt(data.cartPrice)">
                  Rp{{ data.priceWithoutWhosale | g_number_format }}
                </template>
              </p>
           </div>
            </template>
            <template v-else>
              <p class="theme--tuktuk__view-order__list__item__price__regular">
              Rp{{ data.cartPrice | g_number_format }}
            </p>      
           </template>
           
          </div>
        </div>
     
        <div v-if="data.type != 'simple'" class="theme--tuktuk__view-order__list__group">
          <p class="theme--tuktuk__sub-title">
            {{ data.name }}
          </p>
          <template
            v-if="
              !data.multiplyVariations ||
              data.type == 'simple' ||
              (data.multiplyVariations && data.cartOrder)
            "
          >
            <template
              v-if="
                data.cartSummary &&
                data.cartSummary.length > 0 &&
                data.multiplyVariations
              "
            >
              <template v-for="(elem, i) in data.cartSummary">
                <div
                  v-if="elem.qty"
                  v-bind:key="i"
                  class="theme--tuktuk__view-order__list__item "
                >
                  <!-- PRODUCT QTY AND VARIANT NAME -->
                  <div class="theme--tuktuk__view-order__list__item__variant">
                    <p class="theme--tuktuk__view-order__list__item__qty">{{ elem.qty }}x</p>
                    <div class="theme--tuktuk__view-order__list__item__variant__list">
                      <p v-for="(elemChild, j) in Object.keys(elem.items)"
                          v-bind:key="j + 'p'"
                          class="theme--tuktuk__view-order__list__item__name" >
                          {{ elemChild.substring(0, elemChild.length - 1) }} {{ elem.items[elemChild].name }}
                      </p>
                    </div>
                  </div>

                  <!-- PRODUCT PRICE -->
                  <div class="theme--tuktuk__view-order__list__item__price">

                      <template
                      v-if="
                      getSubPrice(elem)  > 0 &&
                      getSubPrice(elem) !== elem.priceOriginal * elem.qty
                      "
                      >
                      <h2 class="theme--tuktuk__view-order__list__item__price__sale">
                      
                        Rp{{ getSubPrice(elem) | g_number_format }}
                      </h2>
                      <div
                        class="theme--tuktuk__view-order__list__item__price__sale_container"
                      >
                        <p class="theme--tuktuk__view-order__list__item__price__regular">
                          <template>
                            Rp{{(elem.priceOriginal * elem.qty) | g_number_format}}
                          </template>
                        </p>                  
                      </div>
                      </template>
                      <template v-else>
                      <h2 class="theme--tuktuk__view-order__list__item__price__regular">
                        Rp{{(elem.priceOriginal * elem.qty) | g_number_format}}
                      </h2>
                      </template>            


                      </div>
                  <!-- <div class="theme--tuktuk__view-order__list__item__price">
                    <p v-if="elem.price == 0" class="theme--tuktuk__view-order__list__item__price__regular">
                      Rp{{ (elem.priceOriginal * elem.qty) | g_number_format }}
                    </p>
                    <template v-if="elem.priceOriginal && elem.price > 0">
                      <p class="theme--tuktuk__view-order__list__item__price__regular">Rp{{ getSubPrice(elem) | g_number_format }}</p>
                      <p
                        v-if="getSubPrice(elem) !== elem.priceOriginal * elem.qty"
                        class="theme--tuktuk__view-order__list__item__price__sale"
                        style="text-decoration: line-through;"
                      >
                        Rp{{(elem.priceOriginal * elem.qty) | g_number_format}}
                      </p>
                    </template>
                </div> -->
                
                </div>
              </template>
            </template>

            <div v-else class="theme--tuktuk__view-order__list__item" >
              <!-- PRODUCT QTY AND VARIANT NAME -->
              <div class="theme--tuktuk__view-order__list__item__variant">
                <p class="theme--tuktuk__view-order__list__item__qty">{{ data.cartQuantity }}x</p>
                <div class="theme--tuktuk__view-order__list__item__variant__list">
                  <p
                    :key="l + 'p'"
                    v-for="(vprice, l) in Object.keys(
                      data.payload.variablePrices
                    )"
                    class="theme--tuktuk__view-order__list__item__name"
                  >
                    {{ vprice.substring(0, vprice.length - 1) }} {{ data.payload.variablePrices[vprice].name }}
                  </p>  
                </div>
                           
              </div>

              <div class="theme--tuktuk__view-order__list__item__price">

                <template
                v-if="
                  parseInt(data.priceWithoutWhosale) !==
                    parseInt(data.cartPrice)
                "
              >
                <h2 class="theme--tuktuk__view-order__list__item__price__sale">
                  Rp{{ data.cartPrice | g_number_format }}
                 
                </h2>
                <div
                  class="theme--tuktuk__view-order__list__item__price__sale_container"
                >
                  <p class="theme--tuktuk__view-order__list__item__price__regular">
                    <template>
                      Rp{{ data.priceWithoutWhosale | g_number_format }}
                    </template>
                  </p>                  
                </div>
              </template>
              <template v-else>
                <h2 class="theme--tuktuk__view-order__list__item__price__regular">
                  Rp{{ data.cartPrice | g_number_format }}
                </h2>
              </template>            
            

              </div>
            </div>
          </template>

          <!-- VARIANT ORDER MULTIPLY VARIATION -->
          <template v-else>
            <template v-for="(attr, i) in data.variablePrices">
              <div
                v-if="attr.qty > 0"
                :key="'f' + i"
                class="theme--tuktuk__view-order__list__item"
              > 
                <div class="theme--tuktuk__view-order__list__item__variant">
                  <p class="theme--tuktuk__view-order__list__item__qty">{{ attr.qty }}x</p>
                  <div class="theme--tuktuk__view-order__list__item__variant__list">
                    <p v-for="(elemChild, j) in attr.attrs"
                        v-bind:key="j + 'p'"
                        class="theme--tuktuk__view-order__list__item__name" >
                        {{ elemChild.name.substring(0, elemChild.name.length - 1) }} {{ elemChild.value }}
                    </p>
                  </div>
                </div>
                <div class="theme--tuktuk__view-order__list__item__price">

                        <template
                        v-if="
                          getSubPrice(attr) !== attr.price * attr.qty
                        "
                        >
                        <h2 class="theme--tuktuk__view-order__list__item__price__sale">
                          Rp{{ getSubPrice(attr) | g_number_format }}
                        
                        </h2>
                        <div
                          class="theme--tuktuk__view-order__list__item__price__sale_container"
                        >
                          <p class="theme--tuktuk__view-order__list__item__price__regular">
                            <template>
                             
                              Rp{{ (attr.price * attr.qty) | g_number_format }}
                            </template>
                          </p>                  
                        </div>
                        </template>
                        <template v-else>
                        <h2 class="theme--tuktuk__view-order__list__item__price__regular">
                          
                          Rp{{ (attr.price * attr.qty) | g_number_format }}
                        </h2>
                        </template>            


                        </div>
                <div class="theme--tuktuk__view-order__list__item__price">
                  <p class="theme--tuktuk__view-order__list__item__price__regular"></p>
                  <p
                    v-if="getSubPrice(attr) !== attr.price * attr.qty"
                    class="theme--tuktuk__view-order__list__item__price__sale"
                  >
                   
                  </p>
                </div>
              </div>
            </template>
          </template>
        </div>

        <!-- BUMP -->
        <div
          v-if="data.isBump && data.selectedbumpArray.length > 0"
          class="theme--tuktuk__view-order__list__group"
        >
          <p class="theme--tuktuk__sub-title">{{ $t("common.bumpProduct") }}</p>
          <div
            v-for="(data, i) in data.selectedbumpArray"
            :key="i"
            class="theme--tuktuk__view-order__list__item"
          >
            <p class="theme--tuktuk__view-order__list__item__name">{{ data.bumpName }}</p>
            <div class="theme--tuktuk__view-order__list__item__price">
              <p class="theme--tuktuk__view-order__list__item__price__regular">
                Rp{{ data.bumpPrice | g_number_format }}
              </p>
            </div>
          </div>
        </div>

        <!-- SHIPPING COST AUTOMATES -->
        <div
          v-if="
            data.addShipping &&
            data.shippingType == 'automated' &&
            parseInt(data.payload.deliveryPrice) + parseInt(data.additionalCost) >
              0
          "
          class="theme--tuktuk__view-order__list__item"
        >
          <p class="theme--tuktuk__view-order__list__item__name">{{ $t("common.shippingCosts") }} ({{ data.shippingWeight | gramsToKilograms_format}}kg)</p>
          <div class="theme--tuktuk__view-order__list__item__price">
            <p class="theme--tuktuk__view-order__list__item__price__regular">
              <template v-if="data.payload.deliveryPrice">
                Rp{{ parseInt(data.payload.deliveryPrice) | g_number_format }}
              </template>
              <template v-else>
                {{ parseInt(0) | g_number_format }}
              </template>
            </p>
          </div>
          
        </div>
        <div
          v-if="
            data.shippingType == 'automated' &&
            data.addShipping &&
            data.shippingDiscount &&
            data.shippingDiscount.status &&
            parseInt(data.shippingDiscountValue) > 0
          "
          class="theme--tuktuk__view-order__list__item"
        >
          <p class="theme--tuktuk__view-order__list__item__name">{{ $t("tuktukTheme.shippingDiscounts") }}</p>
          <div class="theme--tuktuk__view-order__list__item__price">
            <p class="theme--tuktuk__view-order__list__item__price__sale">-Rp{{ parseInt(data.shippingDiscountValue) | g_number_format }}</p>
          </div>
        </div>

        <!-- ADDTIONAL SHIPPING COST -->
        <div
          v-if="
            data.shippingType == 'automated' &&
            data.addShipping &&
            data.additionalCost &&
            parseInt(data.additionalCost) != 0
          "
          class="theme--tuktuk__view-order__list__item"
        >
          <p class="theme--tuktuk__view-order__list__item__name">{{ $t("common.additionalShippingCost") }}</p>
          <div class="theme--tuktuk__view-order__list__item__price">
            <p class="theme--tuktuk__view-order__list__item__price__regular">Rp{{ parseInt(data.additionalCost) | g_number_format }}</p>
          </div>
        </div>

        <!-- SHIPPING COST FLAT -->
        <div
          v-if="
            data.addShipping &&
            data.shippingType == 'flat' &&
            parseInt(data.flatShippingPrice ? data.flatShippingPrice : 0) +
              parseInt(data.additionalCost) >
              0
          "
          class="theme--tuktuk__view-order__list__item"
        >
          <p class="theme--tuktuk__view-order__list__item__name">{{ $t("common.shippingCosts") }}</p>
          <div class="theme--tuktuk__view-order__list__item__price">
            <p class="theme--tuktuk__view-order__list__item__price__regular">
              Rp{{
                parseInt(data.flatShippingPrice ? data.flatShippingPrice : 0)
                  | g_number_format
              }}
            </p>
          </div>
        </div>

        <!-- COD COST -->
        <div
          v-if="data.payload.payment == 'COD'"
          class="theme--tuktuk__view-order__list__item"
        >
          <p class="theme--tuktuk__view-order__list__item__name">{{ $t("common.codFee") }}</p>
          <div class="theme--tuktuk__view-order__list__item__price">
            <p class="theme--tuktuk__view-order__list__item__price__regular">
              Rp{{ data.codPrice | g_number_format }}
            </p>
          </div>
        </div>

        <!-- EPAY FEE -->
        <div
          v-if="
            this.data.payload.payment &&
            this.data.payload.payment != 'bankTransfer' &&
            this.data.payload.payment != 'COD'
          "
          class="theme--tuktuk__view-order__list__item"
        >
          <p class="theme--tuktuk__view-order__list__item__name">{{ $t("common.epayFee", { method: paymentFeeMethodName(this.data.payload.payment) }) }}</p>
          <div class="theme--tuktuk__view-order__list__item__price">
            <p class="theme--tuktuk__view-order__list__item__price__regular">
              Rp{{ calEpayFee() | g_number_format }}
            </p>
          </div>
        </div>
      
        <!-- UNIQUE PRICE -->
        <div
          v-if="
            data.payload.uniquePrice && data.payload.payment == 'bankTransfer'
          "
          class="theme--tuktuk__view-order__list__item"
        >
          <p class="theme--tuktuk__view-order__list__item__name">{{ $t("common.uniquePrice") }}</p>
          <div class="theme--tuktuk__view-order__list__item__price">
            <p class="theme--tuktuk__view-order__list__item__price__regular">
              Rp {{ this.data.payload.uniqueType == "Decrease" ? "-" : "" }} {{ data.payload.uniquePrice }}
            </p>
          </div>
        </div>
      </div>
      <!-- END ORDER SUMMARY LIST -->

      <!-- TOTAL -->
      <div class="theme--tuktuk__view-order__total">
        <p class="theme--tuktuk__title">{{ $t("common.total") }}</p>
        <p class="theme--tuktuk__title  theme--tuktuk__view-order__total__number">
          <template v-if="data.multiplyVariations">
            <template
              v-if="
                this.data.payload.payment &&
                this.data.payload.payment != 'bankTransfer' &&
                this.data.payload.payment != 'COD'
              "
            >
              Rp{{ totalwithEpayFee() | g_number_format }}
            </template>
            <template v-else>
                Rp{{
                (data.cartSummary.length > 0 && cartTotalPrice
                      ? cartTotalPrice
                : data.totalPrice) | g_number_format    
              }}
            </template>
          </template>
          <template v-else>
            <template
              v-if="
                this.data.payload.payment &&
                this.data.payload.payment != 'bankTransfer' &&
                this.data.payload.payment != 'COD'
              "
            >
              Rp{{ totalwithEpayFee() | g_number_format }}
            </template>
            <template v-else>Rp{{ data.totalPrice | g_number_format }}</template>
          </template>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { paymentFeeMethodName } from "@/helpers/epayMethodHelper";
export default {
  props: ["data"],
  data() {
    return {
      paymentFeeMethodName
    };
  },
  components: {},
  mounted(){
    this.$emit("calcCart");
  },
  computed:{
    cartTotalPrice: {
      get() {
        return this.data.cartTotal;
      },
      set(val) {
        this.$emit("updateCartTotal", val);
      },
    },
  },
  methods: {   
    getSubPrice(attr) {
      let price = 0;
      if (attr.qty) {
        let subPrice = attr.priceCompare ? attr.priceCompare : attr.price;
        if (attr.whosaleData) {
          attr.whosaleData.map((one) => {
            if (one.count && parseInt(one.count) <= parseInt(attr.qty)) {
              subPrice = one.price;
            }
          });
        }
        price = price + subPrice * attr.qty;
      }
      return price;
    },
    calEpayFee() {
      let fee = 0;
      if(this.data && this.data.payload && this.data.payload.payment){
        if (this.data.payload.payment == "bca_va" || this.data.payload.payment == "bni_va" || this.data.payload.payment == "echannel" || this.data.payload.payment == "bri_va") {
          fee = 4000;
        }
        if (this.data.payload.payment == "gopay") {
          fee = Math.round((this.data.totalPrice * 2) / 100);
        }
        if (this.data.payload.payment == "credit_card") {
          fee = Math.round(4000 + (this.data.totalPrice * 11) / 100);
        }
        if (this.data.payload.payment == "QRIS") {
          fee = Math.round((this.data.totalPrice * 0.7) / 100);
        }
      }
      return fee;
    },
    totalwithEpayFee() {
      return (
        (this.data.cartSummary.length > 0 && this.cartTotalPrice
          ? this.cartTotalPrice
          : this.data.totalPrice) + this.calEpayFee()
      );
    },
  },
  filters: {
  gramsToKilograms_format(val) {
      return (val / 1000).toFixed(1);
    },
  }
};
</script>