var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({ paddingBottom: _vm.data.embed ? '0': _vm.getNavigationBottomHeight() + 'px', }),attrs:{"id":"theme--tuktuk__checkout"}},[_c('div',{staticClass:"theme--tuktuk__navbar theme--tuktuk__checkout__navbar"},[_c('button',[_c('img',{staticClass:"img2",attrs:{"alt":"","src":require('@/assets/icons/TuktukTheme/ic-tuktuk-arrow-left.svg')},on:{"click":function () {
        _vm.setStep(1);
      }}})]),_c('h2',[_vm._v(_vm._s(_vm.$t("tuktukTheme.recipientData")))])]),_c('RequestedFields',{attrs:{"data":Object.assign({}, _vm.data)},on:{"updateCartQuantity":_vm.updateCartQuantity,"saveDataForNexttime":_vm.saveDataForNexttime}}),((_vm.data.showOrderReview && _vm.showOrderSummery) || _vm.data.showBumpProduct)?_c('div',{staticClass:"theme--tuktuk__checkout__info theme--tuktuk__card"},[_c('div',{staticClass:"theme--tuktuk__container"},[(_vm.data.showOrderReview && _vm.showOrderSummery)?_c('OrderSummery',{attrs:{"data":Object.assign({}, _vm.data)},on:{"updateCartQuantity":_vm.updateCartQuantity,"updateCartSummary":_vm.updateCartSummary}}):_vm._e(),(_vm.data.showBumpProduct && _vm.data.isBump)?_c('BumpOrder',{attrs:{"data":Object.assign({}, _vm.data)}}):_vm._e()],1)]):_vm._e(),_c('Shipping',{attrs:{"data":Object.assign({}, _vm.data),"shippingCourierItems":_vm.shippingCourierItems}}),_c('ViewOrderSummery',{attrs:{"data":Object.assign({}, _vm.data)},on:{"updateCartTotal":_vm.updateCartTotal,"calcCart":_vm.calcCart}}),(_vm.data.showPayment)?_c('PaymentMethod',{attrs:{"data":Object.assign({}, _vm.data)}}):_vm._e(),(_vm.data.showOtherContent)?_c('div',{staticClass:"theme--tuktuk__checkout__user-terms theme--tuktuk__card"},[_c('div',{staticClass:"theme--tuktuk__container"},[_c('h2',{staticClass:"theme--tuktuk__title"},[_vm._v(_vm._s(_vm.data.otherContentTitle || 'Kebijakan Privasi'))]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.otherContent)}})])]):_vm._e(),_c('div',{staticClass:"theme--tuktuk__navigation-bottom theme--tuktuk__checkout__navigation-bottom"},[(_vm.data.orderSummary && _vm.data.payload)?_c('div',{staticClass:"theme--tuktuk__checkout__navigation-bottom__total"},[_c('div',{staticClass:"theme--tuktuk__checkout__navigation-bottom__total__content"},[_c('h2',{staticClass:"theme--tuktuk__title"},[_vm._v(_vm._s(_vm.$t("common.total")))]),_c('p',{staticClass:"theme--tuktuk__title"},[(_vm.data.multiplyVariations)?[(this.data.payload.payment &&
              this.data.payload.payment != 'bankTransfer' &&
              this.data.payload.payment != 'COD'
              )?[_vm._v(" Rp"+_vm._s(_vm._f("g_number_format")(_vm.totalwithEpayFee()))+" ")]:[_vm._v(" Rp"+_vm._s(_vm._f("g_number_format")((_vm.data.cartSummary.length > 0 && _vm.cartTotalPrice ? _vm.cartTotalPrice : _vm.data.totalPrice)))+" ")]]:[(this.data.payload.payment &&
                this.data.payload.payment != 'bankTransfer' &&
                this.data.payload.payment != 'COD'
                )?[_vm._v(" Rp"+_vm._s(_vm._f("g_number_format")(_vm.totalwithEpayFee()))+" ")]:[_vm._v("Rp"+_vm._s(_vm._f("g_number_format")(_vm.data.totalPrice)))]]],2)])]):_vm._e(),_c('button',{staticClass:"theme--tuktuk__button-pink--fill",style:((_vm.data.isBuyButtonLoading | _vm.data.loadingPrice
        ? '; opacity: 0.5; pointer-event: none; cursor: default;'
        : '')),attrs:{"disabled":_vm.data.isBuyButtonLoading || _vm.data.loadingPrice},on:{"click":function () { return !_vm.data.loadingPrice && _vm.onSubmitted(); }}},[_vm._v(_vm._s(_vm.$t("tuktukTheme.createOrder")))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }