<template>
  <!-- BUMP PRODUCT -->
    <div v-if="data.bumpArray.length > 0
      " class="theme--tuktuk__checkout__bump">
      <div v-for="(bumpData, i) in data.bumpArray" :key="i" class="theme--tuktuk__checkout__bump__item" @click="(e) => { addBumpOrder(e, bumpData) }">
        <div class="theme--tuktuk__checkout__bump__item__content" :class="bumpData.bumpImage ? 'theme--tuktuk__checkout__bump__item__content--show-thumbnail' : ''">
          <div v-if="bumpData.bumpImage" class="theme--tuktuk__checkout__bump__item--show-thumbnail__container" >
            <img :src="getSizedImageUrl(bumpData.bumpImage,'thumbnail','product-images')" />
          </div>
          <div class="theme--tuktuk__checkout__bump__item__product">
            <div class="theme--tuktuk__checkout__bump__item__product__detail">
              <h3>{{ bumpData.bumpName }}</h3>
              <p v-if="bumpData.bumpDescription">
                {{ bumpData.bumpDescription }}
              </p>
            </div>
            <p class="theme--tuktuk__checkout__bump__item__product__price">Rp {{ bumpData.bumpPrice | g_number_format }}</p>
          </div>
        </div>

        <div class="theme--tuktuk__checkout__bump__item__action">
            <vs-checkbox v-model="data.selectedbumpArray" :key="i" :val="bumpData" class="theme--tuktuk__checkbox">
              <template #icon >
                <img src="@/assets/icons/TuktukTheme/checklist--pink.svg" class="theme--tuktuk__checkbox__active"/>
              </template>
              {{ bumpData.bumpText3 }}
            </vs-checkbox>
            <input type="hidden" disabled class="checkbox-text bumpText3 ml-2" v-model="bumpData.bumpText3" />
        </div>
      </div>
    </div>
</template>
<script>
import config from "./../../../../config.js";
import { getSizedImageUrl } from "@/helpers/imagekitHelper";

export default {
  props: ["data"],
  data() {
    return {
      baseUrl: config.api.replace("/api", ""),
      getSizedImageUrl
    };
  },
  components: {

  },
  methods: {
    addBumpOrder(event,bumpData){      
      if(event.target.className!='vs-checkbox'){      
        const bumpIndex=this.data.selectedbumpArray.findIndex(x=>x.bump_id==bumpData.bump_id);
        if(bumpIndex==-1){
          this.data.selectedbumpArray.push(bumpData);
        }else{
          this.data.selectedbumpArray.splice(bumpIndex,1);
        }         
        event.preventDefault();      
     }
    },  
  }
};
</script>

<style scoped>
.theme--Tuktuk  #checkout-content--right .checkout-content--right__bump .embed-checkout-right__bump__item{
    border: 1px solid #E8E8E8
  }
  .theme--Tuktuk  #checkout-content--right .checkout-content--right__bump .embed-checkout-right__bump__item .bump__item__action--buy{
    border: 1px solid #EB435B80;
    background: linear-gradient(0deg, rgba(232, 67, 89, 0.1), rgba(232, 67, 89, 0.1)),
linear-gradient(0deg, rgba(235, 67, 91, 0.5), rgba(235, 67, 91, 0.5));
color: #15171C;
  }
  .theme--Tuktuk #checkout-content--right .checkout-content--right__bump .embed-checkout-right__bump__item .bump__item__action--buy .vs-checkbox-label{
    color: #15171C !important;
  }




</style>