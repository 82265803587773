var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data.payload)?_c('div',{staticClass:"theme--tuktuk__card",attrs:{"id":"theme--tuktuk__view-order"}},[_c('div',{staticClass:"theme--tuktuk__container"},[_c('h2',{staticClass:"theme--tuktuk__title"},[_vm._v(_vm._s(_vm.$t("common.orderDetails")))]),_c('div',{staticClass:"theme--tuktuk__view-order__list"},[(_vm.data.type == 'simple')?_c('div',{staticClass:"theme--tuktuk__view-order__list__item"},[_c('div',{staticClass:"theme--tuktuk__view-order__list__item__variant"},[_c('p',{staticClass:"theme--tuktuk__view-order__list__item__qty"},[_vm._v(" "+_vm._s(_vm.data.cartQuantity)+"x ")]),_c('p',{staticClass:"theme--tuktuk__view-order__list__item__name"},[_vm._v(" "+_vm._s(_vm.data.name)+" ")])]),(_vm.data.type == 'simple')?_c('div',{staticClass:"theme--tuktuk__view-order__list__item__price"},[(_vm.data.priceCompare)?[_c('div',{staticClass:"theme--tuktuk__view-order__list__item__price__sale_container"},[_c('p',{staticClass:"theme--tuktuk__view-order__list__item__price__sale"},[_vm._v(" Rp"+_vm._s(_vm._f("g_number_format")(_vm.data.cartPrice))+" ")]),_c('p',{staticClass:"theme--tuktuk__view-order__list__item__price__regular"},[(_vm.data.priceCompare && _vm.data.type == 'simple')?[_vm._v(" Rp"+_vm._s(_vm._f("g_number_format")((_vm.data.cartQuantity * _vm.data.price)))+" ")]:(parseInt(_vm.data.priceWithoutWhosale) !== parseInt(_vm.data.cartPrice))?[_vm._v(" Rp"+_vm._s(_vm._f("g_number_format")(_vm.data.priceWithoutWhosale))+" ")]:_vm._e()],2)])]:[_c('p',{staticClass:"theme--tuktuk__view-order__list__item__price__regular"},[_vm._v(" Rp"+_vm._s(_vm._f("g_number_format")(_vm.data.cartPrice))+" ")])]],2):_vm._e()]):_vm._e(),(_vm.data.type != 'simple')?_c('div',{staticClass:"theme--tuktuk__view-order__list__group"},[_c('p',{staticClass:"theme--tuktuk__sub-title"},[_vm._v(" "+_vm._s(_vm.data.name)+" ")]),(
            !_vm.data.multiplyVariations ||
            _vm.data.type == 'simple' ||
            (_vm.data.multiplyVariations && _vm.data.cartOrder)
          )?[(
              _vm.data.cartSummary &&
              _vm.data.cartSummary.length > 0 &&
              _vm.data.multiplyVariations
            )?[_vm._l((_vm.data.cartSummary),function(elem,i){return [(elem.qty)?_c('div',{key:i,staticClass:"theme--tuktuk__view-order__list__item "},[_c('div',{staticClass:"theme--tuktuk__view-order__list__item__variant"},[_c('p',{staticClass:"theme--tuktuk__view-order__list__item__qty"},[_vm._v(_vm._s(elem.qty)+"x")]),_c('div',{staticClass:"theme--tuktuk__view-order__list__item__variant__list"},_vm._l((Object.keys(elem.items)),function(elemChild,j){return _c('p',{key:j + 'p',staticClass:"theme--tuktuk__view-order__list__item__name"},[_vm._v(" "+_vm._s(elemChild.substring(0, elemChild.length - 1))+" "+_vm._s(elem.items[elemChild].name)+" ")])}),0)]),_c('div',{staticClass:"theme--tuktuk__view-order__list__item__price"},[(
                    _vm.getSubPrice(elem)  > 0 &&
                    _vm.getSubPrice(elem) !== elem.priceOriginal * elem.qty
                    )?[_c('h2',{staticClass:"theme--tuktuk__view-order__list__item__price__sale"},[_vm._v(" Rp"+_vm._s(_vm._f("g_number_format")(_vm.getSubPrice(elem)))+" ")]),_c('div',{staticClass:"theme--tuktuk__view-order__list__item__price__sale_container"},[_c('p',{staticClass:"theme--tuktuk__view-order__list__item__price__regular"},[[_vm._v(" Rp"+_vm._s(_vm._f("g_number_format")((elem.priceOriginal * elem.qty)))+" ")]],2)])]:[_c('h2',{staticClass:"theme--tuktuk__view-order__list__item__price__regular"},[_vm._v(" Rp"+_vm._s(_vm._f("g_number_format")((elem.priceOriginal * elem.qty)))+" ")])]],2)]):_vm._e()]})]:_c('div',{staticClass:"theme--tuktuk__view-order__list__item"},[_c('div',{staticClass:"theme--tuktuk__view-order__list__item__variant"},[_c('p',{staticClass:"theme--tuktuk__view-order__list__item__qty"},[_vm._v(_vm._s(_vm.data.cartQuantity)+"x")]),_c('div',{staticClass:"theme--tuktuk__view-order__list__item__variant__list"},_vm._l((Object.keys(
                    _vm.data.payload.variablePrices
                  )),function(vprice,l){return _c('p',{key:l + 'p',staticClass:"theme--tuktuk__view-order__list__item__name"},[_vm._v(" "+_vm._s(vprice.substring(0, vprice.length - 1))+" "+_vm._s(_vm.data.payload.variablePrices[vprice].name)+" ")])}),0)]),_c('div',{staticClass:"theme--tuktuk__view-order__list__item__price"},[(
                parseInt(_vm.data.priceWithoutWhosale) !==
                  parseInt(_vm.data.cartPrice)
              )?[_c('h2',{staticClass:"theme--tuktuk__view-order__list__item__price__sale"},[_vm._v(" Rp"+_vm._s(_vm._f("g_number_format")(_vm.data.cartPrice))+" ")]),_c('div',{staticClass:"theme--tuktuk__view-order__list__item__price__sale_container"},[_c('p',{staticClass:"theme--tuktuk__view-order__list__item__price__regular"},[[_vm._v(" Rp"+_vm._s(_vm._f("g_number_format")(_vm.data.priceWithoutWhosale))+" ")]],2)])]:[_c('h2',{staticClass:"theme--tuktuk__view-order__list__item__price__regular"},[_vm._v(" Rp"+_vm._s(_vm._f("g_number_format")(_vm.data.cartPrice))+" ")])]],2)])]:[_vm._l((_vm.data.variablePrices),function(attr,i){return [(attr.qty > 0)?_c('div',{key:'f' + i,staticClass:"theme--tuktuk__view-order__list__item"},[_c('div',{staticClass:"theme--tuktuk__view-order__list__item__variant"},[_c('p',{staticClass:"theme--tuktuk__view-order__list__item__qty"},[_vm._v(_vm._s(attr.qty)+"x")]),_c('div',{staticClass:"theme--tuktuk__view-order__list__item__variant__list"},_vm._l((attr.attrs),function(elemChild,j){return _c('p',{key:j + 'p',staticClass:"theme--tuktuk__view-order__list__item__name"},[_vm._v(" "+_vm._s(elemChild.name.substring(0, elemChild.name.length - 1))+" "+_vm._s(elemChild.value)+" ")])}),0)]),_c('div',{staticClass:"theme--tuktuk__view-order__list__item__price"},[(
                        _vm.getSubPrice(attr) !== attr.price * attr.qty
                      )?[_c('h2',{staticClass:"theme--tuktuk__view-order__list__item__price__sale"},[_vm._v(" Rp"+_vm._s(_vm._f("g_number_format")(_vm.getSubPrice(attr)))+" ")]),_c('div',{staticClass:"theme--tuktuk__view-order__list__item__price__sale_container"},[_c('p',{staticClass:"theme--tuktuk__view-order__list__item__price__regular"},[[_vm._v(" Rp"+_vm._s(_vm._f("g_number_format")((attr.price * attr.qty)))+" ")]],2)])]:[_c('h2',{staticClass:"theme--tuktuk__view-order__list__item__price__regular"},[_vm._v(" Rp"+_vm._s(_vm._f("g_number_format")((attr.price * attr.qty)))+" ")])]],2),_c('div',{staticClass:"theme--tuktuk__view-order__list__item__price"},[_c('p',{staticClass:"theme--tuktuk__view-order__list__item__price__regular"}),(_vm.getSubPrice(attr) !== attr.price * attr.qty)?_c('p',{staticClass:"theme--tuktuk__view-order__list__item__price__sale"}):_vm._e()])]):_vm._e()]})]],2):_vm._e(),(_vm.data.isBump && _vm.data.selectedbumpArray.length > 0)?_c('div',{staticClass:"theme--tuktuk__view-order__list__group"},[_c('p',{staticClass:"theme--tuktuk__sub-title"},[_vm._v(_vm._s(_vm.$t("common.bumpProduct")))]),_vm._l((_vm.data.selectedbumpArray),function(data,i){return _c('div',{key:i,staticClass:"theme--tuktuk__view-order__list__item"},[_c('p',{staticClass:"theme--tuktuk__view-order__list__item__name"},[_vm._v(_vm._s(data.bumpName))]),_c('div',{staticClass:"theme--tuktuk__view-order__list__item__price"},[_c('p',{staticClass:"theme--tuktuk__view-order__list__item__price__regular"},[_vm._v(" Rp"+_vm._s(_vm._f("g_number_format")(data.bumpPrice))+" ")])])])})],2):_vm._e(),(
          _vm.data.addShipping &&
          _vm.data.shippingType == 'automated' &&
          parseInt(_vm.data.payload.deliveryPrice) + parseInt(_vm.data.additionalCost) >
            0
        )?_c('div',{staticClass:"theme--tuktuk__view-order__list__item"},[_c('p',{staticClass:"theme--tuktuk__view-order__list__item__name"},[_vm._v(_vm._s(_vm.$t("common.shippingCosts"))+" ("+_vm._s(_vm._f("gramsToKilograms_format")(_vm.data.shippingWeight))+"kg)")]),_c('div',{staticClass:"theme--tuktuk__view-order__list__item__price"},[_c('p',{staticClass:"theme--tuktuk__view-order__list__item__price__regular"},[(_vm.data.payload.deliveryPrice)?[_vm._v(" Rp"+_vm._s(_vm._f("g_number_format")(parseInt(_vm.data.payload.deliveryPrice)))+" ")]:[_vm._v(" "+_vm._s(_vm._f("g_number_format")(parseInt(0)))+" ")]],2)])]):_vm._e(),(
          _vm.data.shippingType == 'automated' &&
          _vm.data.addShipping &&
          _vm.data.shippingDiscount &&
          _vm.data.shippingDiscount.status &&
          parseInt(_vm.data.shippingDiscountValue) > 0
        )?_c('div',{staticClass:"theme--tuktuk__view-order__list__item"},[_c('p',{staticClass:"theme--tuktuk__view-order__list__item__name"},[_vm._v(_vm._s(_vm.$t("tuktukTheme.shippingDiscounts")))]),_c('div',{staticClass:"theme--tuktuk__view-order__list__item__price"},[_c('p',{staticClass:"theme--tuktuk__view-order__list__item__price__sale"},[_vm._v("-Rp"+_vm._s(_vm._f("g_number_format")(parseInt(_vm.data.shippingDiscountValue))))])])]):_vm._e(),(
          _vm.data.shippingType == 'automated' &&
          _vm.data.addShipping &&
          _vm.data.additionalCost &&
          parseInt(_vm.data.additionalCost) != 0
        )?_c('div',{staticClass:"theme--tuktuk__view-order__list__item"},[_c('p',{staticClass:"theme--tuktuk__view-order__list__item__name"},[_vm._v(_vm._s(_vm.$t("common.additionalShippingCost")))]),_c('div',{staticClass:"theme--tuktuk__view-order__list__item__price"},[_c('p',{staticClass:"theme--tuktuk__view-order__list__item__price__regular"},[_vm._v("Rp"+_vm._s(_vm._f("g_number_format")(parseInt(_vm.data.additionalCost))))])])]):_vm._e(),(
          _vm.data.addShipping &&
          _vm.data.shippingType == 'flat' &&
          parseInt(_vm.data.flatShippingPrice ? _vm.data.flatShippingPrice : 0) +
            parseInt(_vm.data.additionalCost) >
            0
        )?_c('div',{staticClass:"theme--tuktuk__view-order__list__item"},[_c('p',{staticClass:"theme--tuktuk__view-order__list__item__name"},[_vm._v(_vm._s(_vm.$t("common.shippingCosts")))]),_c('div',{staticClass:"theme--tuktuk__view-order__list__item__price"},[_c('p',{staticClass:"theme--tuktuk__view-order__list__item__price__regular"},[_vm._v(" Rp"+_vm._s(_vm._f("g_number_format")(parseInt(_vm.data.flatShippingPrice ? _vm.data.flatShippingPrice : 0)))+" ")])])]):_vm._e(),(_vm.data.payload.payment == 'COD')?_c('div',{staticClass:"theme--tuktuk__view-order__list__item"},[_c('p',{staticClass:"theme--tuktuk__view-order__list__item__name"},[_vm._v(_vm._s(_vm.$t("common.codFee")))]),_c('div',{staticClass:"theme--tuktuk__view-order__list__item__price"},[_c('p',{staticClass:"theme--tuktuk__view-order__list__item__price__regular"},[_vm._v(" Rp"+_vm._s(_vm._f("g_number_format")(_vm.data.codPrice))+" ")])])]):_vm._e(),(
          this.data.payload.payment &&
          this.data.payload.payment != 'bankTransfer' &&
          this.data.payload.payment != 'COD'
        )?_c('div',{staticClass:"theme--tuktuk__view-order__list__item"},[_c('p',{staticClass:"theme--tuktuk__view-order__list__item__name"},[_vm._v(_vm._s(_vm.$t("common.epayFee", { method: _vm.paymentFeeMethodName(this.data.payload.payment) })))]),_c('div',{staticClass:"theme--tuktuk__view-order__list__item__price"},[_c('p',{staticClass:"theme--tuktuk__view-order__list__item__price__regular"},[_vm._v(" Rp"+_vm._s(_vm._f("g_number_format")(_vm.calEpayFee()))+" ")])])]):_vm._e(),(
          _vm.data.payload.uniquePrice && _vm.data.payload.payment == 'bankTransfer'
        )?_c('div',{staticClass:"theme--tuktuk__view-order__list__item"},[_c('p',{staticClass:"theme--tuktuk__view-order__list__item__name"},[_vm._v(_vm._s(_vm.$t("common.uniquePrice")))]),_c('div',{staticClass:"theme--tuktuk__view-order__list__item__price"},[_c('p',{staticClass:"theme--tuktuk__view-order__list__item__price__regular"},[_vm._v(" Rp "+_vm._s(this.data.payload.uniqueType == "Decrease" ? "-" : "")+" "+_vm._s(_vm.data.payload.uniquePrice)+" ")])])]):_vm._e()]),_c('div',{staticClass:"theme--tuktuk__view-order__total"},[_c('p',{staticClass:"theme--tuktuk__title"},[_vm._v(_vm._s(_vm.$t("common.total")))]),_c('p',{staticClass:"theme--tuktuk__title  theme--tuktuk__view-order__total__number"},[(_vm.data.multiplyVariations)?[(
              this.data.payload.payment &&
              this.data.payload.payment != 'bankTransfer' &&
              this.data.payload.payment != 'COD'
            )?[_vm._v(" Rp"+_vm._s(_vm._f("g_number_format")(_vm.totalwithEpayFee()))+" ")]:[_vm._v(" Rp"+_vm._s(_vm._f("g_number_format")((_vm.data.cartSummary.length > 0 && _vm.cartTotalPrice ? _vm.cartTotalPrice : _vm.data.totalPrice)))+" ")]]:[(
              this.data.payload.payment &&
              this.data.payload.payment != 'bankTransfer' &&
              this.data.payload.payment != 'COD'
            )?[_vm._v(" Rp"+_vm._s(_vm._f("g_number_format")(_vm.totalwithEpayFee()))+" ")]:[_vm._v("Rp"+_vm._s(_vm._f("g_number_format")(_vm.data.totalPrice)))]]],2)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }