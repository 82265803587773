<script>
import 'swiper/swiper-bundle.min.css'
import Swiper, { Pagination, Navigation } from 'swiper'
// import VueZoomer from 'vue-zoomer'
import { getSizedImageUrl } from "@/helpers/imagekitHelper";

export default {
    props: {
        images: Array,
        variantImageIndex: Number,
        type: {
            type: String,
            validator(type) {
                return typeof type !== 'undefined' && ['tuktuk', 'classic'].includes(type)
            }
        }
    },
    components: {
        // VZoomer: VueZoomer.Zoomer,
    },
    data() {
        return {
            getSizedImageUrl,
            swiper: undefined,
            isMobile: window.innerWidth < 575,
            zoomed: false,
        }
    },
    mounted() {
        if(process.client){
            // disable pinch to zoom
            document.body.style.touchAction = 'pan-y pan-x !important';
            document.addEventListener("gesturestart", function (e) {
                e.preventDefault();
                document.body.style.zoom = 0.99;
            });

            document.addEventListener("gesturechange", function (e) {
                e.preventDefault();

                document.body.style.zoom = 0.99;
            });
            document.addEventListener("gestureend", function (e) {
                e.preventDefault();
                document.body.style.zoom = 1;
            });          

            window.addEventListener('resize', this.onResize);
            const modules = this.type === 'classic' ? [Navigation, Pagination] : [Pagination]
            this.swiper = new Swiper(this.$refs.swiper, {
                modules,
                pagination: {
                    el: '.swiper-pagination',
                    type: this.type === 'classic' ? 'bullets' : 'fraction',
                    clickable: this.type === 'classic'
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            })
         }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
        document.body.style.touchAction = null;
        document.removeEventListener("gesturestart", function (e) {
            e.preventDefault();
            document.body.style.zoom = 0.99;
        });

        document.removeEventListener("gesturechange", function (e) {
            e.preventDefault();

            document.body.style.zoom = 0.99;
        });
        document.removeEventListener("gestureend", function (e) {
            e.preventDefault();
            document.body.style.zoom = 1;
        });
    },
    methods: {
        onResize() {
            this.isMobile = window.innerWidth < 575;
        },
        productImageZoom(e) {
            const zoomImage = e.currentTarget
            const { offsetX, offsetY } = e;
            zoomImage.style.transformOrigin = `${offsetX}px ${offsetY}px`
            zoomImage.style.transform = `scale(2)`;
        },

        productImageZoomReset(e) {
            const zoomImage = e.currentTarget
            zoomImage.style.transform = 'scale(1)';
        },
        preventPinchZoom(e) {
            e.preventDefault();
            document.body.style.zoom = 1;
        }
    },
    watch: {
        zoomed(zoomed) {
            if (this.isMobile && zoomed) {
                this.swiper.disable();
            } else {
                this.swiper.enable();
            }
        },
        variantImageIndex(selected) {
           this.swiper.slideTo(selected)
        }
    }
};
</script>

<template>   
  
    <div v-if="type === 'classic'" class="checkout-content--left__feature checkout-content--left__main__product-image">
        <div v-if="images.length <= 1" class="left__main__product-image__container--single" @touchmove="preventPinchZoom">
            <img
                v-if="!isMobile && images[0]"
                :alt="images[0].src"
                :src="getSizedImageUrl(images[0].src, 'fullView', 'product-images')"
                @mousemove="productImageZoom"
                @mouseleave="productImageZoomReset"
            />
            <template v-else-if="isMobile && images[0]" >
            
              <v-zoomer :style="{ width: '100%', height: '100%', touchAction: zoomed ? 'none' : 'auto' }" :zoomed.sync="zoomed">
                  <img
                      :src="getSizedImageUrl(images[0].src, 'fullView', 'product-images')"
                      style="object-fit: contain; width: 100%; height: 100%;"
                  >
              </v-zoomer>
          
          </template>
        </div>
        <div class="left__main__product-image__container--swiper" :style="{ display: images.length > 1 ? 'flex' : 'none' }">
            <div ref="swiper" class="swiper">
                <div class="swiper-wrapper">
                    <div v-for="(image, i) in images" :key="i" class="swiper-slide" @touchmove="preventPinchZoom">
                        <img
                            v-if="!isMobile"
                            class="w-100 image-carousel"
                            :src="getSizedImageUrl(image.src, 'fullView', 'product-images')"
                            @mousemove="productImageZoom"
                            @mouseleave="productImageZoomReset"
                        />
                        <v-zoomer v-else-if="isMobile && images[0]" :style="{ width: '100%', height: '100%', touchAction: zoomed ? 'none' : 'auto' }" :zoomed.sync="zoomed">
                            <img
                                :src="getSizedImageUrl(image.src, 'fullView', 'product-images')"
                                style="object-fit: contain; width: 100%; height: 100%;"
                            >
                        </v-zoomer>
                    </div>
                </div>
                <div class="swiper-pagination"></div>
                <div class="custom-arrow custom-arrow--left">
                    <img src="@/assets/icons/slick-left-arrow-new.png" alt="left-arrow" />
                </div>
                <div class="swiper-button-next">
                    <img src="@/assets/icons/slick-right-arrow-new.png" alt="right-arrow" />
                </div>
                <div class="swiper-button-prev custom-arrow">
                    <img src="@/assets/icons/slick-left-arrow-new.png" alt="left-arrow" />
                </div>
            </div>
        </div>
    </div>
    <div v-else class="theme--tuktuk__product-details__thumbnail">
        <div v-if="images.length <= 1" class="theme--tuktuk__product-details__thumbnail__single" @touchmove="preventPinchZoom">
            <img
                v-if="!isMobile && images[0]"
                class="w-100 image-carousel"
                :src="getSizedImageUrl(images[0].src, 'fullView', 'product-images')"
                @mousemove="productImageZoom"
                @mouseleave="productImageZoomReset"
            />
            <v-zoomer v-else-if="isMobile && images[0]" :style="{ width: '100%', height: '100%', touchAction: zoomed ? 'none' : 'auto' }" :zoomed.sync="zoomed">
                <img
                    :src="getSizedImageUrl(images[0].src, 'fullView', 'product-images')"
                    style="object-fit: contain; width: 100%; height: 100%;"
                >
            </v-zoomer>
        </div>
        <div v-if="images.length > 1" class="theme--tuktuk__product-details__thumbnail__carousel">
            <div ref="swiper" class="swiper">
                <div class="swiper-wrapper">
                    <div v-for="(image, i) in images" :key="i" class="swiper-slide" @touchmove="preventPinchZoom">
                        <img
                            v-if="!isMobile"
                            class="w-100 image-carousel"
                            :src="getSizedImageUrl(image.src, 'fullView', 'product-images')"
                            @mousemove="productImageZoom"
                            @mouseleave="productImageZoomReset"
                        />
                        <v-zoomer v-else-if="isMobile && images[0]" :style="{ width: '100%', height: '100%', touchAction: zoomed ? 'none' : 'auto' }" :zoomed.sync="zoomed">
                            <img
                                :src="getSizedImageUrl(image.src, 'fullView', 'product-images')"
                                style="object-fit: contain; width: 100%; height: 100%;"
                            >
                        </v-zoomer>
                    </div>
                </div>
                <div class="swiper-pagination"></div>
            </div>
        </div>
    </div>
 

</template>


<style lang="scss">
body {
    touch-action: pan-y pan-x !important;
}
.theme--tuktuk__product-details__thumbnail {
    &__carousel {
        width: 100%;
        aspect-ratio: 1;

        .swiper {
            height: 100%;

            &-wrapper {
                height: 100%;
            }
        }
    }
}
.swiper {
    position: relative;
        
    .image-carousel {
        width: 100%;
        height: 100%;
        object-fit: contain;
        user-select: none;
        transition: transform 0.2s ease-out;
    }

    &-wrapper {
        height: 335px;
    }

    &-slide {
        display: flex;
        align-items: center;
    }

    &-pagination {
        position: absolute;
        bottom: 20px;
        left: calc(100% - 65px);
        max-width: fit-content;
        z-index: 1;
        display: inline-block;
        padding: 4px 12px;
        border-radius: 4px;
        background: rgba(32, 32, 32, 0.4);
        color: #FFF;
        text-align: center;
        font-family: TikTokDisplayBold;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%;
    }
}
.left__main__product-image__container {
    &--swiper {
        width: 100%;
        aspect-ratio: 1/1;
        background-color: #fff;
        border: 1px solid #d9dee2;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;

        .swiper {
            position: relative;
            width: 100%;
            height: 100%;

            &-wrapper {
                width: 100%;
                height: 100%;
            }

            &-pagination {
                background-color: transparent;
                left: 50%;
                transform: translateX(-50%);
                --swiper-pagination-bullet-size: 10px;

                .swiper-pagination-bullet {
                    margin: 0 10px;
                    &-active {
                        background-color: black;
                        opacity: 0.75;
                    }
                }
            }

            &-button-prev, &-button-next {
                width: 24px;
                height: 24px;
                background-color: rgba(0, 0, 0, 0.35);
            }

            &-button-prev {
                left: 0px !important;
            }

            &-button-next {
                right: 0px !important;
            }

            &-button-prev::after, &-button-next::after {
                content: '';
            }
        }
    }
}
</style>
