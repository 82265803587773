// i18n.js
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'id', // Default locale
  fallbackLocale: 'id', // Fallback locale
  messages: {
    en: require('./locales/en.json'), // Adjust the path to your translation files
    id: require('./locales/id.json'), // Adjust the path to your translation files
  },
});

export default i18n;



