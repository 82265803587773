var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:!_vm.form ? '' : 'form-card',attrs:{"id":"success-content"}},[_c('div',{staticClass:"preview-card-scroll"},[(_vm.data.successShowTitle && _vm.data.successTitle)?[_c('h1',{staticClass:"success-content__title"},[_vm._v(" "+_vm._s(_vm.data.successTitle)+" ")])]:_vm._e(),_c('div',{staticClass:"card-body p-0",class:_vm.isMobile ? 'mobileView_inDesktop' : '',style:(!_vm.data.successShowTitle
          ? ''
          : '')},[_c('div',{staticClass:"preview-main no_border"},[_vm._m(0),_c('h2',{staticClass:"thank-you"},[_vm._v(_vm._s(_vm.successHeadline))]),(_vm.data.successVideo)?_c('div',{staticClass:"preview-success-video"},[_c('iframe',{staticClass:"embed-responsive-item",attrs:{"src":'https://www.youtube.com/embed/' + _vm.videoUrl,"allowfullscreen":""}})]):_vm._e(),_c('div',{staticClass:"desc-block",domProps:{"innerHTML":_vm._s(_vm.textData)}}),(
            _vm.data.successTextEpayment &&
            _vm.data.successShowChatButton &&
            _vm.paymentInfo != 'bankTransfer'
          )?_c('div',[_vm._v(" ` "),_c('hr',{staticClass:"line-pengiriman"}),_c('h2',{staticClass:"text-center w-100 bank-text-1"},[_vm._v(" "+_vm._s(_vm.$t('payment.deliveryProblems'))+" ")]),_c('p',{staticClass:"text-center mt-20 mb-20"},[_vm._v(" "+_vm._s(_vm.$t('payment.contactSeller'))+" ")]),_c('br'),_c('div',{staticClass:"customerwhatsapp",on:{"click":_vm.sellerContact}},[_c('img',{attrs:{"src":require("@/assets/icons/whatsapp-link.svg")}}),_c('div',{staticClass:"whatsapp-button ml-1"},[_vm._v(" "+_vm._s(_vm.$t('payment.contactViaWhatsapp'))+" ")])])]):_vm._e(),(
            _vm.data.whenComplete == 'csNumber' || _vm.data.whenComplete == 'random'
          )?_c('div',[_vm._v(" ` "),_c('hr',{staticClass:"line-pengiriman"}),_c('h2',{staticClass:"text-center w-100 bank-text-1"},[_vm._v(" Anda akan diarahkan ke salah satu CS kami dalam beberapa saat... ")]),_c('p',{staticClass:"text-center mt-20 mb-20"},[_vm._v(" Klik tombol dibawah jika anda tidak diarahkan ")]),_c('br'),_c('div',{staticClass:"customerwhatsapp-continue",on:{"click":_vm.continueWhatsapp}},[_c('img',{attrs:{"src":require("@/assets/icons/whatsapp-link.svg")}}),_c('div',{staticClass:"whatsapp-button ml-1"},[_vm._v(" continue ")])])]):_vm._e()])]),_c('div',{staticClass:"copyright-content payment-confirmation__copyright-content"},[(_vm.data.isPoweredBy)?_c('div',{staticClass:"copyright-text__poweredby"},[_c('p',{staticClass:"copyright-text__poweredby__text"},[_vm._v("Powered by")]),_c('img',{attrs:{"src":require("@/assets/icons/formulir-logo.png")}})]):_vm._e(),_c('p',{staticClass:"copyright-content__text"},[_vm._v(" Copyright © "+_vm._s(_vm._f("year_format")(new Date()))+" ")])])],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"success-content__ilust"},[_c('img',{attrs:{"width":"102","src":require("@/assets/icons/success-order.svg"),"alt":"success icon"}})])}]

export { render, staticRenderFns }