<template>
  <div v-if="Array.isArray(sealData)" class="theme--tuktuk__seal">
    <VueSlickCarousel
      :arrows="true"
      :dots="false"
      :infinite="false"
      :variableWidth="true"
      :slidesToShow="2"
      class="theme--tuktuk__seal__carousel"
    >
      <!-- CUSTOMIZE ARROW -->
      <template #prevArrow>
        <div class="theme--tuktuk__seal__carousel__arrow-prev">
          <img src="@/assets/icons/slick-left-arrow-new.png" alt="left-arrow" />
        </div>
      </template>

      <template #nextArrow>
        <div class="theme--tuktuk__seal__carousel__arrow-next" >
          <img src="@/assets/icons/TuktukTheme/ic-nav-tuktuk--next--grey.svg" alt="next" />
        </div>
      </template>

      <div
        v-bind:key="d"
        v-for="d in sealData"
        class="theme--tuktuk__seal__item"
      >
        <div class="theme--tuktuk__seal__item__container">
          <img
            width="16px"
            height="16px"
            :alt="sealData"
            :src="
              d == '100Jaminan'
                ? require('@/assets/icons/TuktukTheme/Checkout Seal/jaminan-kepuasan.svg')
                : d == 'Gratis'
                ? require('@/assets/icons/TuktukTheme/Checkout Seal/gratis-ongkir.svg')
                : d == 'Diskon'
                ? require('@/assets/icons/TuktukTheme/Checkout Seal/harga-special.svg')
                : d == 'Garansi'
                ? require('@/assets/icons/TuktukTheme/Checkout Seal/garansi-uang-kembali.svg')
                : d == 'Enkripsi'
                ? require('@/assets/icons/TuktukTheme/Checkout Seal/transaksi-dijamin-aman.svg')
                : d == 'Gratis2'
                ? require('@/assets/icons/TuktukTheme/Checkout Seal/kualitas-terbaik.svg')
                : ''
            "
          />
          <p>
            {{
              d == "100Jaminan"
                ? $t("seals.satisfactionGuarantee")
                : d == "Garansi"
                ? $t("seals.moneyBackGuarantee")
                : d == "Enkripsi"
                ? $t("seals.transactionsGuaranteedSafe")
                : d == "Gratis2"
                ? $t("seals.bestQuality")
                : d == "Gratis"
                ? $t("seals.freeShipping")
                : d == "Diskon"
                ? $t("seals.specialPrice")
                : ""
            }}
          </p>
        </div>
      </div>
    </VueSlickCarousel>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  props: ["data"],
  data() {
    return {
      sealData: undefined,
    };
  },
  watch: {
    "data.sealText": {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.sealData = newValue;
        } else {
          this.sealData = undefined;
        }
      },
    },
  },
  components: {
    VueSlickCarousel,
  },
};
</script>

<style lang="scss">
.theme--tuktuk__seal {
  width: 100%;
  padding-right: 16px;
  .theme--tuktuk__seal__carousel{
    .theme--tuktuk__seal__carousel__arrow-prev{
      display: none;
    }
    .theme--tuktuk__seal__carousel__arrow-next{
      display: flex;
      top: unset;
      left: unset;
      right: -16px;
      bottom: -2px;
      background-color: #fff;
      height: 16px;
      width: 16px;
      transform: unset;
    }
    .slick-track{
    }
    .slick-list{
      height: 16px !important;
      .slick-slide {
        margin-right: 8px;
      }
    }
  }
  .theme--tuktuk__seal__item {
    .theme--tuktuk__seal__item__container{
      display: flex;
      align-items: center;
      gap: 5px;
      p {
        color: #15171C;
        text-align: left;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; /* 16px */
        letter-spacing: 0.2px;
        margin: 0;
        // white-space: nowrap;
      }
      .theme--tuktuk__seal__item__icon{
        height: 16px;
        width: 16px;
        img{
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
</style>
<style scoped></style>
