<script>
import axios from "axios";
import moment from "moment/src/moment";
import config from "../config.js";
import SuccessPage from "@/components/SuccessPage/index";
import VueScriptComponent from "vue-script-component";
import bankNames from "@/data/bankNames";
import {getContentId} from '../helpers/contentIdHelper'

function replaceAll(str, find, replace) {
  let txt = str.text ? str.text : str;
  return txt.replace(new RegExp(find, "g"), replace);
}

export default {
    head() {
        return {
            title: `Success | Mengr App`,
        };
    },
    components: {
        VueScriptComponent,
        "SuccessPage": SuccessPage,
    },
    layout: 'empty',
    data() {
        return {
            checkOrderMountCount: 0,
            form: null,
            order: null,
            host: '',
            paymentData: [],
            scripts: [],
            total_price: 0,
            product_name: '',
            user:{},
            product_category_full: '',
            bodyColor:'',
            trackConversion: false,
            trackConversionApiCalled: false,
            redirectText: '',
            customerAddress:[],
            getContentId
        }
    },
    async mounted() {
        const storedCounter = localStorage.getItem('checkOrderMountCount');
        if (storedCounter) {
        this.checkOrderMountCount = parseInt(storedCounter);
        }
        this.checkOrderMountCount++;
        this.host = window.location.host
        let data = await axios.get(config.api +'order/one', {params: {id: String(this.$route.query.order), getPayment: true }})
        if (data.data.success) {
            this.form = data.data.form
            this.order = data.data.order
            this.paymentData = data.data.paymentData;
            this.user = data.data.user;
            this.saveOrderLocal()
            this.createScripts();
        }
        this.bodyColor = document.getElementsByTagName("BODY");
        if(this.bodyColor.length) {
            setTimeout(()=>{
                if(self.form) {
                    document.querySelectorAll('meta[name="theme-color"]').forEach((function(x){ x.setAttribute("content", self.form.backgroundColor);}))
                    document.querySelector('meta[name="apple-mobile-web-app-status-bar-style"]').setAttribute("content", self.form.backgroundColor);
                    self.bodyColor[0].style.backgroundColor = self.form.backgroundColor;
                }
            },1000)
        }

        if (this.form.whenComplete && this.form.whenComplete != 'successPage'){
            setTimeout(()=>{
                this.doRedirectAfterOrder()
            }, 1500)
        }
    },
    methods: {
        saveOrderLocal(){

            if (this.checkOrderMountCount === 2) {
            const { customerName, ORDER_ID, product_id, customerPhone } = this.order;
            const dataToSave = {
                customerName,
                ORDER_ID,
                product_id,
                customerPhone
            };
            localStorage.setItem('orderData', JSON.stringify(dataToSave));
            }
            localStorage.setItem('checkOrderMountCount', this.checkOrderMountCount.toString());
        },

        async checkTrackingConversionOnce(order) {
            const compareTime = sessionStorage.getItem("compareTime");
            let orderItem = {}
            if (compareTime && order && !this.trackConversionApiCalled) {
                orderItem = await axios.post(config.api + 'order/trackConversionOnce', {  productId: order.product_id, time: compareTime, product_id : order.product_id, customerName: order.customerName, customerPhone: order.customerPhone, order_id : order._id});
                this.trackConversionApiCalled = true;
                this.trackConversion = orderItem;
            }
            return this.trackConversionApiCalled ? this.trackConversion : orderItem; // Add 'return' statement to return the value of ordersCount
        },
        async doRedirectAfterOrder() {
            // For product variation
            const itemsWithQtyGreaterThanZero = this.order.variations.filter(item => item.qty > 0);
            // Extract names and qty from filtered items
            const variation_name = itemsWithQtyGreaterThanZero.map(item => item.name);
            const variation_qty = itemsWithQtyGreaterThanZero.map(item => item.qty);

            // For address
            if(this.order.RECEIVER_ADDR) {
            this.customerAddress.push(this.order.RECEIVER_ADDR);
            }
            if(this.order.address && this.order.address.customerFULL_AUTOFILL) {
            this.customerAddress.push(this.order.address.customerFULL_AUTOFILL.toLowerCase());
            }
            if(this.order.address && this.order.address.ZIP) {
            this.customerAddress.push(this.order.address.ZIP);
            }

            let redirectText = this.user && this.user.redirectText ? this.user.redirectText:  `Halo, saya sudah melakukan pemesanan {{product_name}}, atas nama {{name}}. Mohon segera diproses ya 🙏🏻`;
            let order = this.order
            redirectText = replaceAll(redirectText, "{{name}}", order.customerName || '');
            // redirectText = replaceAll(redirectText, "{{product_name}}", order.productName || '');
            redirectText = replaceAll(redirectText, "{{order_id}}", "#" + order.ORDER_ID || '');
            redirectText = replaceAll(redirectText, "{{email}}", order.customerEmail || '');
            redirectText = replaceAll(redirectText, "{{phone}}", order.customerPhone || '');

            
            redirectText = replaceAll(redirectText, "{{COD_TOTAL}}", this.order.paymentInfo == 'COD' && this.order.grossRevenue ? this.order.grossRevenue : '');
            redirectText = replaceAll(redirectText, "{{COURIER}}", this.order.shippingInfo && this.order.shippingInfo.deliveryCourier ? this.order.shippingInfo.deliveryCourier : this.order.shipment_courier ? this.order.shipment_courier : '');
            redirectText = replaceAll(redirectText, "{{COD_FEE}}", this.order.paymentInfo == 'COD' && this.order.codPrice ? this.order.codPrice : '');
            redirectText = replaceAll(redirectText, "{{PRODUCT_VARIATION}}", this.order.variations.length ? this.order.productName + variation_name + '('+variation_qty+'x)' : '');
            redirectText = replaceAll(redirectText, "{{PHONE_NUMBER}}", this.order.customerPhone ? this.order.customerPhone : '');
            redirectText = replaceAll(redirectText, "{{ADDRESS}}", this.customerAddress ? this.customerAddress.join(',') : '');
            
            let domain= window.location.protocol + "//" +window.location.host;
            redirectText = replaceAll(redirectText, "{{order_link}}", domain + "/order-details/" + order.ORDER_ID);


            let addressData = ""
            let RECEIVER_ADDR = order.RECEIVER_ADDR ? order.RECEIVER_ADDR+", " : ""
            if (order && order.address) {
            let addressField = order && order.address && order.address.customerFULL_AUTOFILL ? order.address.customerFULL_AUTOFILL + ', ' + order.address.ZIP : ''
            addressData = addressField ? addressField : ''
            }
            redirectText = replaceAll(redirectText, "{{address}}", RECEIVER_ADDR + addressData);

            // redirectText = replaceAll(redirectText, "{{address}}", order && order.address && order.address.customerFULL_AUTOFILL ? (order.address.customerFULL_AUTOFILL || '') : (order.RECEIVER_ADDR || ''));


            let product_name = order.productName
            let variations_str = '';
            if (order.variations && order.variations.length > 0) {
              order.variations
                .filter((a) => !!a.qty)
                .map(
                (f) =>{
                const v_name= f.attrs
                    .map(
                        (a) =>
                        a.name.substring(0, a.name.length - 1) + ": " + a.value
                    )
                    .join(" - ") 
                    
                    variations_str = variations_str+`
                    ${v_name} (${f.qty}x)`
                }
                    
                )
            }

            //const productNameWithVariations = `${product_name || ''}${variations_str !== '' ? ' - ' + variations_str : ''}`;
            redirectText = replaceAll(redirectText, "{{product_name}}", product_name); // MFM-1188 need to show only product name 

            // redirectText = replaceAll(redirectText, "{{product_name}}", order.productName || '');
            redirectText = replaceAll(redirectText, "{{product_price}}", order.productPrice || 0);
            redirectText = replaceAll(redirectText, "{{shipping_cost}}", order.shippingPrice || 0);
            redirectText = replaceAll(redirectText, "{{shipping_cod_cost}}", order.codPrice || 0);
            redirectText = replaceAll(redirectText, "{{receipt_number}}", order.receiptNumber || '');
            redirectText = replaceAll(redirectText, "{{total_price}}", order.grossRevenue || 0);
            redirectText = replaceAll(redirectText, "{{payment_method}}", order && order.paymentInfo && order.paymentInfo == 'bankTransfer' ? 'Bank Transfer' : (order.paymentInfo == 'COD' ? 'COD' : (order && order.paymentMode && order.paymentMode == 'E-Payment' ? "E-Payment" : '')));
            redirectText = replaceAll(redirectText, "{{RECEIVER_NAME}}", order.customerName || '');
            redirectText = replaceAll(redirectText, "{{RECEIVER_ADDR}}", order.RECEIVER_ADDR || '');
            redirectText = replaceAll(redirectText, "{{TRACKING_ID}}", order.cnote_no || '');
            redirectText = replaceAll(redirectText, "{{SELLER_NAME}}", this.user && this.user.name ? this.user.name : '');

            redirectText = replaceAll(redirectText, "{{shipping_courier}}",(order && order.shippingInfo && order.shippingInfo.deliveryCourier ? order.shippingInfo.deliveryCourier : '' ).toUpperCase());

            redirectText = replaceAll(redirectText, "{{UNIQUE_CODE}}",  order && order.uniquePrice ? "Rp" + order.uniquePrice : 'Rp0');
            redirectText = replaceAll(redirectText, "{{PARCEL_CONTENT}}", "");
            redirectText = replaceAll(redirectText, "{{CURRENT_STATUS}}", "");
            redirectText = replaceAll(redirectText, "{{shipping_cost_cod_cost}}", (order.shippingPrice + order.codPrice || 0));          
            let bankText="";
            if (this.form.bankTransferSettings && this.form.bankTransferSettings.length) {             
                this.form.bankTransferSettings.map(bank=>{
                    if (bank.active) {
                        bankText=bankText+ `
                        ${ bankNames.find((bank) => bank.id == bank.id).text ? bankNames.find((bank) => bank.id == bank.id).text : bank.label }
                        No. Rek: ${bank.accountNumber}
                        Atas Nama: ${bank.accountName}
                        `

                    }
                })                           
            }            
            redirectText = replaceAll(redirectText,"{{bank_accounts}}",bankText);

            //bump_Product_Name & bump_Product_Price
            let bump_product = ''
            if (order.bumpArray && order.bumpArray.length > 0) {
                bump_product = order.bumpArray.map(item => `${item.bumpName} : Rp${item.bumpPrice || 0}`).join(' , ');
            }
            redirectText = replaceAll(redirectText, "{{bump_product}}", bump_product || "");

            let custom_fields = ''
            if (order.customFields && order.customFields.length > 0) {
                custom_fields = order.customFields
                    .map((f) =>
                        f[Object.keys(f)[0]]
                            ? Object.keys(f)[0] + ": " + f[Object.keys(f)[0]]
                            : (Object.keys(f)[0] ? Object.keys(f)[0] + ": " + '-' : '')
                    )
                    .join(",\n"); 
            }
            redirectText = replaceAll(redirectText, "{{custom_fields}}", custom_fields || "");

            let variation='';       
            order.variations
            .filter((a) => !!a.qty)
            .map((f) =>{
                const v_name = f.attrs
                  .map(
                    (a) =>
                      a.name.substring(0, a.name.length - 1) + ": " + a.value
                  ) .join(" - ")                   
                  variation = variation+`${v_name} (${f.qty}x)\n`
            })
        

            redirectText = replaceAll(redirectText,"{{product_variation}}",variation);
            redirectText = replaceAll(redirectText, "{{asignee_name}}", order.handledUser ? order.handledUser.name : "");

            this.redirectText = redirectText;
            if (this.form.whenComplete && this.form.whenComplete != 'successPage'){
                let url;
                if(this.form.whenComplete == 'url'){
                    url = this.form.customUrl
                }
                if(this.form.whenComplete == 'random'){
                    var item = this.form.numbersWA[Math.floor(Math.random()*this.form.numbersWA.length)]
                    let number = await this.changeNumber(item.number)
                    url = `https://wa.me/${number}?text=${encodeURIComponent(this.redirectText)}`;
                }
                if(this.form.whenComplete == 'csNumber'){
                    let fphone = this.user.formulirNumber;
                    if(this.order && this.order.handledUser && this.order.handledUser.email != this.user.email){
                        fphone = this.order.handledUser.phone
                    }
                    let number = await this.changeNumber(fphone)
                    url = `https://wa.me/${number}?text=${encodeURIComponent(this.redirectText)}`;
                }              
               window.location.href = url
            }
        },
        async changeNumber(customerPhone){
            let RECEIVER_PHONE = customerPhone.replace("'", "");
            if (RECEIVER_PHONE && RECEIVER_PHONE.charAt(0) == "0") {
                RECEIVER_PHONE =
                "62" + RECEIVER_PHONE.substring(1, RECEIVER_PHONE.length);
            }
            if (RECEIVER_PHONE && RECEIVER_PHONE.charAt(0) != "+") {
                if (
                    !(RECEIVER_PHONE.charAt(0) == "6" &&
                    RECEIVER_PHONE.charAt(1) == "2")
                ) {
                    RECEIVER_PHONE = "62" + RECEIVER_PHONE;
                    RECEIVER_PHONE = "62" + RECEIVER_PHONE;
                }
            }
            return RECEIVER_PHONE;
        },
        async createScripts(){
            this.total_price = this.order.grossRevenue
            this.product_name = this.order.productName
            this.product_category_full = this.form.category + this.form.category2 + this.form.category3;
            if(this.form.successpixelsFB && this.form.successpixelsFB.length > 0 ){
                //eslint-disable-next-line
                let str = "<script>!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};"+"if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];"+"s.parentNode.insertBefore(t,s)}(window, document,'\script','https://connect.facebook.net/en_US/fbevents.js');"

                this.form.successpixelsFB.forEach(async pixel => {
                    // if user select global conversion settings 
                    if (pixel.trackingConversion == 'global') {
                        pixel.trackingConversion = this.form.globalConversionSetting
                    }
                    if (pixel.trackingConversion == 'once') {
                        const result = await this.checkTrackingConversionOnce(this.order);
                        if (result.data && result.data.length > 0) {
                            return
                        }
                        const storedData = localStorage.getItem("orderData");
                        if (storedData) {
                        const storedOrder = JSON.parse(storedData);
                        if (
                            storedOrder.customerName === this.order.customerName &&
                            storedOrder.ORDER_ID === this.order.ORDER_ID &&
                            storedOrder.product_id === this.order.product_id &&
                            storedOrder.customerPhone === this.order.customerPhone
                        ) {
                            return;
                        }
                        }
                    }
                    str += `fbq('init', "${pixel.name.trim()}");`;
                });

                str += `fbq('track', 'PageView');`;
                // removed this condition from below  ---> && this.form.enableSuccessTiktokPixel
                if(this.form.successpixelsEventsFB && this.form.successpixelsEventsFB.length > 0){
                    this.form.successpixelsEventsFB.forEach(events => {
                        var object = events.settings.reduce((obj, item) => Object.assign(obj, { [item.name]: item.name !== "currency" ? this[item.data.code] : item.data.code }), {});
                        str += `fbq('track',"${events.name}",${JSON.stringify(object)});`;
                    });
                }
                //eslint-disable-next-line
                str += "<\/script>";       
                this.scripts.push({script: str, name : "fb", type: "fb"});
            }
            if(this.form.successGTM && this.form.successGTM.length > 0){
                this.form.successGTM.forEach(async gtm => {
                    // if user select global conversion settings 
                    if (gtm.trackingConversion == 'global') {
                    gtm.trackingConversion = this.form.globalConversionSetting
                    }
                    if (gtm.trackingConversion == 'once') {
                        const result = await this.checkTrackingConversionOnce(this.order);
                        if (result.data && result.data.length > 0) {
                            return
                        }
                        const storedData = localStorage.getItem("orderData");
                        if (storedData) {
                        const storedOrder = JSON.parse(storedData);
                        if (
                            storedOrder.customerName === this.order.customerName &&
                            storedOrder.ORDER_ID === this.order.ORDER_ID &&
                            storedOrder.product_id === this.order.product_id &&
                            storedOrder.customerPhone === this.order.customerPhone
                        ) {
                            return;
                        }
                        }
                    }
                    // this.$gtm.init(gtm.name)
                    //eslint-disable-next-line
                    this.scripts.push({script: "<script>(function(w,d,s,l,i){w[l]=w[l]||[];"+"w[l].push({'formLocation': 'head','gtm.start':new Date().getTime(),event:'gtm.js'})"+";var f=d.getElementsByTagName(s)"+"[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?"+"id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','"+gtm.name+"');<\/script>", name : gtm.name, type: "gtm"});
                });
            }
             if(this.form.successGTA && this.form.successGTALabel){
                // if user select global conversion settings 
                if (this.form.successGTAtrackingConversion == 'global') {
                  this.form.successGTAtrackingConversion = this.form.globalConversionSetting
                }
                if (this.form.successGTAtrackingConversion == 'once') {
                        const result = await this.checkTrackingConversionOnce(this.order);
                        if (result.data && result.data.length > 0) {
                            return
                        }
                        const storedData = localStorage.getItem("orderData");
                        if (storedData) {
                        const storedOrder = JSON.parse(storedData);
                        if (
                            storedOrder.customerName === this.order.customerName &&
                            storedOrder.ORDER_ID === this.order.ORDER_ID &&
                            storedOrder.product_id === this.order.product_id &&
                            storedOrder.customerPhone === this.order.customerPhone
                        ) {
                            return;
                        }
                        }
                    }
                //eslint-disable-next-line
                this.scripts.push({script: "<script defer src='https://www.googletagmanager.com/gtag/js?id=AW-"+this.form.successGTA+"'><\/script>", name: "GTA", type: "GTA"});
                //eslint-disable-next-line
                this.scripts.push({script: "<script>window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date()); gtag('config', 'AW-"+this.form.successGTA+"');<\/script>", name: "GTA", type: "GTA"});
                //eslint-disable-next-line
                this.scripts.push({script: "<script>gtag('event', 'conversion', {'send_to': 'AW-"+this.form.successGTA+"/"+this.form.successGTALabel+"', 'transaction_id': '"+this.order._id+"', 'value': '"+this.total_price+"', 'currency': 'USD'});<\/script>", name: "GTA", type: "GTA"});
            }
            if(this.form.pixelsTiktok && this.form.pixelsTiktok.length > 0 && this.form.enableTiktokPixel){
                this.form.pixelsTiktok.forEach(async pixelsTiktok => {
                    if (pixelsTiktok.trackingConversion == 'once') {
                        const result = await this.checkTrackingConversionOnce(this.order);
                        if (result.data && result.data.length > 0) {
                            return
                        }
                        const storedData = localStorage.getItem("orderData");
                        if (storedData) {
                        const storedOrder = JSON.parse(storedData);
                        if (
                            storedOrder.customerName === this.order.customerName &&
                            storedOrder.ORDER_ID === this.order.ORDER_ID &&
                            storedOrder.product_id === this.order.product_id &&
                            storedOrder.customerPhone === this.order.customerPhone
                        ) {
                            return;
                        }
                        }
                    }
                let pixel = pixelsTiktok ? pixelsTiktok.name : ''
                //eslint-disable-next-line
                this.scripts.push({script: "<script>!function (w, d, t) {w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=['page','track','identify','instances','debug','on','off','once','ready','alias','group','enableCookie','disableCookie'],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i='https://analytics.tiktok.com/i18n/pixel/events.js';ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement('script');o.type='text/javascript',o.async=!0,o.src=i+'?sdkid='+e+'&lib='+t;var a=document.getElementsByTagName('script')[0];a.parentNode.insertBefore(o,a)};ttq.load('" + pixel +"');ttq.page();}(window, document, 'ttq');<\/script>", name : pixelsTiktok.name, type: "tiktok"});
                });
            }
            if(this.form.successpixelsTiktok && this.form.successpixelsTiktok.length > 0){
                this.form.successpixelsTiktok.forEach(pixelsTiktok => {
                    let pixel = pixelsTiktok ? pixelsTiktok.name : ''
                    //eslint-disable-next-line
                    let recaptchaScript = document.createElement('script')
                    let str =  "!function (w, d, t) { w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=['page','track','identify','instances','debug','on','off','once','ready','alias','group','enableCookie','disableCookie'],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i='https://analytics.tiktok.com/i18n/pixel/events.js';ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement('script');o.type='text/javascript',o.async=!0,o.src=i+'?sdkid='+e+'&lib='+t;var a=document.getElementsByTagName('script')[0];a.parentNode.insertBefore(o,a)}; ttq.load('" + pixel + "'); ttq.page(); }(window, document, 'ttq');";
                    if (this.form.successpixelsEventsTiktok && this.form.successpixelsEventsTiktok.length > 0 && this.form.enableSuccessTiktokPixel) {
                        this.form.successpixelsEventsTiktok.forEach(events => {
                            if(events.settings){
                                // var object = events.settings.reduce((obj, item) => Object.assign(obj, { [item.name]: ((item.name !== "currency" || item.name !== "content_type") ? this[item.data.code] : item.data.code) }), {});
                                var object = {}; 
                                events.settings.map((item) => {
                                    if(item.name == "currency" || item.name == "content_type"){
                                        object[item.name] = item.name == "currency" ? "IDR" : "product"
                                    }else{
                                        object[item.name] = item.name == "content_id" ? this.getContentId(this.form, this.getSKU(this.order.variations), item.data.code) : this[item.data.code]
                                    }
                                });
                                str += `ttq.track("${events.name}",${JSON.stringify(object)});`;
                            }else{
                                str += `ttq.track("${events.name}");`;
                            }
                        });
                    }
                    recaptchaScript.innerHTML = str;
                    document.head.appendChild(recaptchaScript)
                });
            }
        },
        getSKU(param){
            for(let i =0; i < param.length;i++){
                if(param[i].SKU && param[i].SKU != ""){
                return param[i];
                }
            }
        }, 
    },
    filters: {
        year_format (val) {
            return moment(val).format('YYYY')
        },
        date_format (val) {
            return moment(val).format('DD MMM YYYY hh:mma')
        },
        datetime_format (val) {
            return moment(val).format('DD MMM YYYY, HH:mm')
        },
        history_format (val) {
            return moment(val).local().format('MMMM DD, YYYY ') + ' at ' + moment(val).local().format('hh:mma')
        },
    },
};
</script>
<template>
  <!-- background-color: ${ form.backgroundColor} -->
  <div
    v-if="form && order"
    class="box-height d-flex justify-center"
    :style="`background-color: ${form.backgroundColor};`"
  >
    <span v-bind:key="index" v-for="(pixel, index) of scripts">
      <span v-bind:key="index">
        <VueScriptComponent :script="pixel.script" />
      </span>
    </span>
    <div id="wrapper" class="container-sm success-page">
        <SuccessPage
            :form="true"
            :data="form"
            :order="order"
            :paymentType="order.paymentMode"
            :paymentInfo="order.paymentInfo"
            :paymentData="paymentData"
            :userData="user"
            @continueAction="doRedirectAfterOrder"
        />
    </div>
  </div>
</template>
<style>
.content-page-empty {
  overflow: hidden;
  padding: 0 20px;
  min-height: 80vh;
}
.success-page .card {
  border: none !important;
  box-shadow: none !important;
}
.container-sm {
  max-width: 1140px !important;
}
.box-height {
  min-height: 100vh;
}
@media (max-width: 576px) {
  .content-page-empty {
    overflow: hidden;
    padding: 40px 20px !important;
    min-height: 80vh;
  }
  .box-height {
    min-height: 0;
  }
}
</style>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;900&display=swap');
</style>