<template>
  <div 
    v-if="form && order"
    class="box-height d-flex justify-center"
    :style="`background-color: ${form.backgroundColor};`"
  >
    <span v-bind:key="index" v-for="(pixel, index) of scripts">
      <span v-bind:key="index">
        <VueScriptComponent :script="pixel.script" />
      </span>
    </span>

    <div id="success-content" class="payment-success-page">
      <div
        class="preview-card-scroll"
        :style="
          order.paymentInfo != 'bankTransfer' && !form.successShowTitle
            ? ''
            : ''
        "
      >
        <!-- SHOW TITLE -->
        <template
          v-if="form.successTitle"
        >
          <h1 v-if="form.successShowTitle && form.successTitle" class="success-content__title">
            {{ form.successTitle }}
          </h1>
        </template>

        <div class="preview-main">
          <!-- ILUSTRATIONS -->
          <div class="success-content__ilust">
            <img
              width="102"
              src="@/assets/icons/success-order.svg"
              alt="success icon"
            />
          </div>

          <!-- THANK YOU CONTENT -->
          <h2 class="thank-you">
            {{ successHeadline }}
          </h2>
        
          <!-- DESC PAYMENT -->
          <div
            v-if="
              order &&
              order.paymentInfo !== 'bankTransfer' &&
              order.paymentInfo !== 'COD'
            "
          >
            <div v-if="form && order">
              <div
                class="desc-block"
                v-html="textData"
              ></div>
            </div>
          </div>

          <div v-else-if="order.shippingInfo && order.shippingInfo.deliveryCourier && order.shippingPrice > 0" class="card-body">
            <p class="success-content__text m-0">
              {{ $t('payment.processingOrder') }}
            </p>
          </div>
          
          
          <div
            v-if="
              form &&
              form.successShowChatButton &&
              order.paymentInfo !== 'COD'
            "
            class="card mb-0 preview-card"
          >
            <div>
              <hr class="line" />
              <h2 class="text-center pt-3 epay-text-1">
                {{ $t('payment.deliveryProblems') }}
              </h2>
              <p class="text-center mb-2 mt-2">
                {{ $t('payment.contactSeller') }}
              </p>
            </div>
            <br />
            <div>
              <div @click="sellerContact" class="customerwhatsapp">
                <img src="@/assets/icons/whatsapp-link.svg" />
                <div class="whatsapp-button ml-1">
                  {{ $t('payment.contactViaWhatsapp') }}
                </div>
              </div>
            </div>
          </div>

          
        </div>
      </div>
       <!-- CONTENT COPYRIGHT -->
       <div
        class="copyright-content payment-confirmation__copyright-content"
      >
        <div class="copyright-text__poweredby" v-if="form.isPoweredBy">
          <p class="copyright-text__poweredby__text">Powered by</p>
          <img src="@/assets/icons/formulir-logo.png" />
        </div>
        <p class="copyright-content__text">
          Copyright © {{ new Date() | year_format }}
        </p>
      </div>
    </div>
    

    
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment/src/moment";
import config from "../config.js";
import VueScriptComponent from "vue-script-component";
import {getContentId} from '../helpers/contentIdHelper'; 

export default {
    head() {
        return {
            title: `Payment Confirmation | Mengr App`,
        };
    },
    components: {
        VueScriptComponent,
    },
    layout: 'empty',
    //middleware: ['router-auth', 'acl-user'],
    data() {
        return {
            orderCountPaymentSuccess: 0,
            form: null,
            order: null,
            user:{},
            settings: [],
            scripts: [],
            customerPhone:'',
            trackConversion: false,
            trackConversionApiCalled: false,
            getContentId
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                this.getTitle();
            }
        },
    },
    async mounted() {
        const storedCounter = localStorage.getItem('orderCountPaymentSuccess');
        if (storedCounter) {
        this.orderCountPaymentSuccess = parseInt(storedCounter);
        }
        this.orderCountPaymentSuccess++;
        this.host = window.location.host
        let data = await axios.get(config.api +'order/one', {params: { ORDER_ID: this.$route.query.order, getPayment: true}})
        let temp = this.host.split('.');
        temp.splice(0,1);
        let d = temp.join('')
        axios.post(config.api +'payment/tracking', {domain: d,triggerCondition: 'PaymentInfo', orderId: this.$route.query.order})
        if (data.data.success) {
            this.form = data.data.form
            this.order = data.data.order;
            this.user = data.data.user;
            this.customerPhone = this.order.customerPhone;
            this.host = window.location.host
            this.createScripts();
            this.saveOrderLocal()
        }
        this.getTitle();
        this.bodyColor = document.getElementsByTagName("BODY");
        if(this.bodyColor.length) {
            setTimeout(()=>{
                if(self.form) {
                    document.querySelectorAll('meta[name="theme-color"]').forEach((function(x){ x.setAttribute("content", self.form.backgroundColor);}))
                    document.querySelector('meta[name="apple-mobile-web-app-status-bar-style"]').setAttribute("content", self.form.backgroundColor);
                    self.bodyColor[0].style.backgroundColor = self.form.backgroundColor;
                }
            },1000)
        }
        this.getUserDetails()
    },
    computed: {
        successHeadline() {
            return this.form.successHeadline.replace('{{product_name}}', this.order.productName)
        },
        textData() {
            let text = this.order.paymentInfo == 'COD' ? this.form.successTextCod : (this.order.paymentInfo == "bankTransfer" ? this.form.successTextBank : this.form.successTextEpayment);
            text = text.replace('{{line}}', ' <hr class="mt-2 mb-2">')
             text = text.replace(
                "{{success-text-bank}}",
                `<div class="success-text-bank">Untuk menyelesaikan proses order, silahkan transfer sejumlah </div>`
            );
            text = text.replace('{{total_price}}', `<div class="rp-white">Rp ${new Intl.NumberFormat().format(this.order.grossRevenue)}</div>`)
            text = text.replace('{{epay-text}}', `<div class="epay-text order-txt mb-2"> Orderan anda kini kami proses, silahkan tunggu kedatangan
     kurir dalam 2-4 hari kedepan.</div>`)
            return text  
     },
    },
    methods: {
        saveOrderLocal(){
            if (this.orderCountPaymentSuccess === 2) {
            const { customerName, ORDER_ID, product_id, customerPhone } = this.order;
            const dataToSave = {
                customerName,
                ORDER_ID,
                product_id,
                customerPhone
            };
            localStorage.setItem('orderDataPaymentSuccess', JSON.stringify(dataToSave));
            }
            localStorage.setItem('orderCountPaymentSuccess', this.orderCountPaymentSuccess.toString());
        },
        async checkTrackingConversionOnce(order) {
            const compareTime = sessionStorage.getItem("compareTime");
            let orderItem = {}
            if (compareTime && order) {
            orderItem = await axios.post(config.api + 'order/trackConversionOnce', {  productId: order.product_id, time: compareTime, product_id : order.product_id, customerName: order.customerName, customerPhone: order.customerPhone, order_id : order._id});
            this.trackConversionApiCalled = true;
            this.trackConversion = orderItem;
            }
            return this.trackConversionApiCalled ? this.trackConversion : orderItem; // Add 'return' statement to return the value of ordersCount
        },
        getTitle() {
            setTimeout(()=>{
                document.title = (this.form ? this.form.name : "embed-form")+" - Formulir";
            },500)
        },
        async sellerContact(){
          if (this.order && this.user) {
            let fphone = this.user.formulirNumber;
            if(this.order && this.order.handledUser && this.order.handledUser.email != this.user.email){
              fphone = this.order.handledUser.phone
            }
            let number = await this.changeNumber(fphone)
            let url = `https://wa.me/${number}`;
            window.open(url, "_blank");
          }
        },
        async getUserDetails(){
            try{
                let userId = this.host.split('.')[0]
                let paymentData = await axios.get(config.api +'user/getPaymentData', {params: {user_id: userId}})
                this.settings = paymentData.data.settings ? paymentData.data.settings[0] : []
            }catch(error){
                console.log(error, "error------------");
            }
        },
         async changeNumber(customerPhone){
            let RECEIVER_PHONE = customerPhone.replace("'", "");
            if (RECEIVER_PHONE && RECEIVER_PHONE.charAt(0) == "0") {
                RECEIVER_PHONE =
                "62" + RECEIVER_PHONE.substring(1, RECEIVER_PHONE.length);
            }
            if (RECEIVER_PHONE && RECEIVER_PHONE.charAt(0) != "+") {
                if (
                    !(RECEIVER_PHONE.charAt(0) == "6" &&
                    RECEIVER_PHONE.charAt(1) == "2")
                ) {
                    RECEIVER_PHONE = "62" + RECEIVER_PHONE;
                    RECEIVER_PHONE = "62" + RECEIVER_PHONE;
                }
            }
            return RECEIVER_PHONE;
        },
        createScripts(){
            if(this.order){
                this.total_price = this.order.grossRevenue
                this.product_name = this.order.productName
            }
            if(this.form){
                this.product_category_full = this.form.category + this.form.category2 + this.form.category3;
                if(this.form.successpixelsFB && this.form.successpixelsFB.length > 0 ){
                  //eslint-disable-next-line
                  let str = "<script>!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};"+"if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];"+"s.parentNode.insertBefore(t,s)}(window, document,'\script','https://connect.facebook.net/en_US/fbevents.js');"

                  this.form.successpixelsFB.forEach(async pixel => {
                    // if user select global conversion settings 
                    if (pixel.trackingConversion == 'global') {
                      pixel.trackingConversion = this.form.globalConversionSetting
                    }
                    if (pixel.trackingConversion == 'once') {
                      const result = await this.checkTrackingConversionOnce(this.order);
                      if (result.data && result.data.length > 0) {
                          return
                      }
                      const storedData = localStorage.getItem("orderDataPaymentSuccess");
                      if (storedData) {
                        const storedOrder = JSON.parse(storedData);
                        if (
                            storedOrder.customerName === this.order.customerName &&
                            storedOrder.ORDER_ID === this.order.ORDER_ID &&
                            storedOrder.product_id === this.order.product_id &&
                            storedOrder.customerPhone === this.order.customerPhone
                        ) {
                            return;
                        }
                      }
                    } 
                    str += `fbq('init', "${pixel.name.trim()}");`;
                  });

                  str += `fbq('track', 'PageView');`;
                  if(this.form.successpixelsEventsFB && this.form.successpixelsEventsFB.length > 0){
                      this.form.successpixelsEventsFB.forEach(events => {
                        var object = events.settings.reduce((obj, item) => Object.assign(obj, { [item.name]: item.name !== "currency" ? this[item.data.code] : item.data.code }), {});
                        str += `fbq('track',"${events.name}",${JSON.stringify(object)});`;
                      });
                  }

                  //eslint-disable-next-line
                  str += "<\/script>";       
                  this.scripts.push({script: str, name : "fb", type: "fb"});
                }
                if(this.form.successGTM && this.form.successGTM.length > 0){
                    this.form.successGTM.forEach(async gtm => {
                        // if user select global conversion settings 
                        if (gtm.trackingConversion == 'global') {
                            gtm.trackingConversion = this.form.globalConversionSetting
                            }
                         if (gtm.trackingConversion == 'once') {
                            const result = await this.checkTrackingConversionOnce(this.order);
                            if (result.data && result.data.length > 0) {
                                return
                            }
                            const storedData = localStorage.getItem("orderDataPaymentSuccess");
                        if (storedData) {
                        const storedOrder = JSON.parse(storedData);
                        if (
                            storedOrder.customerName === this.order.customerName &&
                            storedOrder.ORDER_ID === this.order.ORDER_ID &&
                            storedOrder.product_id === this.order.product_id &&
                            storedOrder.customerPhone === this.order.customerPhone
                        ) {
                            return;
                        }
                        }
                        }
                        //eslint-disable-next-line
                        this.scripts.push({script: "<script>(function(w,d,s,l,i){w[l]=w[l]||[];"+"w[l].push({'formLocation': 'head','gtm.start':new Date().getTime(),event:'gtm.js'})"+";var f=d.getElementsByTagName(s)"+"[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?"+"id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','"+gtm.name+"');<\/script>", name : gtm.name, type: "gtm"});
                    });
                }
                if(this.form.successpixelsTiktok && this.form.successpixelsTiktok.length > 0){
                    this.form.successpixelsTiktok.forEach(async pixelsTiktok => {
                         // if user select global conversion settings 
                        if (pixelsTiktok.trackingConversion == 'global') {
                        pixelsTiktok.trackingConversion = this.form.globalConversionSetting
                        }
                        if (pixelsTiktok.trackingConversion == 'once') {
                            const result = await this.checkTrackingConversionOnce(this.order);
                            if (result.data && result.data.length > 0) {
                                return
                            }
                            const storedData = localStorage.getItem("orderDataPaymentSuccess");
                        if (storedData) {
                        const storedOrder = JSON.parse(storedData);
                        if (
                            storedOrder.customerName === this.order.customerName &&
                            storedOrder.ORDER_ID === this.order.ORDER_ID &&
                            storedOrder.product_id === this.order.product_id &&
                            storedOrder.customerPhone === this.order.customerPhone
                        ) {
                            return;
                        }
                        }
                        }
                        let pixel = pixelsTiktok ? pixelsTiktok.name : ''
                        //eslint-disable-next-line
                        let recaptchaScript = document.createElement('script')
                        let str =  "!function (w, d, t) { w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=['page','track','identify','instances','debug','on','off','once','ready','alias','group','enableCookie','disableCookie'],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i='https://analytics.tiktok.com/i18n/pixel/events.js';ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement('script');o.type='text/javascript',o.async=!0,o.src=i+'?sdkid='+e+'&lib='+t;var a=document.getElementsByTagName('script')[0];a.parentNode.insertBefore(o,a)}; ttq.load('" + pixel + "'); ttq.page(); }(window, document, 'ttq');";
                        if (this.data.successpixelsEventsTiktok && this.data.successpixelsEventsTiktok.length > 0 && this.data.enableSuccessTiktokPixel) {
                          this.data.successpixelsEventsTiktok.forEach(events => {
                              if(events.settings){
                                var object = {}; 
                                events.settings.map((item) => {
                                    if(item.name == "currency" || item.name == "content_type"){
                                        object[item.name] = item.name == "currency" ? "IDR" : "product"
                                    }else{
                                        object[item.name] = item.name == "content_id" ? this.getContentId(this.form, this.getSKU(this.order.variations), item.data.code) : this[item.data.code]
                                    }
                                });
                                str += `ttq.track("${events.name}",${JSON.stringify(object)});`;
                              }else{
                                str += `ttq.track("${events.name}");`;
                              }
                          });
                        }
                        recaptchaScript.innerHTML = str;
                        document.head.appendChild(recaptchaScript)
                    });
                }
            }
        },
        getSKU(param){
          for(let i =0; i < param.length;i++){
            if(param[i].SKU && param[i].SKU != ""){
              return param[i];
            }
          }
        },
    },
    filters: {
        year_format (val) {
            return moment(val).format('YYYY')
        },
        date_format (val) {
            return moment(val).format('DD MMM YYYY hh:mma')
        },
        datetime_format (val) {
            return moment(val).format('DD MMM YYYY, HH:mm')
        },
        history_format (val) {
            return moment(val).local().format('MMMM DD, YYYY ') + ' at ' + moment(val).local().format('hh:mma')
        },
    },
};
</script>

<style>
.confirmation-message {
  font-size: 13px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #62718f;
}

.title-text-epay {
  margin: 15px auto 20px;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.02em;
  color: #203551;
}
.embed-checkout-header-text-margin {
  /* margin-top: 50px;  */
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #203551;
  margin-bottom: 8px;
}
.embed-checkout-header-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #203551;
  margin-bottom: 8px;
}

.order-txt {
  margin-top: -0.3rem !important;
}
.box-height {
  min-height: 100vh;
}
.whatsapp-button {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #ffffff;
}
@media (max-width: 576px) {
  .preview-main {
    margin: 10px;
  }
  .box-height {
    min-height: 0px;
  }
  
  .embed-checkout-header-text {
    margin-top: 0px;
  }
}
.epay-text-1 {
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 20px !important;
  text-align: center !important;
  letter-spacing: 0.02em;
  color: #203551 !important;
}
.epay-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #62718f;
  text-align: center !important;
  width: auto;
  max-width: 431px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.customerwhatsapp {
  cursor: pointer;
  margin-top: -1rem !important;
  max-width: 590px;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto 20px;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  background: #04a784;
  border-radius: 4px;
}
.line {
  height: 0px;
  width: 60px;
  left: 320px;
  top: 347px;
  border-radius: 0px;
}
.content-page-empty {
  overflow: hidden;
  padding: 20px 90px 65px 90px;
  min-height: 80vh;
}

.container-sm {
  max-width: 1140px !important;
}
.mx-input {
  background-color: white !important;
    border: 1px solid #bfcee0 !important;
    border-radius: 8px !important;
    font-size: 13px !important;
    line-height: 25px !important;
    height: 38px !important;
}
</style>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;900&display=swap');
</style>