<template>
  <div class="switch-green-selected" :class="className">
    <div v-bind:key="i" v-for="(option, i) in options">    
      <vs-tooltip v-if="truncatedText(option.text, false)">
        <div
          class="b-simple"
          :class="{
            active: value == option.id,
            'disabled-option':option.disabled           
          }"
         @click="onSwitch(option.id)"
        >
          <div class="b-simple__content">
            <div v-if="option.imgSrc" class="b-simple__content__image">
              <img
                :src="option.imgSrc"
                class="courier-img icon"
                width="24px"
                height="24px"
                alt=""
                style="margin-right: 10px"
              />
            </div>
            <p class="b-simple__content__title">{{ truncatedText(option.text) }}</p>
          </div>
          <img
            v-if="value == option.id"
            class="b-simple__mark"
            src="@/assets/icons/blue-tick.svg"
            id="voicebutton"
          />
        </div>
        <template #tooltip>
          {{ option.text }}
        </template>
      </vs-tooltip>

      <div
        class="b-simple"
        :class="{
          active: value == option.id,
          'disabled-option':option.disabled         
        }"
        @click="onSwitch(option.id)"
      >
        <div class="b-simple__content">
          <div v-if="option.imgSrc" class="b-simple__content__image">
            <img
              :src="option.imgSrc"
              class="courier-img icon"
              width="24px"
              height="24px"
              alt=""
              style="margin-right: 10px"
            />
          </div>
          <p class="b-simple__content__title">{{ option.text }}</p>
        </div>
        <img
          v-if="value == option.id"
          class="b-simple__mark"
          src="@/assets/icons/blue-tick.svg"
          id="voicebutton"
        />
      </div>
      
    </div>
  </div>
</template>

<script>
import config from "./../config.js";
export default {
  props: ["options", "value", "full", "className"],
  data() {
    return {
      baseUrl: config.api.replace("/api/", ""),
    };
  },
  methods: {
    truncatedText(text, getText = true) {
      const maxLength = 100;
      if (text.length > maxLength) {
        return getText ? text.slice(0, maxLength) + "..." : true;
      } else {
        return getText ? text.slice(0, maxLength) + "..." : false;
      }
    },
    onSwitch(val) {
      if (this.full == 100) {
        this.$emit("input", this.value ? !val : val);
      } else {
        this.$emit("input", val);
      }
    },
  },
};
</script>

<style lang="scss">
.hover-variant {
  /*display: none;*/
  position: absolute;
  bottom: 50px;
  background-color: #203551;
  padding: 5px 10px;
  color: #fff;
  font-size: 10px;
  font-weight: 400;
  border-radius: 10px;
  z-index: -1;
  opacity: 0;
  transition: 0.3s ease;
  max-width: 100%;
  height: auto;
  white-space: break-spaces;
}
.switch-green-selected {
  position: relative;
  &:hover .hover-variant {
    display: block;
    bottom: 60px;
    opacity: 1;
    z-index: 99;
  }
  
  .disabled-option{
    background-color:#F6F8FC;
    color:#B1BBC9;
    opacity: 1;
    .b-simple__content__title{
      color: #B1BBC9 !important;
    }
  }
  .b-simple {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    box-sizing: border-box;
    cursor: pointer;
    text-align: center;
    position: relative;

    min-width: 67px;
    height: auto;
    min-height: 44px;
    padding: 12px 15px;



    border: 1px solid transparent !important;
    border-radius: 8px;

    &:before{
      content: "";
        position: absolute;
        inset: 0px;
        border: 1px solid #BFCEE0;
        border-radius: 8px;
    }

    &.active {
      box-shadow: none;
      border: 1px solid transparent !important;
      &:before{
        content: "";
        position: absolute;
        inset: 0px;
        border: 2px solid #2e47ba;
        border-radius: 8px;
      }
      .b-simple__content {
        .b-simple__content__title {
          color: #2e47ba;
        }
      }

      .b-simple__mark {
        position: absolute;
        top: -7px;
        right: -7px;
      }
    }

    .b-simple__content {
      .b-simple__content__title {
        color: #6c7e95;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.28px;
        margin: 0;
        max-width: 100%;
        width: 100%;
        text-align: left;
        overflow-wrap: anywhere;
      }
    }
  }
}
.tuktukTheme .b-simple.active:before{   
    border: 1px solid #EB435B;
  
}
.tuktukTheme .b-simple.active .b-simple__content__title{   
    
}
.tuktukTheme .b-simple__mark{
  display: none;
}

.tuktukTheme.switch-green-selected {
  position: relative;
  &:hover .hover-variant {
    display: block;
    bottom: 60px;
    opacity: 1;
    z-index: 99;
  }
  
  .disabled-option{
    background-color:transparent;
    color: #15171C !important;
    opacity: 0.4;
    .b-simple__content__title{
      color: #15171C !important; 
    }
  }
  .b-simple {
    min-width: unset;
    height: auto;
    min-height: 40px;
    min-width: 60px;
    padding: 4px 8px;

    border: 1px solid transparent !important;
    border-radius: 4px;

    &:before{
      content: "";
        position: absolute;
        inset: 0px;
        border: 1px solid rgba(21, 23, 28, 0.10);
        border-radius: 4px;
    }

    &.active {
      box-shadow: none;
      border: 1px solid transparent !important;
      &:before{
        content: "";
        position: absolute;
        inset: 0px;
        border: 2px solid #EB435B;
        border-radius: 4px;
      }
      .b-simple__content {
        .b-simple__content__title {
          color: #EB435B;
        }
      }

      .b-simple__mark {
        display: none;
      }
    }

    .b-simple__content {
      .b-simple__content__title {
        color: #15171C;
        text-align: center;
        font-family: TikTokDisplayRegular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 19.2px */
        letter-spacing: 0.24px;
      }
    }
  }
}
</style>
