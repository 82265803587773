<template>
  <div
    v-if="
      data.paymentType &&
      data.paymentType.find((p) => p.active) &&
      (data.paymentType.filter((p) => p.active).length > 1 ||
        !data.showPaymentHideView)
    "
    id="theme--tuktuk__payment-method"
    class="theme--tuktuk__card"
  >
    <div class="theme--tuktuk__container">
      <h2 class="theme--tuktuk__title">{{ data.titlePayment }}</h2>
      <div class="theme--tuktuk__payment-method__list">
        <template
          v-for="(pType, ind) in data.paymentType.filter((p) => p.active)"
        >
          <div
            v-bind:key="ind"
            v-if="pType.type != 'E-Payment'"
            class="tuktuk__payment-method__list__option"
            @click="changeMode(pType.name)"
          >
            <vs-radio :val="pType.type" v-model="data.payload.payment" class="theme--tuktuk__radio">
              <img
                width="42px"
                height="30px"
                alt=""
                class="payment-image"
                :src="
                  pType.type == 'COD'
                    ? require('@/assets/icons/Payments/payment-cod.png')
                    : require('@/assets/icons/Payments/payment-bank_transfer.png')
                "
              />{{ pType.name }}
            </vs-radio>
          </div>

          <template v-else v-for="(elem, i) in data.epaymentSettings" >
            <div
              v-if="elem.active"
              :key="ind + i"
              @click="changeMode('E-Payment')"
              class="tuktuk__payment-method__list__option"
            >
              <vs-radio
                v-bind:key="i"
                :val="elem.id"
                v-model="data.payload.payment"
                class="theme--tuktuk__radio"
              >
                <img
                  width="42px"
                  height="30px"
                  alt=""
                  class="payment-image"
                  :src="
                    pType.id == 'COD'
                      ? require('@/assets/icons/Payments/payment-bank_transfer.png')
                      : require('@/assets/icons/Payments/' + elem.id + '.png'
                          ? '@/assets/icons/Payments/' + elem.id + '.png'
                          : '@/assets/icons/Payments/' + elem.id + '.svg')
                  "
                />{{ elem.label }}
              </vs-radio>
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ["data"],
  data() {
    return {};
  },
  components: {},
  methods: {
    setStep(count) {     
      this.$emit("setStep", count);
    },
    changeMode(param) {
      this.data.payload.paymentMode = param;
    },
  },
};
</script>

<style scoped></style>
