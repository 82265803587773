module.exports = [
  //1.a. PICK UP PROCESS
  {
    code: 'RC1',
    type: 'onlySendSHIPMENT_RECEIVED',
    group: 'PICK UP PROCESS',
    displayed: 'SHIPMENT RECEIVED BY JNE COUNTER OFFICER AT',
  },
  {
    code: 'PU1',
    type: 'onlySendSHIPMENT_PICKED',
    group: 'PICK UP PROCESS',
    displayed: 'SHIPMENT PICKED UP AT COUNTER BY JNE COURIER',
  },
  //1.b. OUTBOUND PROCESS
  {
    code: 'OP1',
    type: 'noNotificationRECEIVED_AT_SORTING_CENTER',
    group: 'OUTBOUND PROCESS',
    displayed: 'RECEIVED AT SORTING CENTER',
  },
  {
    code: 'OP2',
    type: 'noNotificationPROCESSED_AT_SORTING_CENTER',
    group: 'OUTBOUND PROCESS',
    displayed: 'PROCESSED AT SORTING CENTER',
  },
  //1.c. GATEWAY PROCESS
  {
    code: 'TP1',
    type: 'noNotificationRECEIVED_AT_ORIGIN_GATEWAY',
    group: 'GATEWAY PROCESS',
    displayed: 'RECEIVED AT ORIGIN GATEWAY',
  },
  {
    code: 'TP2',
    type: 'noNotificationDEPARTED_FROM_ORIGIN_GATEWAY',
    group: 'GATEWAY PROCESS',
    displayed: 'DEPARTED FROM ORIGIN GATEWAY',
  },
  {
    code: 'TP3',
    type: 'noNotificationRECEIVED_AT_TRANSIT',
    group: 'GATEWAY PROCESS',
    displayed: 'RECEIVED AT TRANSIT',
  },
  {
    code: 'TP4',
    type: 'noNotificationDEPARTED_FROM_TRANSIT',
    group: 'GATEWAY PROCESS',
    displayed: 'DEPARTED FROM TRANSIT',
  },
  {
    code: 'TP5',
    type: 'noUpdReceivedAtDest',
    tag: 'attention',
    description: 'contactToConfirm',
    group: 'GATEWAY PROCESS',
    displayed: 'RECEIVED AT DESTINATION GATEWAY',
  },
  //2.a. INBOUND PROCESS
  {
    code: 'IP1',
    type: 'noUpdReceivedAtWar',
    tag: 'attention',
    description: 'contactToConfirm',
    group: 'INBOUND PROCESS',
    displayed: 'RECEIVED AT WAREHOUSE',
  },
  {
    code: 'IP2',
    type: 'noUpdReceivedAtSta',
    tag: 'attention',
    description: 'contactToConfirm',
    group: 'INBOUND PROCESS',
    displayed: 'RECEIVED AT INBOUND STATION',
  },
  {
    code: 'IP3',
    type: 'noUpdwithDeliveryCourier',
    tag: 'attention',
    description: 'contactToConfirm',
    group: 'INBOUND PROCESS',
    displayed: 'WITH DELIVERY COURIER',
  },
  {
    code: 'OP3',
    type: 'noUpdwithDeliveryCourier',
    tag: 'attention',
    description: 'contactToConfirm',
    group: 'INBOUND PROCESS',
    displayed: 'SHIPMENT FORWARDED FROM TRANSIT CITY TO DESTINATION CITY',
  },
  {
    code: 'BI1',
    type: 'noUpdReceivedAtWar',
    tag: 'attention',
    description: 'contactToConfirm',
    group: 'INBOUND PROCESS',
    displayed: 'RECEIVED AT WAREHOUSE',
  },
  {
    code: 'BI2',
    type: 'noUpdReceivedAtSta',
    tag: 'attention',
    description: 'contactToConfirm',
    group: 'INBOUND PROCESS',
    displayed: 'RECEIVED AT INBOUND STATION',
  },
  {
    code: 'BI3',
    type: 'onlySendUpdwithDeliveryCourier',
    tag: 'attention',
    description: 'contactToConfirm',
    group: 'INBOUND PROCESS',
    displayed: 'WITH DELIVERY COURIER',
  },
  {
    code: 'OP4',
    type: 'noUpdShipmentForwarded',
    tag: 'attention',
    description: 'contactToConfirm',
    group: 'INBOUND PROCESS',
    displayed: 'SHIPMENT FORWARDED FROM TRANSIT CITY TO DESTINATION CITY',
  },
  //2.b. DELIVERED
  {
    code: 'D01',
    type: 'onlySendSHIPMENT_RECEIVED_BY_CONSIGNEE',
    group: 'DELIVERED',
    displayed: 'SHIPMENT RECEIVED BY CONSIGNEE',
  },
  {
    code: 'D02',
    type: 'onlySendSHIPMENT_RECEIVED_BY_RECEPTIONIST',
    group: 'DELIVERED',
    displayed: 'SHIPMENT RECEIVED BY RECEPTIONIST',
  },
  {
    code: 'D03',
    type: 'onlySendSHIPMENT_RECEIVED_BY_SECRETARY',
    group: 'DELIVERED',
    displayed: 'SHIPMENT RECEIVED BY SECRETARY',
  },
  {
    code: 'D04',
    type: 'onlySendSHIPMENT_RECEIVED_BY_SECURITY_OFFICER',
    group: 'DELIVERED',
    displayed: 'SHIPMENT RECEIVED BY SECURITY OFFICER',
  },
  {
    code: 'D05',
    type: 'onlySendSHIPMENT_RECEIVED_BY_MAILING_ROOM_OFFICER',
    group: 'DELIVERED',
    displayed: 'SHIPMENT RECEIVED BY MAILING ROOM OFFICER',
  },
  {
    code: 'D06',
    type: 'onlySendSHIPMENT_RECEIVED_BY_IMMEDIATE_FAMILY',
    group: 'DELIVERED',
    displayed: 'SHIPMENT RECEIVED BY IMMEDIATE FAMILY',
  },
  {
    code: 'D07',
    type: 'onlySendSHIPMENT_RECEIVED_BY_HOUSE_KEEPER/DRIVER',
    group: 'DELIVERED',
    displayed: 'SHIPMENT RECEIVED BY HOUSE KEEPER/DRIVER',
  },
  {
    code: 'D08',
    type: 'onlySendSHIPMENT_RECEIVED_BY_BOARDING_HOUSE_KEEPER_/_OWNER',
    group: 'DELIVERED',
    displayed: 'SHIPMENT RECEIVED BY BOARDING HOUSE KEEPER / OWNER',
  },
  {
    code: 'D09',
    type: 'onlySendSHIPMENT_RECEIVED_BY_FAMILY_AT_THE_SAME_ADDRESS',
    group: 'DELIVERED',
    displayed: 'SHIPMENT RECEIVED BY FAMILY AT THE SAME ADDRESS',
  },
  {
    code: 'D10',
    type: 'onlySendSHIPMENT_RECEIVED_BY_OFFICE_COLLEAGUE',
    group: 'DELIVERED',
    displayed: 'SHIPMENT RECEIVED BY OFFICE COLLEAGUE',
  },
  {
    code: 'D12',
    type: 'onlySendSHIPMENT_RECEIVED_BY_ERRAND_BOY',
    group: 'DELIVERED',
    displayed: 'SHIPMENT RECEIVED BY ERRAND BOY',
  },
  {
    code: 'DB1',
    type: 'onlySendSHIPMENT_ALREADY_PUT_IN_LOCKER',
    group: 'DELIVERED',
    displayed: 'SHIPMENT ALREADY PUT IN LOCKER',
  },
  {
    code: 'DB2',
    type: 'onlySendSHIPMENT_ALREADY_TAKEN_FROM_LOCKER_BY_CONSIGNEE',
    group: 'DELIVERED',
    displayed: 'SHIPMENT ALREADY TAKEN FROM LOCKER BY CONSIGNEE',
  },
  //3.a. HOLD PROCESS
  {
    code: 'HL1',
    type: 'onHoldDataAndPhysicaldoesntMatch',
    tag: 'actionRequired',
    description: 'contactToConfirm',
    group: 'HOLD PROCESS',
    displayed: 'SHIPMENT BEING HELD AT ORIGIN CITY WAREHOUSE',
  },
  {
    code: 'HL2',
    type: 'onHoldNotPassXRay',
    tag: 'actionRequired',
    description: 'contactToConfirm',
    group: 'HOLD PROCESS',
    displayed: 'SHIPMENT BEING HELD DUE TO DID NOT PASS X-RAY CHECK',
  },
  {
    code: 'HL3',
    type: 'onHoldWaitForNext',
    tag: 'attention',
    description: 'sortedOutSoon',
    group: 'HOLD PROCESS',
    displayed: 'SHIPMENT BEING HELD. WAIT FOR NEXT DEPARTURE SCHEDULE TO DESTINATION',
  },
  {
    code: 'HL4',
    type: 'onHoldWaitForCodConfirm',
    tag: 'attention',
    description: 'sortedOutSoon',
    group: 'HOLD PROCESS',
    displayed: 'SHIPMENT BEING HELD TO WAIT FOR CONFIRMATION OF COD PAYMENT FROM CONSIGNEE',
  },
  //3.b. TRANSPORT IRREGULARITY
  {
    code: 'R01',
    type: 'noNotificationSHIPMENT_ALREADY_UPLOADED',
    group: 'TRANSPORT IRREGULARITY',
    displayed: 'SHIPMENT ALREADY UPLOADED, BUT UNLOADED DUE TO CERTAIN REASON',
  },
  {
    code: 'R02',
    type: 'onHoldOverloadCapacity',
    tag: 'attention',
    description: 'sortedOutSoon',
    group: 'TRANSPORT IRREGULARITY',
    displayed: 'SHIPMENT CAN NOT BE LOADED DUE TO CARRIER ALREADY OVER CAPACITY',
  },
  {
    code: 'R03',
    type: 'onHoldStrandedInTransit',
    tag: 'attention',
    description: 'sortedOutSoon',
    group: 'TRANSPORT IRREGULARITY',
    displayed: 'SHIPMENT BEING HELD AT TRANSIT CITY',
  },
  {
    code: 'R04',
    type: 'onHoldTemporarySuspended',
    tag: 'attention',
    description: 'sortedOutSoon',
    group: 'TRANSPORT IRREGULARITY',
    displayed: 'SHIPPING ACTIVITY SUSPENDED TEMPORARY FOR CERTAIN',
  },
  {
    code: 'R05',
    type: 'onHoldCarriedOverToAirport',
    tag: 'attention',
    description: 'sortedOutSoon',
    group: 'TRANSPORT IRREGULARITY',
    displayed: 'SHIPMENT CARRIED OVER TO OTHER AIPORT DUE TO TECHNICAL PROBLEM',
  },
  {
    code: 'R06',
    type: 'onHoldShipmentLost',
    tag: 'actionRequired',
    description: 'contactCustomer',
    group: 'TRANSPORT IRREGULARITY',
    displayed: 'SHIPMENT LOST CAUSED BY CARRIER',
  },
  {
    code: 'R07',
    type: 'onHoldShipmentDamaged',
    tag: 'actionRequired',
    description: 'contactCustomer',
    group: 'TRANSPORT IRREGULARITY',
    displayed: 'SHIPMENT DAMAGE CAUSED BY CARRIER',
  },
  {
    code: 'R08',
    type: 'onHoldCarrierWarehouseClosed',
    tag: 'attention',
    description: 'sortedOutSoon',
    group: 'TRANSPORT IRREGULARITY',
    displayed: 'CARRIER WAREHOUSE CLOSED DUE TO CERTAIN REASON',
  },
  {
    code: 'R09',
    type: 'onHoldFlightCanceled',
    tag: 'attention',
    description: 'sortedOutSoon',
    group: 'TRANSPORT IRREGULARITY',
    displayed: 'CARRIER DEPARTURE SCHEDULE BEING CANCELLED',
  },
  {
    code: 'R10',
    type: 'onHoldWrongDestination',
    tag: 'attention',
    description: 'sortedOutSoon',
    group: 'TRANSPORT IRREGULARITY',
    displayed: 'SHIPMENTS SHIPPED TO WRONG DESTINATION',
  },
  {
    code: 'R11',
    type: 'delayedAirplanIssue',
    tag: 'attention',
    description: 'sortedOutSoon',
    group: 'TRANSPORT IRREGULARITY',
    displayed: 'AIRPLANE RETURN TO BASE DUE TO CERTAIN REASON (SAFETY, BAD WEATHER, ETC)',
  },
  {
    code: 'R12',
    type: 'delayedAirplanDiverted',
    tag: 'attention',
    description: 'sortedOutSoon',
    group: 'TRANSPORT IRREGULARITY',
    displayed: 'AIRPLANE DIVERTED NOT TO DESTINATION AIRPORT, DUE TO CERTAIN REASON (SAFETY, BAD WEATHER, ETC)',
  },
  {
    code: 'R13',
    type: 'delayedFlightDelay',
    tag: 'attention',
    description: 'sortedOutSoon',
    group: 'TRANSPORT IRREGULARITY',
    displayed: 'DELAY OF FLIGHT SCHEDULE',
  },
  {
    code: 'R14',
    type: 'delayedChangeFlightSchedule',
    tag: 'attention',
    description: 'sortedOutSoon',
    group: 'TRANSPORT IRREGULARITY',
    displayed: 'CHANGE OF FLIGHT SCHEDULE',
  },
  // 3.c. CUSTOMER (SHIPPER) REQUEST
  {
    code: 'CR1',
    type: 'returnedToSenderByRequest',
    tag: 'RTS',
    description: 'contactCustomer',
    group: 'CUSTOMER (SHIPPER) REQUEST',
    displayed: 'SHIPMENT RETURN TO SHIPPER BY REQUEST',
  },
  {
    code: 'CR2',
    type: 'noNotificationSHIPMENT_CANCELLED_BY_SHIPPER',
    group: 'CUSTOMER (SHIPPER) REQUEST',
    displayed: 'SHIPMENT CANCELLED BY SHIPPER',
  },
  {
    code: 'CR3',
    type: 'noNotificationSHIPMENT_COLLECTED_BY_CONSIGNEE',
    group: 'CUSTOMER (SHIPPER) REQUEST',
    displayed: 'SHIPMENT COLLECTED BY CONSIGNEE',
  },
  {
    code: 'CR4',
    type: 'shipmentDestroyedAsCompanyRegulation',
    tag: 'actionRequired',
    description: 'contactCustomer',
    group: 'CUSTOMER (SHIPPER) REQUEST',
    displayed: 'SHIPMENT DESTROYED AS COMPANY REGULATION',
  },
  
  {
    code: 'CR5',
    type: 'onHoldByShipperRequest',
    tag: 'actionRequired',
    description: 'willResolved',
    group: 'CUSTOMER (SHIPPER) REQUEST',
    displayed: 'SHIPMENT HELD BY SHIPPER REQUEST',
  },{
    code: 'CR6',
    type: 'onHoldByShipperRequest',
    tag: 'actionRequired',
    description: 'willResolved',
    group: 'CUSTOMER (SHIPPER) REQUEST',
    displayed: 'NEW ADDRESS',
  },
  //3.d. UNDELIVERED
  {
    code: 'U01',
    type: 'incompleteAddress',
    tag: 'actionRequired',
    description: 'contactSupportAndCustomer',
    group: 'UNDELIVERED',
    displayed: 'INCOMPLETE CONSIGNEE ADDRESS',
  },
  {
    code: 'U02',
    type: 'unknownreceiver',
    tag: 'actionRequired',
    description: 'contactCustomer',
    group: 'UNDELIVERED',
    displayed: 'CONSIGNEE UNKNOWN',
  },{
    code: 'U03',
    type: 'typeU03',
    tag: 'actionRequired',
    description: 'contactCustomer',
    group: 'UNDELIVERED',
    displayed: 'PENERIMA PINDAH ALAMAT',
  },
  {
    code: 'U05',
    type: 'onHoldrefuseToReceiveShipment2',
    tag: 'actionRequired',
    description: 'contactCustomer',
    group: 'UNDELIVERED',
    displayed: 'RUMAH /KANTOR KOSONG',
  },
  {
    code: 'U06',
    type: 'refuseToReceiveShipment',
    tag: 'actionRequired',
    description: 'contactCustomer',
    group: 'UNDELIVERED',
    displayed: 'CONSIGNEE REFUSE TO RECEIVE SHIPMENT',
  },
  {
    code: 'U07',
    type: 'typeU07',
    tag: 'actionRequired',
    description: 'contactCustomer',
    group: 'UNDELIVERED',
    displayed: 'RUMAH /KANTOR TIDAK DIHUNI',
  },
  {
    code: 'U09',
    type: 'typeU07',
    tag: 'actionRequired',
    description: 'contactCustomer',
    group: 'UNDELIVERED',
    displayed: 'RUMAH /KANTOR TIDAK DIHUNI',
  },
  {
    code: 'U10',
    type: 'typeU10',
    tag: 'actionRequired',
    description: 'contactCustomer',
    group: 'UNDELIVERED',
    displayed: 'Undelivered',
  },
  {
    code: 'U12',
    type: 'typeU12',
    tag: 'actionRequired',
    description: 'contactCustomer',
    group: 'UNDELIVERED',
    displayed: 'Undelivered',
  },
  {
    code: 'U08',
    type: 'refuseToPayCodShipment',
    tag: 'actionRequired',
    description: 'contactCustomer',
    group: 'UNDELIVERED',
    displayed: 'CONSIGNEE REFUSE TO PAY COD SHIPMENT / SHIPMENT FEE',
  },
  {
    code: 'U21',
    type: 'refuseToPayCodShipment',
    tag: 'actionRequired',
    description: 'contactCustomer',
    group: 'UNDELIVERED',
    displayed: 'CONSIGNEE REFUSE TO PAY COD SHIPMENT / SHIPMENT FEE',
  },
  {
    code: 'U22',
    type: 'typeU22',
    tag: 'actionRequired',
    description: 'contactCustomer',
    group: 'UNDELIVERED',
    displayed: 'TUTUP/LIBUR CUTI/DINAS LUAR KOTA',
  },
  {
    code: 'U23',
    type: 'typeU22',
    tag: 'actionRequired',
    description: 'contactCustomer',
    group: 'UNDELIVERED',
    displayed: 'TUTUP/LIBUR CUTI/DINAS LUAR KOTA',
  },
  {
    code: 'U25',
    type: 'typeU25',
    tag: 'actionRequired',
    description: 'contactCustomer',
    group: 'UNDELIVERED',
    displayed: 'PENERIMA MENOLAK BAYAR (KIRIMA',
  },
  {
    code: 'UB1',
    type: 'onHoldLockerSizeNotAvailable',
    tag: 'attention',
    description: 'sortedOutSoon',
    group: 'UNDELIVERED',
    displayed: 'SHIPMENT SIZE IS BIGGER THAN AVAILABLE LOCKER SIZE',
  },
  {
    code: 'UB2',
    type: 'onHoldShipmentPickedUpFromLockerByCourier',
    tag: 'attention',
    description: 'sortedOutSoon',
    group: 'UNDELIVERED',
    displayed: 'SHIPMENT PICKED UP FROM LOCKER BY COURIER, DUE TO 3 DAYS RETENTION ALREADY EXPIRED',
  },
  //3.e. PROBLEM SHIPMENT
  {
    code: 'PS1',
    type: 'onHoldShipmentDamaged',
    tag: 'actionRequired',
    description: 'contactCustomer',
    group: 'PROBLEM SHIPMENT',
    displayed: 'SHIPMENT DAMAGE, WAITING FOR FURTHER INSTRUCTION',
  },
  {
    code: 'PS2',
    type: 'onHoldShipmentWrongDestination',
    tag: 'actionRequired',
    description: 'contactCustomer',
    group: 'PROBLEM SHIPMENT',
    displayed: 'SHIPMENT SHIPPED TO WRONG DESTINATION',
  },
  {
    code: 'PS3',
    type: 'onHoldShipmentCrissCrossed',
    tag: 'actionRequired',
    description: 'contactCustomer',
    group: 'PROBLEM SHIPMENT',
    displayed: 'SHIPMENTS ARE CRISSCROSSED',
  },
  {
    code: 'PS4',
    type: 'onHoldShipmentLost',
    tag: 'actionRequired',
    description: 'contactCustomer',
    group: 'PROBLEM SHIPMENT',
    displayed: 'SHIPMENT LOST',
  },
  //3.f. DELIVERY PROBLEM
  {
    code: 'BY',
    type: 'noUpdShipmentOnSortingProcess',
    tag: 'attention',
    description: 'sortedOutSoon',
    group: 'DELIVERY PROBLEM',
    displayed: 'SHIPMENT ON SORTING PROCESS AT DESTINATION [CITY]',
  },
  {
    code: 'DP3',
    type: 'noUpdOutOfDeliveryArea',
    tag: 'attention',
    description: 'sortedOutSoon',
    group: 'DELIVERY PROBLEM',
    displayed: "CONSIGNEE'S ADDRESS IS OUT OF DELIVERY AREA FOR YES",
  },
  {
    code: 'DP4',
    type: 'noUpdChangeAddress',
    tag: 'attention',
    description: 'sortedOutSoon',
    group: 'DELIVERY PROBLEM',
    displayed: "CHANGE OF CONSIGNEE'S ADDRESS",
  },
  {
    code: 'DP5',
    type: 'noUpdTrafficAccident',
    tag: 'attention',
    description: 'sortedOutSoon',
    group: 'DELIVERY PROBLEM',
    displayed: 'COURIER GOT A TRAFFIC ACCIDENT',
  },
  //3.g. DELIVERY PROBLEM
  {
    code: 'KRK',
    type: 'onHoldNobodyAtHome',
    tag: 'actionRequired',
    description: 'contactCustomer',
    group: 'DELIVERY PROBLEM',
    displayed: 'NOBODY AT HOME / OFFICE TO RECEIVE SHIPMENT',
  },
  {
    code: 'OC',
    type: 'onHoldDestinationCorrection',
    tag: 'actionRequired',
    description: 'contactCustomer',
    group: 'DELIVERY PROBLEM',
    displayed: 'DESTINATION CORRECTION',
  },
  {
    code: 'NT',
    type: 'onHoldNT',
    tag: 'actionRequired',
    description: 'contactCustomer',
    group: 'DELIVERY PROBLEM',
    displayed: 'NOT TIME DELIVERY',
  },
  {
    code: 'KRP',
    type: 'onHoldReceiverMoved',
    tag: 'actionRequired',
    description: 'contactCustomer',
    group: 'DELIVERY PROBLEM',
    displayed: 'HOME / OFFICE ADDRESS ALREADY MATCH, BUT CONSIGNEE MOVED TO DIFFERENT ADDRESS',
  },
  {
    code: 'KRT',
    type: 'onHoldOfficeClosed',
    tag: 'actionRequired',
    description: 'contactCustomer',
    group: 'DELIVERY PROBLEM',
    displayed: 'OFFICE CLOSED DURING WEEKEND/ NATIONAL HOLIDAY',
  },
  {
    code: 'FOM',
    type: 'delayedNoAccessForceMajeur',
    tag: 'actionRequired',
    description: 'contactCustomer',
    group: 'DELIVERY PROBLEM',
    displayed: 'NO ACCESS DUE TO FORCE MAJEURE',
  },
  {
    code: '000',
    type: 'noNotificationDELIVERED',
    group: 'CUSTOM',
    displayed: 'DELIVERED TO',
  },
]
