<script>
import axios from 'axios'
import config from './../config.js'
import { getSizedImageUrl } from "@/helpers/imagekitHelper";
export default {
    props: ['dir', 'value', 'className', 'type', 'btn', 'customStyle', 'bumpProduct'],
    data() {
        return {
            customWidth: 0,
            mobileVersion: false,
            isSizeWindow: false,
            getSizedImageUrl,
            isImageUploading: false,
        };
    },
    created() {
        this.customWidth = process.client ? window.innerWidth :"";
        if(process.client){
            window.addEventListener("resize", this.updateWidth);
        }
   
    },
    watch: {
        'customWidth':{
            handler(val){
                this.mobileVersion = val <= 560 ?  true : false;
                this.isSizeWindow = this.mobileVersion;
            },
            deep: true
        }
    },
    methods: {
        updateWidth() {
            this.customWidth = process.client ? window.innerWidth : "";
            if (this.customWidth <= 560) {
                this.mobileVersion = true;
            }
        },
        removeImage() {
            this.$emit('input', '')
        },
        onPickFile () {
            this.$refs.fileInput.click()
        },
        async onFilePicked (event) {            
            const files = event.target.files
            const formData = new FormData();
            let uploadImage=true;
            //  // appending file
            this.isImageUploading = true
            this.$emit("loadingChange",this.isImageUploading);
            if (files[0].size <= 10485760) {
            // Check if file is an image
            if (files[0].type.startsWith("image/")) {
                //let b = await this.resize(files[x]);
                formData.append("file", files[0]);
            } else {
                    this.$vs.notification({
                    color: "danger",
                    position: "top-right",
                    text: "Please select valid image format",
                    duration: 5000,
                    });
                    uploadImage=false;
                }
            } else {
                this.$vs.notification({
                    color: "danger",
                    position: "top-right",
                    text:"Image size exceeds 10 mb",
                    duration: 5000,
                });
                uploadImage=false;
            }
            if(uploadImage){
                try {
                // const files = event.target.files
                // const formData = new FormData();
                    //formData.append("file", files[0]);  // appending file

                    // sending file to the backend
                    let url = (this.type !== "file") ? "product/upload-images" : "product/upload-file"
                    url = config.api + url
                    let im = await axios.post(url, formData)

                    if (im.data.success) {
                        this.$emit('input', im.data.data)
                                        
                    }
                    
                    this.isImageUploading = false    
                     this.$emit("loadingChange",this.isImageUploading);
                } catch (e) {
                    console.error("Error uploading image:", e);
                    this.$vs.notification({
                        color: "danger",
                        position: "top-right",
                        text: "Error uploading image",
                        duration: 5000,
                    });
                    this.uploadProgress = 0;
                    this.isImageUploading = false
                    this.$refs.fileInput.value = ""
                    this.$emit("loadingChange",this.isImageUploading);

                }
             }else{
                this.uploadProgress = 0;
                this.isImageUploading = false
                this.$refs.fileInput.value = ""
                 this.$emit("loadingChange",this.isImageUploading);
            }
        },
    },
};
</script>

<template>
<div>
    <div v-if='type !== "file" && bumpProduct'>
        <div v-if='btn && value' class='bump-order-img'>
            <div class="img-wrapper mr-20">
                <div class="image-inner">
                    <img class="upload-img" :src="value ? getSizedImageUrl(value,'medium','product-images') : '/img/icon/default-profile.svg'"/>
                </div>
                <div v-if="value" class="del-btn" @click="removeImage">
                    <img src='/img/icon/close.svg'/>
                </div>
            </div>
            <div class="d-flex align-items-center">
                <button v-if='type !== "file"' @click="onPickFile" class="pp-button-blue--fill mr-15">Upload New Image</button>
                <button v-if='btn && value' class='pp-button-red--border' @click="removeImage" style="width: 150px">Delete</button>
            </div>
        </div>

        <div v-if='btn' class='w-100'>
            <vs-button v-if='btn && !value' flat active class='m-0 upload-image-btn' @click="onPickFile">
                <img class="mr-1 light" src='/img/icon/attachment.svg'/>
                <img class="mr-1 dark" src='/img/icon/attachment-dark.svg'/>
                {{btn}}
            </vs-button>    
        </div>
    </div>
    <div v-if='type !== "file" && !bumpProduct' >
        <div class='testimonial-wrapper' :style='!isSizeWindow ? "background: #F6F8FC; border-radius: 8px;":""'>
            <div class="img-wrapper">
                <div class="image-inner">
                    <img class="upload-img" :src="value ?getSizedImageUrl(value,'medium','product-images')  : '/img/icon/default-profile.svg'"/>
                </div>
                <div v-if="value" class="del-btn" @click="removeImage">
                    <img src='/img/icon/del.svg'/>
                </div>
            </div>
            <!--
            <div v-if="isSizeWindow" @click="onPickFile" class="upload-testi-btn"><img src='/img/icon/Upload-img.svg'/></div>
            -->
            <button v-if='type !== "file"' @click="onPickFile" class="upload-testi-btn">Upload Photo</button>
        </div>
    </div>
    <div v-if='type == "file"' :style='customStyle ? customStyle : "height: 44px; background: transparent; border: 1px solid #bfcee0; padding: 7px 13px; border-radius: 8px;"' 
    :class='className.isError ? "transferFile-error" : ""'>
        <input
            :class='className ? className : ""'
            type="file"
            ref="fileInput"
            accept="image/*"
            @change="onFilePicked"/>    
    </div>
    <input
        v-else
        :class='className ? className : ""'
        type="file"
        ref="fileInput"
        :style="'display: none'"
        accept="image/*"
        @change="onFilePicked"/>
</div>
</template>

<style scoped>
.transferFile-error {
    border: 1px solid red !important;
}
.testimonial-wrapper{
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 207px;
}
.bump-order-img {
    display: flex;
    align-items: center;
    /* padding: 10px 0 0; */
}

.upload-testi-btn{
    font-family: 'Inter', sans-serif;
    font-style: normal; 
    font-weight: 500; 
    font-size: 14px; 
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #2E47BA; 
    border: 1px solid #2e47ba; 
    background: transparent;
    border-radius: 8px; 
    padding: 10px 13px;
    margin-top: 20px;
    cursor: pointer;
}

.img-wrapper{
    position: relative;
    border-radius: 100%;
}

.testimonial-wrapper .image-inner{
    width: 65px;
    height: 65px;
    overflow: hidden;
    border-radius: 50%;
}
.testimonial-wrapper .upload-img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    border-radius: 100px;
}

.bump-order-img .image-inner{
    width: 118px;
    height: 118px;
    overflow: hidden;
}
.bump-order-img .upload-img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

.testimonial-wrapper .del-btn{
    display: inline-block;
    position: absolute;
    top: 0px;
    left: 41px;
    cursor: pointer;
    width: max-content;
}

.bump-order-img .del-btn{
    display: inline-block;
    position: absolute;
    top: 9px;
    right: 9px;
    cursor: pointer;
    width: max-content;
}

@media (max-width: 767px){
    .testimonial-wrapper{
        flex-direction: row;
        height: auto;
        grid-gap: 25px;
        justify-content: start;
        margin-top: 20px;
    }
    .upload-testi-btn{
        margin: 0;
    }
    body[data-sidebar-color="dark"] .upload-testi-btn{
        color: #FFF;
        border-color: #FFF;
    }
}
</style>
