export function getfbpc(){
    let fbp = document.cookie.split(';').filter(c => c.includes('_fbp=')).map(c => c.split('_fbp=')[1]);
    let fbc = document.cookie.split(';').filter(c => c.includes('_fbc=')).map(c => c.split('_fbc=')[1]);
    fbp = (fbp.length && fbp[0]) || null;
    fbc = (fbc.length && fbc[0]) || null;

    if(!fbc && window.location.search.includes('fbclid=')){
        fbc = 'fb.1.'+ (+new Date()) +'.'+ window.location.search.split('fbclid=')[1];
    }

    return {fbp, fbc};
}