module.exports = [
    { "text": "Bank Mandiri", "oy_code": "008", "id": "mandiri" },
    { "text": "Bank Rakyat Indonesia", "oy_code": "002", "id": "bri" },
    { "text": "BNI (Bank Negara Indonesia) & BNI Syariah", "oy_code": "009", "id": "bni" },
    { "text": "BNI Syariah", "oy_code": "612", "id": "bni_syariah" },
    { "text": "CIMB Niaga Syariah", "oy_code": "613", "id": "cimb_niaga_syariah" },
    { "text": "Bank Central Asia (BCA)", "oy_code": "014", "id": "bca" },
    { "text": "Bank Syariah Mandiri", "oy_code": "451", "id": "bsm" },
    { "text": "CIMB Niaga & CIMB Niaga Syariah", "oy_code": "022", "id": "cimb" },
    { "text": "Muamalat", "oy_code": "147", "id": "muamalat" },
    { "text": "Bank Danamon", "oy_code": "011", "id": "danamon" },
    { "text": "Bank Permata & Permata Syariah", "oy_code": "013", "id": "permata" },
    { "text": "Maybank Indonesia", "oy_code": "016", "id": "bii" },
    { "text": "Panin Bank", "oy_code": "517", "id": "panin" },
    { "text": "UOB Indonesia", "oy_code": "023", "id": "uob" },
    { "text": "OCBC NISP", "oy_code": "028", "id": "ocbc" },
    { "text": "Citibank", "oy_code": "031", "id": "citibank" },
    { "text": "Bank Artha Graha Internasional", "oy_code": "037", "id": "artha" },
    { "text": "Bank of Tokyo Mitsubishi UFJ", "oy_code": "042", "id": "tokyo" },
    { "text": "DBS Indonesia", "oy_code": "046", "id": "dbs" },
    { "text": "Standard Chartered Bank", "oy_code": "050", "id": "standard_chartered" },
    { "text": "Bank Capital Indonesia", "oy_code": "054", "id": "capital" },
    { "text": "ANZ Indonesia", "oy_code": "061", "id": "anz" },
    { "text": "Bank of China (Hong Kong) Limited", "oy_code": "069", "id": "boc" },
    { "text": "Bank Bumi Arta", "oy_code": "076", "id": "bumi_arta" },
    { "text": "HSBC Indonesia", "oy_code": "087", "id": "hsbc" },
    { "text": "Rabobank International Indonesia", "oy_code": "089", "id": "rabobank" },
    { "text": "Bank Mayapada", "oy_code": "097", "id": "mayapada" },
    { "text": "BJB", "oy_code": "110", "id": "bjb" },
    { "text": "Bank DKI Jakarta", "oy_code": "111", "id": "dki" },
    { "text": "BPD DIY", "oy_code": "112", "id": "daerah_istimewa" },
    { "text": "Bank Jateng", "oy_code": "113", "id": "jawa_tengah" },
    { "text": "Bank Jatim", "oy_code": "114", "id": "jawa_timur" },
    { "text": "Bank Jambi", "oy_code": "115", "id": "jambi" },
    { "text": "Bank Sumut", "oy_code": "117", "id": "sumut" },
    { "text": "Bank Sumbar (Bank Nagari)", "oy_code": "118", "id": "sumatera_barat" },
    { "text": "Bank Riau Kepri", "oy_code": "119", "id": "riau_dan_kepri" },
    { "text": "Bank Sumsel Babel", "oy_code": "120", "id": "sumsel_dan_babel" },
    { "text": "Bank Lampung", "oy_code": "121", "id": "lampung" },
    { "text": "Bank Kalsel", "oy_code": "122", "id": "kalimantan_selatan" },
    { "text": "Bank Kalbar", "oy_code": "123", "id": "kalimantan_barat" },
    { "text": "Bank Kaltim", "oy_code": "124", "id": "kalimantan_timur" },
    { "text": "Bank Kalteng", "oy_code": "125", "id": "kalimantan_tengah" },
    { "text": "Bank Sulselbar", "oy_code": "126", "id": "sulselbar" },
    { "text": "Bank SulutGo", "oy_code": "127", "id": "sulut" },
    { "text": "Bank NTB", "oy_code": "128", "id": "nusa_tenggara_barat" },
    { "text": "BPD Bali", "oy_code": "129", "id": "bali" },
    { "text": "Bank NTT", "oy_code": "130", "id": "nusa_tenggara_timur" },
    { "text": "Bank Maluku", "oy_code": "131", "id": "maluku" },
    { "text": "Bank Papua", "oy_code": "132", "id": "papua" },
    { "text": "Bank Bengkulu", "oy_code": "133", "id": "bengkulu" },
    { "text": "Bank Sulteng", "oy_code": "134", "id": "sulawesi" },
    { "text": "Bank Sultra", "oy_code": "135", "id": "sulawesi_tenggara" },
    { "text": "Bank Nusantara Parahyangan", "oy_code": "145", "id": "nusantara_parahyangan" },
    { "text": "Bank of India Indonesia", "oy_code": "146", "id": "india" },
    { "text": "Bank Mestika Dharma", "oy_code": "151", "id": "mestika_dharma" },
    { "text": "Bank Sinarmas", "oy_code": "153", "id": "sinarmas" },
    { "text": "Bank Maspion Indonesia", "oy_code": "156", "id": "maspion" },
    { "text": "Bank Ganesha", "oy_code": "161", "id": "ganesha" },
    { "text": "ICBC Indonesia", "oy_code": "164", "id": "icbc" },
    { "text": "QNB Indonesia", "oy_code": "167", "id": "qnb_kesawan" },
    { "text": "BTN (Bank Tabungan Negara)", "oy_code": "200", "id": "btn" },
    { "text": "Bank Woori Saudara", "oy_code": "212", "id": "woori" },
    { "text": "BTPN", "oy_code": "213", "id": "tabungan_pensiunan_nasional" },
    { "text": "BRI (Bank Rakyat Indonesia) Syariah", "oy_code": "422", "id": "bri_syr" },
    { "text": "BJB Syariah", "oy_code": "425", "id": "bjb_syr" },
    { "text": "Bank Mega", "oy_code": "426", "id": "mega" },
    { "text": "Bukopin", "oy_code": "441", "id": "bukopin" },
    { "text": "Bank Jasa Jakarta", "oy_code": "472", "id": "jasa_jakarta" },
    { "text": "KEB Hana Bank Indonesia", "oy_code": "484", "id": "hana" },
    { "text": "Bank MNC Internasional", "oy_code": "485", "id": "mnc_internasional" },
    { "text": "BRI Agroniaga", "oy_code": "494", "id": "agroniaga" },
    { "text": "SBI Indonesia", "oy_code": "498", "id": "sbi_indonesia" },
    { "text": "Bank Royal Indonesia", "oy_code": "501", "id": "royal" },
    { "text": "Nobu (Nationalnobu) Bank", "oy_code": "503", "id": "nationalnobu" },
    { "text": "Bank Mega Syariah", "oy_code": "506", "id": "mega_syr" },
    { "text": "Bank Ina Perdana", "oy_code": "513", "id": "ina_perdana" },
    { "text": "Bank Sahabat Sampoerna", "oy_code": "523", "id": "sahabat_sampoerna" },
    { "text": "Bank Kesejahteraan Ekonomi", "oy_code": "535", "id": "kesejahteraan_ekonomi" },
    { "text": "BCA (Bank Central Asia) Syariah", "oy_code": "536", "id": "BCA_syr" },
    { "text": "Bank Artos Indonesia", "oy_code": "542", "id": "artos" },
    { "text": "Bank Mayora Indonesia", "oy_code": "553", "id": "mayora" },
    { "text": "Bank Index Selindo", "oy_code": "555", "id": "index_selindo" },
    { "text": "Bank Victoria International", "oy_code": "566", "id": "victoria_internasional" },
    { "text": "Bank Agris", "oy_code": "945", "id": "agris" },
    { "text": "CTBC (Chinatrust) Indonesia", "oy_code": "949", "id": "chinatrust" },
    { "text": "Commonwealth Bank", "oy_code": "950", "id": "commonwealth" },
    { "text": "Bank Victoria Syariah", "oy_code": "405", "id": "victoria_syr" },
    { "text": "BPD Banten", "oy_code": "137", "id": "banten" },
    { "text": "Doku", "oy_code": "", "id": "doku" },
    { "text": "Bank Mutiara", "oy_code": "095", "id": "mutiara" },
    { "text": "Panin Dubai Syariah", "oy_code": "517", "id": "panin_syr" },
 ] 