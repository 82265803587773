<script>
export default {
    props: ['data', 'classProp'],
};
</script>

<template>
  <div :class='classProp'>
    {{data}}
  </div>
</template>
