<script>
import axios from "axios";
import config from "./../config.js";
import { getSizedImageUrl } from "@/helpers/imagekitHelper";

export default {
  props: ["dir", "value", "className", "type", "btn", "orderDetails"],
  data() {
    return {
      getSizedImageUrl,
      uploadProgress: 0,
      isImageUploading: false,
    }
  },
  methods: {
    removeImage() {
      this.$emit("input", "");
    },
    onPickFile() {
      this.$refs.fileInput.click();
    },
    async onFilePicked(event) {
      const files = event.target.files;
      const formData = new FormData();
      let uploadImage=true;
      this.isImageUploading = true
      //formData.append("file", files[0]); // appending file

      if (files[0].size <= 10485760) {
      // Check if file is an image
      if (files[0].type.startsWith("image/")) {
          //let b = await this.resize(files[x]);
          formData.append("file", files[0]);
      } else {
              this.$vs.notification({
              color: "danger",
              position: "top-right",
              text: "Please select valid image format",
              duration: 5000,
              });
              uploadImage=false;
          }
      } else {
          this.$vs.notification({
              color: "danger",
              position: "top-right",
              text: this.$t('product.imageSize10'),
              duration: 5000,
          });
          uploadImage=false;
      }
      if(uploadImage){
        // sending file to the backend
        try {
          let url =
            this.type !== "file" ? "product/upload-images" : "product/upload-file";
          url = config.api + url;
          let im = await axios.post(url, formData, {
                      onUploadProgress: (progressEvent) => {
                          this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                      },
                  });
          if (im.data.success) {
            this.$emit("input", im.data.data);
          }
         } catch (e) {
                console.error("Error uploading image:", e);
                this.$vs.notification({
                    color: "danger",
                    position: "top-right",
                    text: "Error uploading image",
                    duration: 5000,
                });
                this.uploadProgress = 0;
                this.isImageUploading = false
                this.$refs.fileInput.value = ""
            }
      }else{
            this.uploadProgress = 0;
            this.isImageUploading = false
            this.$refs.fileInput.value = ""
        }
    },
  },
};
</script>

<template>
  <div :class="className['is-invalid'] ? 'is-invalid' : ''">
    <div v-if="type !== 'file'">
      <div v-if="!btn || value" class="flex items-center py-1">
        <img
          @click="onPickFile"
          style="width: 100%; border: 1px solid #ddd; cursor: pointer"
          :src="value ? getSizedImageUrl(value,'medium','product-images') : '/img/people.png'"
        />
      </div>
      <div v-if="btn" class="w-100">
        <vs-button
          v-if="btn && !value"
          flat
          active
          class="m-0"
          @click="onPickFile"
          ><i class="ri-image-fill mr-1"></i>{{ btn }}</vs-button
        >
        <vs-button
          v-if="btn && value"
          flat
          active
          class="m-0"
          @click="removeImage"
          ><i class="ri-image-fill mr-1"></i>Remove Image</vs-button
        >
      </div>
    </div>

    <div 
    class="write-review__upload-images"
    v-if="orderDetails"
   
    >
    <template v-if="!isImageUploading">
      <img src="@/assets/icons/attachment.svg" />
      <p class="write-review__upload-images__title">Upload Images</p>
      <input
          type="file"
          ref="fileInput"
          accept="image/*"
          @change="onFilePicked"
        />
    </template>
    <template v-else>
      <span>Loading... {{ uploadProgress }}%</span>
    </template>
   
    </div>

    <div
      v-if="type == 'file' && !orderDetails"
      class="file-upoloader"
      :class="className['is-invalid'] ? 'file-upoloader--error': ''"
    >
      <input
        type="file"
        ref="fileInput"
        accept="image/*"
        @change="onFilePicked"
      />
    </div>

    <input
      v-else
      type="file"
      ref="fileInput"
      :style="'display: none'"
      accept="image/*"
      @change="onFilePicked"
    />
  </div>
</template>

<style lang="scss">
.write-review__upload-images{
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 1px dashed #BFCEE0; 
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: relative;
    cursor: pointer;
    height: 215px;
    p{
      color: #203551;
      text-align: center;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 138.462% */
      letter-spacing: 0.26px;
      margin: 0;
    }
    input{
      cursor: pointer;
      opacity: 0;
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }
.file-upoloader {
  display: flex;
  align-items: center;
  
  height: 44px; 
  text-align: left;

  border: 1px solid #bfcee0;
  padding: 7px 13px; 
  border-radius: 8px;

  .file-upoloader--error{
    border-color: red;
  }
}
</style>
