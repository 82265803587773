<script>
import { getSizedImageUrl } from "../helpers/imagekitHelper";
export default {
    data() {
    return {
      getSizedImageUrl
    }   
  },
  props:["headerImage"] 
};
</script>

<template>
     <img :src="getSizedImageUrl(headerImage,'fullView','header-images')" alt="header image" />
</template>