<script>
import Empty from './empty'
// import { defineAsyncComponent } from "vue";
// const Empty = defineAsyncComponent(() => import("./empty.vue"));
import debounce from "lodash/debounce";
import axios from "axios";
import config from "./../config.js";
export default {
  props: [
    "value",
    "className",
    "type",
    "dontIncludeSubdistrict",
    "placeholder",
    "fieldName",
    "addressVal",
    "isLabel",
    "formMode",
    "shippingData"
  ],
  data() {
    return {
      baseUrl: config.api.replace("/api", ""),
      searchData: "",
      autofillLoading: false,
      autoFillPayload: undefined,
      AutofillData: [],
      customerAUTOFILL: "",
      payload: {
        customerAUTOFILL: "",
        customerFULL_AUTOFILL: "",
        PROVINCE: "",
        DISTRICT: "",
        SUBDISTRICT: "",
        CITY: "",
        ZIP: "",
        ORIGIN_CODE: "",
        CODE_SAP: "",
        DESTINATION_CODE_SI: "",
        ORIGIN_CODE_SI: "",
        closedSi: "",
        unsupportedSi: "",
        CITY_NAME_SI: "",
      },
    };
  },
  mounted() {    
    const city=[],district=[],province=[];
    const params={ };
    const params2={};

      if(this.shippingData && this.shippingData.enableExcludeShipping && this.shippingData.excludeShippingLocation && this.shippingData.excludeShippingLocation.length>0){
          this.shippingData.excludeShippingLocation.map(item=>{
              if(item.type=='province'){
                  province.push(item.PROVINCE_NAME);
              }else if(item.type=='city'){
                  city.push(item.item.CITY_NAME);
              }else if(item.type=='district'){
                  district.push(item.item.DISTRICT_NAME);
              }
          })
          params['province_filter']=province.join(",");
          params['city_filter']=city.join(",");
          params['district_filter']=district.join(",")
          params['excludeShipping']=this.shippingData.excludeShipping;     

          

          
      }
   
      this.handleAutofill = debounce(async function (val) {
      this.autofillLoading = true;
      this.AutofillData = [];
      let autofill = await axios.get(
        this.baseUrl + "api/address/autofill/province",
        { params:{province: val,...params,exact:true} }
      );
      this.AutofillData = autofill.data.success ? autofill.data.data : [];
      this.autofillLoading = false;
    }, 500);

    this.handleAutofillSecond = debounce(async function (val) {
      this.autofillLoading = true;
      this.AutofillData = [];

      val={...val,...params2};

      let autofill = await axios.get(this.baseUrl + "api/address/getAddress", {
        params: val,
      });
      this.AutofillData = autofill.data.success ? autofill.data.data : [];
      this.autofillLoading = false;
    }, 500);

    if (this.fieldName == "PROVINCE_NAME") {
      this.handleAutofill();
    }

    if (this.fieldName == "CITY_NAME" && ((this.addressVal && this.addressVal.PROVINCE) || this.formMode == 'Progressive Form')) {
      this.handleAutofillSecond({ province: this.addressVal && this.addressVal.PROVINCE ? this.addressVal.PROVINCE : undefined });
    }
    
    if (this.fieldName == "DISTRICT_NAME" && ((this.addressVal && this.addressVal.PROVINCE && this.addressVal.CITY) || this.formMode == 'Progressive Form')) {
      this.handleAutofillSecond({
        province: this.addressVal && this.addressVal.PROVINCE ? this.addressVal.PROVINCE : undefined,
        city: this.addressVal && this.addressVal.CITY ? this.addressVal.CITY : undefined,
      });
    }

    if (this.addressVal) {
      this.payload = this.addressVal;
      this.customerAUTOFILL = this.payload.customerAUTOFILL+'_'+this.addressVal[this.fieldName]
    }
  },

  watch: {
    customerAUTOFILL(val){
      if(val){
        this.payload.customerAUTOFILL = val.split('_')[0]
        this.changeDestinationCode();
      }
    },
    addressVal(val) {
      if(val){
        if (this.fieldName == "PROVINCE_NAME") {
          this.handleAutofill(val["PROVINCE"]);
        } else {
          let search = {
            key: this.fieldName,
            value: val[this.fieldName.split("_")[0]],
          };
          this.handleAutofillSecond({ province: val["PROVINCE"], search });
        }     
        this.payload = val;
        if (this.fieldName == "PROVINCE_NAME") {
          this.payload.PROVINCE=val.PROVINCE_NAME;
          this.customerAUTOFILL=val.customerAUTOFILL;
        }
        if (this.fieldName == "CITY_NAME" && val.CITY_NAME) {
          this.payload.CITY=val.CITY_NAME;
          this.customerAUTOFILL=val.customerAUTOFILL;
        }
        if (this.fieldName == "DISTRICT_NAME" && val.DISTRICT_NAME) {
          this.payload.DISTRICT=val.DISTRICT_NAME;
          this.customerAUTOFILL=val.customerAUTOFILL;
        }
       
       
      }
     
    },
    "payload.customerAUTOFILL": {
      async handler() {
        this.changeDestinationCode();
      },
    },
    searchData: {
      async handler(val) {
        if (val.length > 3 || val.length==0) {
          if (this.fieldName == "PROVINCE_NAME") {
            this.handleAutofill(val);
          } else {
            let search = {
              key: this.fieldName,
              value: val,
            };
            this.handleAutofillSecond({ ...this.autoFillPayload, search });
          }
        }
      },
    },
    "addressVal.PROVINCE": {
      async handler(val) {
        if (this.fieldName == "CITY_NAME" && this.addressVal) {
          this.autoFillPayload = { province: val };
          this.handleAutofillSecond(this.autoFillPayload);
          // this.payload.CITY = '';
          // this.payload.DISTRICT= '';
        }
      },
    },
    // "AutofillData": {
    //   handler(val) {
    //     // console.log("here",this.$refs.carousel,this.images.length,this.variantImageIndex);
      
    //     if(val.length == 1 && this.fieldName == "PROVINCE_NAME"){
    //       this.customerAUTOFILL= val[0]._id+'_'+val[0][this.fieldName];
    //       this.changeDestinationCode()
    //     }     

    //     if(val.length == 1 && this.fieldName == "DISTRICT_NAME"){
    //       this.customerAUTOFILL= val[0]._id+'_'+val[0][this.fieldName];
    //       this.changeDestinationCode()
    //     }       

    //     if(val.length == 1 && this.fieldName == "CITY_NAME"){
    //       this.customerAUTOFILL= val[0]._id+'_'+val[0][this.fieldName];
    //       this.changeDestinationCode()       
    //     }
       
    //   },
    //   deep: true,
    // },
    // AutofillData(val){
    //   console.log("this.fieldName",this.fieldName,val);
    //   if(val.length == 1 && this.fieldName == "DISTRICT_NAME"){
    //     this.customerAUTOFILL= val[0]._id+'_'+val[0][this.fieldName];
    //     this.changeDestinationCode()
    //   }
    //   // if(val.length == 1 && this.fieldName == "CITY_NAME"){
    //   //   this.customerAUTOFILL= val[0]._id+'_'+val[0][this.fieldName];
    //   //   this.changeDestinationCode()
       
    //   // }
    // },
    "addressVal.CITY": {
      async handler(val) {
        if (
          this.addressVal &&
          this.addressVal.PROVINCE &&
          this.addressVal.CITY &&
          this.fieldName == "DISTRICT_NAME"
        ) {
          this.autoFillPayload = {
            province: this.addressVal.PROVINCE,
            city: val,
          };
          this.handleAutofillSecond(this.autoFillPayload);
          //this.payload.DISTRICT= '';
        }
      },
    },
  },
  methods: {
    changeDestinationCode() {
      let val = this.payload.customerAUTOFILL;
      if (this.AutofillData.length) {
        let find = this.AutofillData.find((one) => one._id == val);
        if (find) {
          this.payload.SUBDISTRICT = find.SUBDISTRICT_NAME;
          this.payload.DISTRICT =
            this.fieldName == "DISTRICT_NAME"
              ? find.DISTRICT_NAME
              : this.addressVal
              ? this.addressVal.DISTRICT
              : "";

          this.payload.PROVINCE =
            this.fieldName == "PROVINCE_NAME"
              ? find.PROVINCE_NAME
              : this.addressVal
              ? this.addressVal.PROVINCE
              : "";
              
          if (this.fieldName == "CITY_NAME") {
            this.payload.CITY = find.CITY_NAME;
          } else {
            this.payload.CITY = this.addressVal ? this.addressVal.CITY : "";
          }
          this.payload.ZIP = find.ZIP_CODE;

          this.payload.DESTINATION_CODE_SI = find.DESTINATION_CODE_SI;
          this.payload.ORIGIN_CODE_SI = find.ORIGIN_CODE_SI;
          this.payload.closedSi = find.closedSi;
          this.payload.unsupportedSi = find.unsupportedSi;
          this.payload.CITY_NAME_SI = find.CITY_NAME_SI;

          this.payload.DESTINATION_CODE = find.DESTINATION_CODE;
          this.payload.ORIGIN_CODE = find.ORIGIN_CODE;

          this.payload.CODE_SAP = find.CODE_SAP;

          if (find.PROVINCE_NAME && find.CITY_NAME && find.DISTRICT_NAME) {
            this.payload.customerFULL_AUTOFILL =
              find.PROVINCE_NAME +
              ", " +
              find.CITY_NAME +
              ", " +
              find.DISTRICT_NAME +
              (this.dontIncludeSubdistrict ? "" : ", " + find.SUBDISTRICT_NAME);
          }
          this.$emit("onChange", this.payload);
          //this.getEstimate()
        }
      }
    },
    focusFields() {
      this.$refs.orderInput &&
        this.$refs.orderInput.$el.querySelector("input").focus();
    },
    resetFields() {
      if (this.fieldName == "PROVINCE_NAME") {
        this.customerAUTOFILL= "",
        this.payload.PROVINCE = "";
        this.payload.CITY = "";
        this.payload.DISTRICT = "";
        if(this.addressVal){       
          this.$emit('onChange', null)
        }
        
        // if(this.payload.PROVINCE == '') {
        //   this.customerAUTOFILL = '';
        // }
      }
      if (this.fieldName == "CITY_NAME") {
        this.customerAUTOFILL= "",
        this.payload.CITY = "";
        this.payload.DISTRICT = "";      
        // if(this.payload.CITY == '') {
        //   this.customerAUTOFILL = '';
        // }
        
      }
      if (this.fieldName == "DISTRICT_NAME") {
        this.payload.DISTRICT="";
        this.customerAUTOFILL = '';        
       // this.customerAUTOFILL = '';   
        // if(this.payload.DISTRICT == '') {
        //   this.customerAUTOFILL = '';         
        // }
       
      }
      if (this.searchData.length > 3) {
          if (this.fieldName == "PROVINCE_NAME") {
            this.handleAutofill(this.searchData);
          } else {
            let search = {
              key: this.fieldName,
              value: this.searchData,
            };
            this.handleAutofillSecond({ ...this.autoFillPayload, search });
          }
        }

    },
  },
  components: {
    Empty,
  },
};
</script>

<template>
  <div id="sub-address" class="sub-address">   
    <div
      :class="{
        'selected-address' : isLabel == 'showLabel', 
        'placeholder-text' : ((fieldName == 'PROVINCE_NAME' && payload.PROVINCE == '') || (payload.CITY == '' && fieldName == 'CITY_NAME') || (fieldName == 'DISTRICT_NAME' && payload.DISTRICT == ''))
      }"
      class="select-value-label"
    >
      {{
        fieldName == "PROVINCE_NAME"
          ? (payload.PROVINCE ? payload.PROVINCE : placeholder)
          : fieldName == "CITY_NAME" 
          ? (payload.CITY ? payload.CITY : placeholder)
          : fieldName == "DISTRICT_NAME"
          ? (payload.DISTRICT ? payload.DISTRICT : placeholder)
          : ""
      }}
    </div>
    <vs-select
      class="vs-form-control"
      @click="focusFields();resetFields();"
      :class="className"
      v-model="customerAUTOFILL"
    >  
      <vs-input
        ref="orderInput"
        class=""
        v-model="searchData"
        type="text"
      />
      <empty
        v-if="fieldName != 'PROVINCE_NAME'"
        :classProp="'p-1'"
        :data="
          AutofillData.length == 0
            ? fieldName == 'CITY_NAME'
              ? 'No Cities found'
              : fieldName == 'DISTRICT_NAME'
              ? 'No districts found'
              : ''
            : ''
        "
      />
      <empty
        v-else-if="autofillLoading"
        :classProp="'p-1'"
        :data="'Searching'"
      />
      <vs-option
        :key="opt._id+'_'+opt[fieldName]"
        v-for="opt in AutofillData"
        :label="opt.PROVINCE_NAME"
        :value="opt._id+'_'+opt[fieldName]"
      >
        {{ fieldName ? opt[fieldName] : "" }}
      </vs-option>
    </vs-select>
  </div>
</template>

<style lang="scss">
.sub-address {
  scroll-margin: 150px;
}
.placeholder-text {
  opacity: 0.55;
}
</style>