<script>
import bankNames from '@/data/bankNames.js';
import axios from 'axios';
import moment from 'moment';
import config from "@/config.js";
import StarRating from 'vue-star-rating';
import FileUploader from "@/components/FileUploader";
import trackingStatus from '@/data/trackingStatus.js';
import { cloneDeep } from 'lodash';
import { getSizedImageUrl } from "@/helpers/imagekitHelper";
export default {
  head() {
    return {
      title: `Order Details | Mengantar App`,
    };
  },
  components: {
    'star-rating' : StarRating,
    FileUploader
  },
  data() {
    return {
      baseUrl: config.api.replace('/api', ''),
      order: null,
      form: null,
      paymentData: null,
      showPayment: false,
      loading: false,
      customerAddress: [],
      shownAddress:'',
      writeReviewModal: false,
      orderReview: {
        userRating: 0,
        title:'',
        description:'',
        image:''
      },
      history: [],
      deliverdTime:'',
      error:{},
      submitted:false,
      getSizedImageUrl
    };
  },
  watch: {
  'order.orderReview': {
    handler() {
      this.$forceUpdate();
    },deep: true,
  },
  'orderReview' : {
    handler(val){
      this.error.title = val.title === '' ? 'Title is required' : '';
      this.error.description = val.description === '' ? 'Description is required' : '';
    },
    deep:true
   }
  },
  computed: {
    historyData() {
      if (!this.history) {
        return []
      }
      let res = []
      let date = ''
      let history = cloneDeep(this.history)
      if (this.order.reconciliatedDate) {
        history.push({
          desc: this.$t('common.ReconciliationCompleted'),
          date: this.order.reconciliatedDate,
        })
      }
      for (let i = history.length; i > 0; i--) {
        let data = history[i - 1]
        if (data.desc) {
          let currDate = moment(data.date, 'DD-MM-YYYY').format('DD MM YYYY')
          let status = trackingStatus.find((one) => {
            return data.desc && data.desc.includes(one.displayed)
          })
          if (data.code && (data.code.includes('R0') || data.code.includes('R1') || data.code.includes('R2')) && status) {
              status.type = 'RTS'
          }
          data.status = status
            ? this.$t('notifications.' + status.type)
            : data.desc
          if (moment(date, 'DD-MM-YYYY').format('DD MM YYYY') !== currDate) {
            data.showDate = true
          } else {
            data.showDate = false
          }
          let destination = data.desc.split('[')
          if (destination[1]) {
            data.destination = destination[1].replace(']', '')
          } else {
            data.destination = ''
          }
          if (data.status == 'HOLD FOR FURTHER INSTRUCTIONS') {
            data.status = 'ON PROCESS TO DESTINATION'
          }
          date = data.date
          data.day = moment(data.date, 'DD-MM-YYYY').format('DD MMM')
          data.time = moment(data.date, 'DD-MM-YYYY hh:mm').format('hh:mma')
          res.push(data)
          }
        }
        let created = {}
        created.day = moment(this.order.createdAt).format('DD MMM')
        created.time = moment(this.order.createdAt).format('hh:mma')
        let currDate2 = moment(this.order.createdAt).format('DD MM YYYY')
        if (moment(date, 'DD-MM-YYYY').format('DD MM YYYY') !== currDate2) {
          created.showDate = true
        } else {
          created.showDate = false
        }
        created.status = 'Order created'
        res.push(created)
        return res
     },

    isReceivedByCustomer() {
      const filteredEntries = this.history.filter((x) =>  x.desc == 'Paket telah diterima' || x.desc.includes('SHIPMENT RECEIVED BY'));
      return this.findEntryWithCondition(filteredEntries);
    },

    withDeliveryCourier() {
      const filteredEntries = this.history.filter((x) => x.desc.includes('Paket akan dikirimkan ke') || x.desc.includes('WITH DELIVERY COURIER'));
      return this.findEntryWithCondition(filteredEntries);
    },

    receivedInboundStation() {
      const filteredEntries = this.history.filter((x) => x.desc.includes('Paket telah sampai') || x.desc.includes('RECEIVED AT INBOUND STATION'));
      return this.findEntryWithCondition(filteredEntries);
    },

    receivedByShipper() {
      const filteredEntries = this.history.filter((x) =>  x.desc.includes('Paket telah diterima oleh') || x.desc.includes('SHIPMENT RECEIVED BY JNE COUNTER OFFICER AT'));
      return this.findEntryWithCondition(filteredEntries);
    },

    bank() {
      if (this.paymentData.length) {
        let bank = bankNames.find(one => one.id == this.paymentData[0].bankName)
        return bank ? bank.text : ''
      } else {
        return ''
      }

    }
  },
  filters: {
    year_format(val) {
      return moment(val).format('YYYY')
    },
    g_number_format(val) {
      return new Intl.NumberFormat().format(val);
    },
    g_date_format(val) {
      return moment(val).format('DD-MM-YYYY');
    },
    g_datetime_format(val) {
      return moment(val).format('DD-MM-YYYY HH:mm')
    }
  },
  async mounted() {
    // this.$store.dispatch("authfack/getUser", []);
    
    if (this.$route.query.theme != 'light') {
      document.body.setAttribute("data-sidebar-color", "dark");
    } else {
      document.body.setAttribute("data-sidebar-color", "light");
    }
    let orderData = await axios.get(config.api + 'order/one', { params: { ORDER_ID: this.$route.params.order_id, getPayment: true } })
    if (orderData.data.success) {
      this.order = orderData.data.order;
      this.form = orderData.data.form;
      this.paymentData = orderData.data.paymentData;
      if (this.order.RECEIVER_ADDR) {
        this.customerAddress.push(this.order.RECEIVER_ADDR);
      }
      if (this.order.address && this.order.RECEIVER_ADDR) {
        this.customerAddress.push(this.order.address.customerFULL_AUTOFILL.toLowerCase());
      }
      if (this.order.address && this.order.address.ZIP) {
        this.customerAddress.push(this.order.address.ZIP);
      }
      if (this.order.cnote_no) {
        let trackingData = await axios.post(config.api + 'order/getFollowUpShipmentData', { order_id: this.order.ORDER_ID, courier: this.order.shipment_courier })
        if (trackingData.data.success) {
          this.history = trackingData.data.data.history || []
        }
      }
    }
  },
  methods: {
    removeImage() {
      this.orderReview.image = '';
      this.$forceUpdate()
    },
    findEntryWithCondition(filteredEntries) {
      if (filteredEntries.length > 0) {
        // Find the last entry in the filtered entries
        const lastEntry = filteredEntries[filteredEntries.length - 1];
        const getCity = lastEntry.desc.match(/\[(.*?)\]/);
        let cityText = ''
        if (getCity) {
          cityText = getCity[1];
        }
        const parsedDate = moment(lastEntry.date, "DD-MM-YYYY HH:mm", true);
        if (parsedDate.isValid()) {
          // Format the date as required
          const formattedDate = parsedDate.format("DD MMM YYYY hh:mm A");
          return { status: true, date: formattedDate, counter_name: lastEntry.counter_name || cityText };
        } else {
          return { status: true, date: "", counter_name: lastEntry.counter_name || cityText };
        }
      } else {
        return { status: false, date: null, counter_name: '' };
      }
    },
    async saveReview() {
      this.submitted = true
      if (this.orderReview.description == '' || this.orderReview.title == '') {
        return;
      } else {
        try {
          await axios.post(config.api + 'order/updateOrderReview', {
            order_id: this.order._id,
            data: this.orderReview
          });
        } catch (error) {
          console.error(error);
        } finally {
          this.$router.go(0);
          this.writeReviewModal = false;
        }
      }
    },
    copyCnote_no(x) {
      if (x) {
        navigator.clipboard.writeText(x);
        this.$vs.notification({
          color: "success",
          position: "top-right",
          text: "Copied!",
          duration: 2000,
        });
      }
    },
    calculatePrice(productData) {
      var price = productData.priceCompare && productData.priceCompare != 0 ? productData.priceCompare : productData.price ? productData.price : 0;
      productData.whosaleData.map((one) => {
        if (one.count && one.count <= productData.qty) {
          //price = one.price;
          price = one.priceCompare && one.priceCompare != 0 ? one.priceCompare : one.price
        }
      });
      price = price * productData.qty;
      return parseInt(price)
    }
  }

};
</script>
<template>
  <div id="detail-orders" class="order-detail-wrapper order-page" v-if='order'>
    <div class="detail-orders__container">
      <div class="detail-orders__header">
        <img src="@/assets/icons/order-message-icon.svg" alt="ilust" class="detail-orders__header__ilust" />
        <h1 class="detail-orders__header__title">Detail Order #{{ order.ORDER_ID }}</h1>
      </div>
      <div class="detail-orders__inner">
        <!-- CONTENT REVIEWS -->
        <div v-if="order.orderReview" class="detail-orders__content detail-orders__content--review">
          <div class="detail-orders__content__header">
            <img src="@/assets/icons/star.svg" alt="star-icon"/>
            <h2 class="detail-orders__content__header__title">Review</h2>
          </div>
          <div class="content--review__container">
            <div class="content--review__content">
              <h2>{{ order.orderReview.title }}</h2>
              <p>{{ order.orderReview.description }}</p>
              <div class="content--review__content__rating">
                <star-rating
                  v-model="order.orderReview.userRating"
                  :star-size="20"
                  :read-only=true
                  :fixed-points=1
                  active-color="#F3B846"
                  :rounded-corners=true
                ></star-rating>
              </div>
            </div>
            <div v-if="order.orderReview.image" class="content--review__thumbnail">
              <img :src="getSizedImageUrl(order.orderReview.image,'medium','product-images')"/>
            </div>
          </div>
        </div>
        <!-- CONTENT ORDERS -->
        <div class="detail-orders__content detail-orders__content--orders">
          <div style='z-index: 1000000' v-if='loading' class='loader-global'>
            <div class='text-center'>
              <img width='60' height='60' src='/loader.gif' />
            </div>
          </div>

          <div class="detail-orders__content__header">
            <img class="detail-orders__content__ilust" src="@/assets/icons/order-data.svg" alt="ilust-icon"/>
            <h2 class="detail-orders__content__header__title">Order Details</h2>
          </div>

          <div class="detail-orders__content__info">
            <div class="content__info__customer">
              <h3 class="info__customer__name">{{ order.customerName }}</h3>
              <div class="info__customer__details">
                <div class="customer__details__data">
                  <img src="@/assets/icons/call-black.svg"/>
                  <p>{{ order.customerPhone }}</p>
                </div>
                <div v-if="order.address" class="customer__details__data">
                  <img src='@/assets/icons/address-black.svg'/>
                  <p>{{ customerAddress.join(", ") }}</p>
                </div>
                <div v-if="order.shippingInfo" class="customer__details__data">
                  <img src="@/assets/icons/order-shippping-icon-black.svg"/>
                  <p>{{ order.shipment_courier ? order.shipment_courier :
                    order.shippingInfo.deliveryCourier }}-{{ order.shippingInfo.deliveryType }}</p>
                </div>
                <div v-if="order.cnote_no" class="customer__details__data">
                  <img src="@/assets/icons/order-shippping-icon-black.svg"/>
                  <p>{{ order.cnote_no }}</p>
                </div>
                <div v-if="order.paymentInfo" class="customer__details__data">
                  <img src="@/assets/icons/order-wallet-icon-black.svg"/>
                  <p>{{ order.paymentInfo == 'bankTransfer' ? 'Bank Transfer' : order.paymentInfo }}</p>
                </div>
              </div>
            </div>
            <div class="content__info__order">
              <h2 class="content__info__order__title">INVOICE #{{ order.ORDER_ID }}</h2>
              <p class="content__info__order__status">
                <span>
                  <template v-if="order.status == 'Cancelled'">(Cancel)</template>
                  <template v-if="order.status == 'Completed'">(Complete)</template>
                  <template v-if="order.status == 'Pending'">(Pending)</template>
                  <template v-if="order.status == 'Processing'">(Process)</template>
                  <template v-if="order.status == 'Refunded'">(Refund)</template>
                </span>
                Order Date: {{ order.createdAt | g_datetime_format }}
              </p>

              <div class="content__info__order__payment-status" :class="order.paymentStatus == 'Paid' ? 'content__info__order__payment-status--paid' : 'content__info__order__payment-status--unpaid'">
                  {{ order.paymentStatus }}
              </div>

              <button v-if="!order.orderReview" @click="() => (writeReviewModal = true)" class='pp-button-blue--fill '>
                <span>Write a review</span>
              </button>
            </div>
          </div>

          <div class="detail-orders__tracking" v-if="order.cnote_no">
            <h2>Receipt Number</h2>
            <div class="detail-orders__tracking__note">
              <span>{{ order.cnote_no }}</span>
              <img @click="copyCnote_no(order.cnote_no)" src="@/assets/icons/copy-icon-blue.svg"/>
            </div>
            <div class="detail-orders__tracking__history">
              <h3>Tracking Status</h3>
              <div class="tracking__history__container">

                <div>
                  <div class="order-track-wrapper">
                    <div class="order-track-history">
                      <div v-for="(data, i) in historyData" v-bind:key="i" class="track-history">
                        <div
                        :class="{
                            'tracking__history__info tracking__history__info--last': data.status == 'Order created',
                            'tracking__history__info tracking__history__info--first': data.status == 'delivered' || data.status == 'Terkirim',
                            'tracking__history__info': data.status !== 'Order created' && data.status != 'delivered' && data.status != 'Terkirim'
                          }">
                          <div class="info__ilust-status">
                            <img src="@/assets/icons/status-green.svg" />
                          </div>
                          <div class="info__description">
                            <p class="info__description__primary">{{ data.status }} {{data.note ? " ("+ data.note + ")" : ""}}</p>
                            <p class="info__description__secondary">
                              <span>
                                {{ data.day }} {{ data.time }} {{ data.counter_name ? data.counter_name : '' }}
                                <span class="bullet"></span>
                                {{ data.destination }}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="tracking__history__info tracking__history__info--first">
                  <div class="info__ilust-status">
                    <img v-if="isReceivedByCustomer.status" src="@/assets/icons/status-green.svg"/>
                    <img v-else src="@/assets/icons/status-inactive.svg"/>
                  </div>
                  <div class="info__description">
                    <p class="info__description__primary">Received by Customer</p>
                    <p v-if="isReceivedByCustomer && isReceivedByCustomer.date" class="info__description__secondary">{{ isReceivedByCustomer.date }}</p>
                    <div v-else class="info__description__secondary">{{ order.estimate_delivery ? 'Estimated Delivery, ' + order.estimate_delivery : '' }}</div>
                  </div>
                </div>

                <div class="tracking__history__info">
                  <div class="info__ilust-status">
                    <img v-if="withDeliveryCourier.status && isReceivedByCustomer.status" src="@/assets/icons/status-green.svg"/>
                    <img v-else-if="withDeliveryCourier.status && receivedInboundStation.status && receivedByShipper.status && !isReceivedByCustomer.status" src="@/assets/icons/status-blue.svg"/>
                    <img v-else src="@/assets/icons/status-inactive.svg"/>
                  </div>
                  <div class="info__description">
                    <p class="info__description__secondary">{{ withDeliveryCourier.date || '' }}  {{ withDeliveryCourier.counter_name ? "* " + withDeliveryCourier.counter_name : "" }}</p>
                    <p class="info__description__primary">With Delivery Courier</p>
                  </div>
                </div>

                <div class="tracking__history__info">
                  <div class="info__ilust-status">
                    <img v-if="receivedInboundStation.status && withDeliveryCourier.status" src="@/assets/icons/status-green.svg"/>
                    <img v-else-if="receivedInboundStation.status && receivedByShipper.status && !withDeliveryCourier.status" src="@/assets/icons/status-blue.svg"/>
                    <img v-else src="@/assets/icons/status-inactive.svg"/>
                  </div>
                  <div class="info__description">
                    <p class="info__description__secondary">{{ receivedInboundStation.date }}  {{ receivedInboundStation.counter_name ? '* BDO, ' + receivedInboundStation.counter_name : '' }}</p>
                    <p class="info__description__primary">Received at Inbound Station</p>
                  </div>
                </div>

                <div class="tracking__history__info tracking__history__info--last">
                  <div class="info__ilust-status">
                    <img v-if="receivedByShipper.status && receivedInboundStation.status" src="@/assets/icons/status-green.svg"/>
                    <img v-else-if="receivedByShipper.status && !receivedInboundStation.status" src="@/assets/icons/status-blue.svg"/>
                    <img v-else src="@/assets/icons/status-inactive.svg"/>
                  </div>
                  <div class="info__description">
                    <p class="info__description__secondary">{{ receivedByShipper.date }}   {{ receivedByShipper.counter_name ? "* " + receivedByShipper.counter_name : '' }}</p>
                    <p class="info__description__primary">
                      Shipment Received by {{ order.shipment_courier.toUpperCase() }} counter officer
                    </p>
                  </div>
                </div> -->
              </div>              
            </div>
          </div>

          <!-- order view table -->
          <div class="detail-orders__content__cart">
            <div class="detail-orders__content__cart__container">
              <div class="content__cart__header">
                <p>Items</p>
                <p>Price</p>
              </div>

              <template v-if="order.variations && order.variations.length">
                <div class="content__cart__item content__cart__item--divider-title">
                  <p>{{ order.productName }}</p>
                </div>
                <template v-for="(variation, index) in order.variations"  >
                  <div v-bind:key="index" v-if="parseInt(variation.qty)" class="content__cart__item">
                    <p class="list-item overflow-wrap--anywhere pr-10">
                      {{ order.productName }} {{ variation.name }} ({{variation.qty }}x)
                    </p>
                    <p>Rp{{ calculatePrice(variation) | g_number_format}}</p>
                  </div>
                </template>
              </template>

              <div v-else class="content__cart__item">
                <p>{{ order.productName }} ({{ order.quantity }}x)</p>
                <p>Rp{{ order.productPrice | g_number_format }}</p>
              </div>

              <!-- Shipping Cost  -->
              <div v-if="order.shippingPrice" class="content__cart__item">
                <p>
                  <template v-if="order.shippingInfo">
                    Shipping Cost ({{ order.shipment_courier ? order.shipment_courier : order.shippingInfo.deliveryCourier }}
                    - {{ order.shippingInfo.deliveryType }})
                  </template>
                  <template v-else>Shipping Cost (Flat Shipping)</template>
                </p>
                <p>
                  Rp{{ parseFloat(order.shippingPrice) | g_number_format }}
                </p>
              </div>

              <div v-if="order.shippingInfo && order.shippingInfo.shippingDiscountValue && order.shippingInfo.shippingDiscountValue > 0" class="content__cart__item">
                  <p v-if="order.shippingInfo">Shipping Discounts</p>
                  <p>-Rp{{ parseFloat(order.shippingInfo.shippingDiscountValue) | g_number_format }}</p>
              </div>

              <!-- Additional Shipping Cost -->
              <div v-if="form.shippingType == 'automated' && order.additionalCost != 0" class="content__cart__item">
                <p>Additional Shipping Cost</p>
                <p>
                  Rp{{ parseInt(order.additionalCost) | g_number_format }}
                </p>
              </div>

              <!-- Cod Price -->
              <div v-if="order.codPrice" class="content__cart__item">
                <p>
                  Cod Fee
                </p>
                <p>
                  Rp{{ parseFloat(order.codPrice) | g_number_format }}
                </p>
              </div>

              <!-- Bump Price -->
              <template v-if="order.isBump && order.bumpPrice && order.bumpPrice > 0">
                <div v-for="(data, i) in order.bumpArray" v-bind:key="i" class="content__cart__item">
                  <p>
                    {{ data.bumpName }} (Bump)
                  </p>
                  <p>
                    Rp {{ data.bumpPrice | g_number_format }}
                  </p>
                </div>
              </template>

              <!-- Unique Code -->
              <div v-if="order.uniquePrice && order.uniquePrice != 0 && order.paymentInfo == 'bankTransfer'" class="content__cart__item">
                <p>Unique Code</p>
                <p>
                  Rp{{ parseInt(order.uniquePrice ? order.uniquePrice : 0) | g_number_format }}
                </p>
              </div>

              <!-- E-Payment Fee -->
              <div v-if="order.paymentMode = 'E-Payment' && order.ePayFee" class="content__cart__item">
                <p>E-Payment Fee</p>
                <p>Rp{{ parseInt(order.ePayFee) | g_number_format }}</p>
              </div>
            </div>

            <!--Total Price -->
            <div class="content__cart__footer">
              <p>Total</p>
              <p>
                Rp{{ parseFloat(order.grossRevenue) | g_number_format }}
              </p>
            </div>
          </div>

          <div class='detail-orders__content__footer'>
            <div class='content__footer__top'>
              <div class="content__footer__top__description">
                <img width="24px" height="24px" src="@/assets/icons/Processing.svg"/>
                <p>Your order is now being processed, please wait for the arrival of the courier, and
                  prepare a payment worth</p>
              </div>
              <p class="content__footer__top__price">Rp{{ parseFloat(order.grossRevenue) | g_number_format }}</p>
            </div>
            <p class="content__footer__info">to be paid to the courier directly</p>
          </div>
        </div>
      </div>
      <!-- CONTENT COPYRIGHT -->
      <div
        class="copyright-content payment-confirmation__copyright-content"
      >
        <div class="copyright-text__poweredby">
          <p class="copyright-text__poweredby__text">Powered by</p>
          <img src="@/assets/icons/formulir-logo.png" />
        </div>
        <p class="copyright-content__text">
          Copyright © {{ new Date() | year_format }}
        </p>
      </div>
    </div>

     <!-- Write a review popup-->
    <vs-dialog 
      prevent-close
      overflow-hidden
      class="pp-modal-vuesax variant-modal pp-modal-vuesax--write-review" v-model="writeReviewModal" width="785px"
     >
      <div class="pp-modal-vuesax__container">
        <div class="pp-modal-vuesax__header">
          <img class="icon-black" src="@/assets/icons/star.svg" alt="" />
          <h4 class="modal-title">Write a Review</h4>
        </div>
        <hr>
        <div class="pp-modal-vuesax__main">
          <div class="write-review__container">
            <div class="write-review__container__leftside">
              <div class="write-review__fields">
                  <label>Review Title</label>
                  <label>Review Title</label>
                  <div>
                    <vs-input v-model="orderReview.title" class="vs-form-control" type="text"
                     />
                  <div v-if="submitted && this.error.title" class="invalid-feedback">{{ this.error.title }}</div>
                  </div>
                
                  <div class="write-review__fields__star">
                    <star-rating
                      v-model="orderReview.userRating"
                      :star-size="20"
                      active-color="#F3B846"
                      :rounded-corners=true
                      :show-rating=false
                    ></star-rating>
                  </div>
              </div>

              <div class="write-review__field">
                <label>Your Review</label>
                <textarea v-model="orderReview.description"></textarea>
                <div v-if="submitted && this.error.description" class="invalid-feedback">{{ this.error.description }}</div>
              </div>
            </div>

            <div v-if="orderReview.image && orderReview.image !== ''" class="remove-img">              
              <img class="mb-10" :src="getSizedImageUrl(orderReview.image,'medium','product-images')"/>
              <button class="pp-button-blue--border m-0" @click="removeImage()">
                <span>Remove</span>
              </button>
            </div>

            <div v-else>
              <FileUploader
              :className="{ 'vs-form-control': true}"
              v-model="orderReview.image"
              :orderDetails=true
              type="file"
            />
            </div>

          </div>
        </div>
        <hr class="pp-modal-vuesax__line-last">
        <div class="pp-modal-vuesax__footer">
          <button class='pp-button-blue--border' @click="() => (writeReviewModal = false)">
            <span>Cancel</span>
          </button>
          <button @click="saveReview()" class='pp-button-blue--fill'>
            <span>Submit</span>
          </button>
        </div>
      </div>
    </vs-dialog>
  </div>
</template>